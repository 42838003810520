import {
  SCHEDULE_LOAD_REQUEST,
  SCHEDULE_LOAD_SUCCESS,
  SCHEDULE_LOAD_FAILURE,
} from "../types/schedule-types";

const initialState = {
  loading: false,
  success: false,
  error: null,
  schedule: null,
};

function schedule(state = initialState, action: any = null) {
  switch (action.type) {
    case SCHEDULE_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };

    case SCHEDULE_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error,
      };

    case SCHEDULE_LOAD_SUCCESS:
      return {
        ...state,
        schedule: action.payload,
        loading: false,
        success: true,
        error: null,
      };

    default:
      return state;
  }
}

export default schedule;
