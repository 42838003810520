// @ts-check
import React, { useState, useCallback } from 'react';

function Times({ start, end, updateTime, isActive }) {
  return (
    <div className="uk-grid-small uk-child-width-expand" data-uk-grid>
      <SelectTime time={start} threshold="start" updateTime={updateTime} isActive={isActive} />

      <div className="uk-text-center uk-width-1-6">
        <small>to</small>
      </div>

      <SelectTime time={end} threshold="end" updateTime={updateTime} isActive={isActive} />
    </div>
  );
}

function SelectTime({ time, threshold, updateTime, isActive }) {
  const [state, setState] = useState({
    time: time
  });
  /**
   * Handles the input change of values
   *
   * @param {object} e Contains the event object of an input
   * @memberof CreateProduct
   */
  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log(value);
    setState({ ...state, [name]: value });
    updateTime(value, threshold);
  };

  let times = [
    '00:00',
    '00:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '03:30',
    '04:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30'
  ];

  return (
    <div className="">
      <div className="uk-form-controls">
        <select
          name="time"
          className="uk-select"
          id="form-stacked-select"
          value={state.time}
          onChange={handleInputChange}
          // defaultValue={'15:00'}
          disabled={!isActive}
          style={{ height: '30px' }}
        >
          {/* <option value="DEFAULT" disabled hidden>
            Time *
          </option> */}
          {times.map((time, i) => (
            <option key={i} value={time}>
              {time}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

function ActiveDay({ isActive, isActiveUpdate }) {
  const [active, setActive] = useState(isActive);

  const handleInputChange = (e) => {
    const value = e.target.checked;
    setActive(value);
    isActiveUpdate(value);
  };

  return (
    <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid">
      <label>
        <input
          name="active"
          className="uk-checkbox"
          type="checkbox"
          checked={active}
          onChange={handleInputChange}
        />
      </label>
    </div>
  );
}

function DaySchedule({ schedule, updateSchedule }) {
  const isActiveUpdate = useCallback(
    (value) => {
      const _schedule = {
        ...schedule,
        active: value
      };
      updateSchedule(_schedule);
    },
    [schedule, updateSchedule]
  );

  const updateTime = useCallback(
    (value, threshold) => {
      const _schedule = {
        ...schedule,
        [threshold]: value
      };

      updateSchedule(_schedule);
    },
    [schedule, updateSchedule]
  );

  return (
    <tr>
      <td>{schedule.short_day_name}</td>
      <td>
        <ActiveDay isActive={schedule.active} isActiveUpdate={isActiveUpdate} />
      </td>
      <td>
        <Times
          start={schedule.start}
          end={schedule.end}
          updateTime={updateTime}
          isActive={schedule.active}
        />
      </td>
    </tr>
  );
}

export function NormalScheduleComponent({ daily_schedule, updateDailySchedule }) {
  const updateSchedule = useCallback(
    (schedule) => {
      let _daily_schedule = [...daily_schedule];
      const index = _daily_schedule.findIndex((record) => record.day === schedule.day);
      _daily_schedule[index] = schedule;
      updateDailySchedule(_daily_schedule);
    },
    [daily_schedule, updateDailySchedule]
  );

  return (
    <div className="uk-margin">
      <table className="uk-table uk-table-small">
        <thead>
          <tr>
            <th>Day</th>
            <th></th>
            <th>Working hours</th>
          </tr>
        </thead>

        <tbody>
          {daily_schedule.map((schedule, i) => (
            <DaySchedule key={i} schedule={schedule} updateSchedule={updateSchedule} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
