// @ts-check
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { NoSlotsComponent } from "../components/no-slots-component";

import { CalendarControlComponent } from "../components/calendar-control-component";
import { CalendarLoadingComponent } from "../components/calender-loading-component";
import { getGuardianCalendarAction } from "../redux/actions/calendar-actions";
// import { RescheduleAppointmentComponent } from '../../../appointment/presentation/components/reschedule-appointment-component';
import { SlotsRescheduleComponent } from "../components/slots-reschedule-component";
import { RescheduleButtonComponent } from "../../../appointments/presentation/components/actions/reschedule-button-component";

const styles = {
  pricing: {
    border: "1px solid rgb(73, 168, 97)",
    background: "rgb(73, 168, 97)",
    paddingTop: "5px",
    paddingBottom: "5px",
    color: "#fff",
    fontFamily: "Gilroy-ExtraBold",
  },
  appointment: {
    border: "1px solid",
    paddingTop: "5px",
    paddingBottom: "5px",
    fontFamily: "Gilroy-ExtraBold",
  },
};

function SelectedDateForReschedule({ datetime }) {
  const date = new Date(datetime);
  const day = date.toLocaleDateString("default", { weekday: "short" });
  const month = date.toLocaleString("default", { month: "short" });
  const time = date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  const formated_day_time = `${day}, ${date.getDate()} ${month} at ${time}`;

  return (
    <>
      <div className="uk-margin-medium-top-">
        <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-padding-small">
          <div className="uk-grid-small uk-child-width-expand" data-uk-grid>
            <div className="uk-display-inline uk-width-3-5">
              <div>
                <small className="uk-margin-left-">
                  <h6 className="uk-margin-remove uk-display-inline">
                    {formated_day_time}
                  </h6>
                </small>
              </div>
            </div>

            <div className="uk-text-right uk-width-2-5">
              <small
                className="uk-card uk-card-default uk-padding-small uk-border-rounded uk-h6 uk-margin-small-right-"
                style={styles.appointment}
              >
                new time
              </small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Slots({
  guardian_id,
  appointment_id,
  calendar_availability,
  type_of_appointments_offered,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
}) {
  const [selected, setSelected] = useState({
    view: "slots",
    date: new Date().getTime(),
  });

  const list = [];
  type_of_appointments_offered.includes("video") && list.push("video");
  type_of_appointments_offered.includes("inperson") && list.push("inperson");
  type_of_appointments_offered.includes("afterhours") &&
    list.push("afterhours");

  const type = list[0];

  const [state, setState] = useState({
    selected: 0,
    date: new Date(),
    normal_slots: calendar_availability[0].normal_slots,
    telehealth_slots: calendar_availability[0].telehealth_slots,
    afterhours_slots: calendar_availability[0].afterhours_slots,
  });

  const choosenDate = (
    date,
    key,
    normal_slots,
    telehealth_slots,
    afterhours_slots
  ) => {
    setState({
      selected: key,
      date: date,
      normal_slots: normal_slots,
      telehealth_slots: telehealth_slots,
      afterhours_slots: afterhours_slots,
    });

    setSelected({
      view: "slots",
      date: date.getTime(),
    });
  };

  const bookAppointment = (date, afterhours = false) => {
    const localTime = date.getTime();

    setSelected({
      view: "reschedule",
      date: localTime,
    });
  };

  const totalSlots = [
    ...state.normal_slots,
    ...state.telehealth_slots,
    ...state.afterhours_slots,
  ];

  // @ts-ignore
  let uniqSlots = [...new Set(totalSlots)];

  return (
    <div>
      <>
        <CalendarControlComponent
          selected={state.selected}
          choosenDate={choosenDate}
          calendar_availability={calendar_availability}
        />

        {uniqSlots.length === 0 && <NoSlotsComponent />}

        {type && uniqSlots.length > 0 && (
          <>
            {selected.view === "slots" && (
              <>
                <SlotsRescheduleComponent
                  date={state.date}
                  slots={uniqSlots}
                  bookAppointment={bookAppointment}
                  afterhours={false}
                />
              </>
            )}
          </>
        )}
      </>

      {selected.view === "reschedule" && (
        <>
          <div className="uk-margin">
            <SelectedDateForReschedule datetime={selected.date} />
            <RescheduleButtonComponent
              guardian_id={guardian_id}
              appointment_id={appointment_id}
              openBackdrop={openBackdrop}
              closeBackdrop={closeBackdrop}
              showSnackbar={showSnackbar}
              datetime={selected.date}
            />

            <button
              className="uk-button uk-button-secondary uk-width-1-1 "
              onClick={() =>
                setSelected({
                  view: "slots",
                  date: new Date().getTime(),
                })
              }
              style={{
                background: "unset",
                color: "#012535",
                border: "1px solid #012535",
              }}
            >
              <small>Select a different time</small>
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export function RescheduleCalendarComponent({
  guardian_id,
  appointment_id,
  appointment_type = "video",
  openBackdrop,
  closeBackdrop,
  showSnackbar,
}) {
  const dispatch = useDispatch();
  const { me } = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(getGuardianCalendarAction(me.id, appointment_type));
  }, [dispatch, me.id, appointment_type]);

  const { calendar, loading } = useSelector((state) => state.calendar);

  if (loading || !calendar) {
    return <CalendarLoadingComponent />;
  }
  // if (error) return `Error! ${error.message}`;

  return (
    <>
      <div className="uk-margin">
        <Slots
          guardian_id={guardian_id}
          appointment_id={appointment_id}
          calendar_availability={calendar.days}
          type_of_appointments_offered={calendar.appointment_types}
          openBackdrop={openBackdrop}
          closeBackdrop={closeBackdrop}
          showSnackbar={showSnackbar}
        />
      </div>
    </>
  );
}
