import { USERS_LOAD_REQUEST, USERS_LOAD_SUCCESS, USERS_LOAD_FAILURE } from '../types/users-types';

const initialState = {
  loading: false,
  error: null,
  success: false,
  users: []
};

function users(state = initialState, action: any = null) {
  switch (action.type) {
    case USERS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case USERS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case USERS_LOAD_SUCCESS:
      return {
        ...state,
        users: action.payload,
        success: true,
        loading: false,
        error: null
      };

    default:
      return state;
  }
}

export default users;
