// @ts-check
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppointmentsAction } from "../redux/actions/appointment-actions";

import CssBaseline from "@material-ui/core/CssBaseline";

import { AppointmentListComponent } from "../components/appointment-list-component";

import { LoadingComponent } from "../components/loading-component";
import { NoResultsFound } from "../../../../core/constants";

export default function UpcomingAppointmentsPage({
  level = "organization",
  guardian_id = null,
}) {
  const dispatch = useDispatch();
  const { location } = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(
      getAppointmentsAction(level, false, false, location.id, guardian_id)
    );
  }, [dispatch, guardian_id, level, location]);

  const { appointments, loading, error } = useSelector(
    (state) => state.appointments
  );

  if (error) return <div>Error! {error.message}</div>;

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        {loading && <LoadingComponent />}
        {!loading && (
          <>
            {appointments.length === 0 && (
              <>
                <NoResultsFound message={"No upcoming appointments found"} />
              </>
            )}
            <AppointmentListComponent
              appointments={appointments}
              level={level}
            />
          </>
        )}
      </React.Fragment>
    </>
  );
}
