// @ts-check
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Cookies from "universal-cookie";

import NavBar from "./components/nav";
import LoginPage from "../../features/authentication/presentation/pages/login-page.js";
import { AccessComponent } from "./components/access-component";
import GuardiansPage from "../../features/guardians/presentation/pages/guardians-page";
import GuardianPage from "../../features/guardians/presentation/pages/guardian-page";
import HomePage from "../../features/dashboard/presentation/pages/home-page";
import { ProfilePage } from "../../features/me/presentation/pages/profile-page";
import BookingPage from "../../features/booking/presentation/pages/book-appointment";
import AppointmentsPage from "../../features/appointments/presentation/pages/appointments-page";
import AppointmentPage from "../../features/appointments/presentation/pages/appointment-page";
import AppointmentReschedulePage from "../../features/appointments/presentation/pages/appointment-reschedule-page";
import AppointmentCancelPage from "../../features/appointments/presentation/pages/appointment-cancel-page";
import AppointmentOfflinePaymentPage from "../../features/appointments/presentation/pages/appointment-offline-payment-page";
import UsersPage from "../../features/users/presentation/pages/users-page";
import UserPage from "../../features/users/presentation/pages/user-page";

function Protected() {
  return (
    <AccessComponent>
      <Switch>
        <Route>
          <NavBar>
            <Route>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/guardians" component={GuardiansPage} />
              <Route exact path="/guardians/:id" component={GuardianPage} />
              <Route
                exact
                path="/guardians/:id/book/appointment"
                component={BookingPage}
              />
              <Route
                exact
                path="/guardians/:guardian_id/appointments/:appointment_id"
                component={AppointmentPage}
              />
              <Route
                exact
                path="/guardians/:guardian_id/appointments/:appointment_id/reschedule"
                component={AppointmentReschedulePage}
              />
              <Route
                exact
                path="/guardians/:guardian_id/appointments/:appointment_id/cancel"
                component={AppointmentCancelPage}
              />
              <Route
                exact
                path="/guardians/:guardian_id/appointments/:appointment_id/offline/payment"
                component={AppointmentOfflinePaymentPage}
              />

              <Route exact path="/appointments" component={AppointmentsPage} />

              <Route exact path="/users" component={UsersPage} />
              <Route exact path="/users/:id" component={UserPage} />

              <Route path="/account" component={ProfilePage} />
            </Route>
          </NavBar>
        </Route>
      </Switch>
    </AccessComponent>
  );
}

function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={LoginPage} />

        <Route
          render={() => {
            const cookies = new Cookies();
            const token = cookies.get("gh_org_tok");

            if (!token) {
              return <Redirect to="/login" />;
            }

            return <Protected />;
          }}
        />
      </Switch>
    </Router>
  );
}

export default Routes;
