import React from "react";

import LocalHospitalOutlinedIcon from "@material-ui/icons/LocalHospitalOutlined";
import NightsStayOutlinedIcon from "@material-ui/icons/NightsStayOutlined";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

export const CUSTOM_SELECT_STYLE = {
  option: (provided, state) => ({
    ...provided,
    padding: "0.8em 0.75rem",
  }),
  control: (provided, state) => ({
    ...provided,
    height: "55px",
    padding: "0 12px",
    borderRadius: "0",
  }),
};

export const FONTS = {
  PRIMARY: '"Maven Pro",Helvetica,Arial,sans-serif',
};

export const COLOURS = {
  DUSTY_GRAY: "#969696",
  GREEN: "green",
  WHITE: "#ffffff",
  CRIMSON: "#ed1c24",
  DANGER: "#ed1c24",
  TIARA: "#cbd2d6",
  DECO: "#999",
  BACKGROUND: "#fff",
};

export const ErrorMessage = ({ error }) => {
  if (error) {
    return (
      <small style={{ color: COLOURS.CRIMSON, width: "100%" }}>
        {error.message}
      </small>
    );
  }

  return null;
};

export const REASON_SELECTION = [
  { label: "Well baby", value: 0 },
  { label: "Immunisation", value: 1 },
  { label: "Family planning", value: 2 },
  { label: "Sexually transmitted infections", value: 3 },
  { label: "HIV/Aids", value: 4 },
  { label: "Curative (children)", value: 5 },
  { label: "TB and other communicable diseases", value: 6 },
  { label: "Health education", value: 7 },
];

export const logo = "https://storage.googleapis.com/avobuild/Gaudian.png";
// export const logo = "/logo192.png";
export const styles = {
  btnPrimaryColor: {
    background: "linear-gradient(135deg, #cc2936 0%, #3a3a3a 100%)",
    border: 0,
  },
  btnSecondaryColor: {
    background: "linear-gradient(135deg, #cc2936 0%, #144056 100%)",
    border: 0,
    // background: "linear-gradient(135deg, #ff6347 0%, #cc2936 100%)",
  },
  logo: {
    height: "12rem",
    width: "12rem",
    padding: "1.25rem",
  },
  logoSmall: {
    height: "4rem",
    width: "4rem",
    padding: "0.25rem",
  },
  time: {
    fontWeight: "200",
  },
  map: {
    width: "100%",
  },
  check: {
    color: "green",
  },
  cursorPointer: {
    cursor: "pointer",
  },
};

export function LoadingButton() {
  return (
    <div className="uk-margin">
      <button className="uk-button uk-button-primary uk-width-1-1">
        <div data-uk-spinner></div>
      </button>
    </div>
  );
}

export function LoadingButtonMedium() {
  return (
    <div className="uk-margin">
      <button className="uk-button uk-button-primary uk-button-medium uk-width-1-1">
        <div data-uk-spinner></div>
      </button>
    </div>
  );
}

export function Loader() {
  return (
    <div className="uk-margin uk-padding uk-text-center">
      <div className="uk-margin" data-uk-spinner></div>
      <small className="uk-display-block ">loading, please wait...</small>
    </div>
  );
}

export function NoResultsFound({ message }) {
  return (
    <div className="uk-margin uk-padding uk-text-center">
      {/* <span data-uk-icon={`icon: minus-circle; ratio: 3`}></span> */}
      <RemoveCircleOutlineIcon style={{ color: "#666", fontSize: "5rem" }} />
      <br />
      <br />
      <small className="uk-display-block ">{message}</small>
    </div>
  );
}

export const AppointmentStatus = ({ status }) => {
  const statuses = {
    pending: (
      <span className="uk-label uk-border-rounded" style={{}}>
        {status}
      </span>
    ),
    accepted: (
      <span
        className="uk-label uk-border-rounded"
        style={{ background: "#1e8a61" }}
      >
        {status}
      </span>
    ),
    rejected: (
      <span
        className="uk-label uk-border-rounded"
        style={{ background: "#f0506e" }}
      >
        {status}
      </span>
    ),
    expired: (
      <span
        className="uk-label uk-border-rounded"
        style={{ background: "#f0506e" }}
      >
        {status}
      </span>
    ),
    cancelled: (
      <span
        className="uk-label uk-border-rounded"
        style={{ background: "#f0506e" }}
      >
        {status}
      </span>
    ),
    noshow: (
      <span
        className="uk-label uk-border-rounded"
        style={{ background: "#fd8361" }}
      >
        {status}
      </span>
    ),
    inprogress: (
      <span
        className="uk-label uk-border-rounded"
        style={{ background: "#25a9e0" }}
      >
        {status}
      </span>
    ),
    completed: (
      <span
        className="uk-label uk-border-rounded"
        style={{ background: "#012535" }}
      >
        {status}
      </span>
    ),
  };

  return statuses[status] || null;
};

export const AppointmentPaid = ({ payment_status }) => {
  switch (payment_status) {
    case "paid":
      return (
        <span
          className="uk-label uk-border-rounded"
          style={{ background: "#49a861" }}
        >
          Paid
        </span>
      );
    case "unpaid":
      return (
        <span
          className="uk-label uk-border-rounded"
          style={{ background: "#f0506e" }}
        >
          Unpaid
        </span>
      );
    case "free":
      return (
        <span
          className="uk-label uk-border-rounded"
          style={{ background: "#25a9e0" }}
        >
          Free
        </span>
      );

    default:
      return null;
  }
};

export const AppointmentTypeIcon = ({ type }) => {
  const types = {
    inperson: (
      <LocalHospitalOutlinedIcon
        fontSize={"default"}
      ></LocalHospitalOutlinedIcon>
    ),
    video: <VideocamOutlinedIcon fontSize={"default"}></VideocamOutlinedIcon>,
    afterhours: (
      <NightsStayOutlinedIcon fontSize={"small"}></NightsStayOutlinedIcon>
    ),
  };

  return types[type];
};
