const dateKey = (dt) => {
  let date = new Date(dt);
  date.setDate(date.getDate());

  date = date
    .toLocaleString("en-us", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
  return date;
};

const generateWeekDates = () => {
  let dates = [];
  let count = 0;
  for (let i = 0; i < 6; i++) {
    let date = new Date();
    date.setDate(date.getDate() + count);
    if (date.getDay() === 0) {
      date.setDate(date.getDate() + 1);
      count = count + 1;
    }
    dates.push(date);
    count = count + 1;
  }
  return dates;
};

export const getBarChartTimeData = (bookings, type = "date") => {
  let data = {
    "00:00": 0,
    "01:00": 0,
    "02:00": 0,
    "03:00": 0,
    "04:00": 0,
    "05:00": 0,
    "06:00": 0,
    "07:00": 0,
    "08:00": 0,
    "09:00": 0,
    "10:00": 0,
    "11:00": 0,
    "12:00": 0,
    "13:00": 0,
    "14:00": 0,
    "15:00": 0,
    "16:00": 0,
    "17:00": 0,
    "18:00": 0,
    "19:00": 0,
    "20:00": 0,
    "21:00": 0,
    "22:00": 0,
    "23:00": 0,
  };

  if (type === "date") {
    data = {};
    const dates = generateWeekDates();
    dates.map((date) => (data[dateKey(date)] = 0));
  }

  for (let key in bookings) {
    let dt = bookings[key][type];
    if (type === "time") {
      dt = new Date(dt).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      });
    }
    const count = bookings[key]["count"];
    if (dt in data) {
      data[dt] = count;
    }
    console.log(key);
    console.log("dt", dt);
  }

  const arr_x = Object.keys(data).map((dt) => {
    if (type === "time") {
      // Append any date. Use your birthday.
      const timeString12hr = new Date(`1970-01-01T${dt}`).toLocaleString(
        "en-US",
        {
          hour: "numeric",
          hour12: true,
        }
      );

      console.log("timeString12hr", timeString12hr);

      return timeString12hr;
    }
    const date = new Date(dt);
    const day = date.toLocaleDateString("default", { weekday: "short" });
    const month = date.toLocaleString("default", { month: "long" });
    return `${day} ${date.getDate()} ${month}`;
  });

  const arr_y = Object.values(data);

  return { categories: arr_x, data: arr_y };
};
