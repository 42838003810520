import { Guardian } from "../entities/guardian-entity";
import { IGuardianRepository } from "../repositories/iguardian-repository";

export interface IGuardianUsecase {
  GetOrganizationGuardians(location_id?: string): Promise<Guardian[]>;
  GetOrganizationGuardian(guardian_id: string): Promise<Guardian>;
}

export class GuardianUsecase implements IGuardianUsecase {
  guardianRepository: IGuardianRepository;

  constructor(gr: IGuardianRepository) {
    this.guardianRepository = gr;
  }

  GetOrganizationGuardians(location_id?: string): Promise<Guardian[]> {
    return this.guardianRepository.GetOrganizationGuardians(location_id);
  }

  GetOrganizationGuardian(guardian_id: string): Promise<Guardian> {
    return this.guardianRepository.GetOrganizationGuardian(guardian_id);
  }
}
