class User {
  id: String;
  name: String;
  image: String;
  gender: String;
  contact: String;

  constructor(args: any) {
    this.id = args.id;
    this.name = args.name;
    this.image = args.image;
    this.gender = args.gender;
    this.contact = args.contact;
  }
}

export class Appointment {
  id: string;
  type: string;
  payment_method: string;
  payment_status: string;
  show_gateway: boolean;
  currency: string;
  amount: number;
  paid: boolean;
  rescheduled: boolean;
  status: string;
  reason: string;
  walkin: boolean;
  datetime: Date;
  reference: string;
  patient: User;
  medical_guardian: User;
  location: any;

  constructor(args: any) {
    this.id = args.id;
    this.type = args.type;
    this.payment_method = args.payment_method;
    this.payment_status = args.payment_status;
    this.show_gateway = args.show_gateway;
    this.currency = args.currency;
    this.amount = args.amount;
    this.paid = args.paid;
    this.rescheduled = args.rescheduled;
    this.status = args.status;
    this.reason = args.reason;
    this.walkin = args.walkin;
    this.datetime = new Date(args.datetime);
    this.reference = args.reference;
    this.patient = new User(args.patient);
    this.medical_guardian = new User(args.medical_guardian);
    this.location = args.location;
  }
}
