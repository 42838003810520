import { LOGIN_LOAD_REQUEST, LOGIN_LOAD_SUCCESS, LOGIN_LOAD_FAILURE } from '../types/login-types';

const initialState = {
  loading: false,
  error: null,
  data: null
};

function login(state = initialState, action: any = null) {
  switch (action.type) {
    case LOGIN_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case LOGIN_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case LOGIN_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };

    default:
      return state;
  }
}

export default login;
