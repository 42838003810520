// @ts-check
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import { IconButton, ListItemSecondaryAction } from "@material-ui/core";

import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import { GuardianListItemComponent } from "./guardian-list-item-component";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  indicator: {},
}));

export function AlignItemsList({ guardians }) {
  const classes = useStyles();
  const history = useHistory();

  const n = guardians.length - 1;
  const renderUsers = guardians.map((guardian, index) => {
    return (
      <div key={index}>
        <ListItem
          alignItems="flex-start"
          button
          onClick={() => history.push(`/guardians/${guardian.id}/`)}
        >
          <ListItemAvatar>
            <Avatar
              alt={guardian.name}
              src={guardian.image || "/static/images/avatar/1.jpg"}
            />
          </ListItemAvatar>
          <ListItemText
            primary={guardian.name}
            secondary={
              <React.Fragment>{guardian.application.profession}</React.Fragment>
            }
          />

          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="comments"
              onClick={() => history.push(`/guardians/${guardian.id}/`)}
            >
              <FiberManualRecordIcon
                style={{
                  color:
                    guardian.application.status === "approved"
                      ? "#4caf50"
                      : "#f44336",
                }}
              />
              <LocalHospitalIcon fontSize="small" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        {index !== n && <Divider variant="inset" component="li" />}
      </div>
    );
  });

  return <List className={classes.root}>{renderUsers}</List>;
}

export function GuardianListComponent({ guardians }) {
  const renderUsers = guardians.map((guardian, index) => {
    return (
      <div key={index}>
        <GuardianListItemComponent guardian={guardian} />
      </div>
    );
  });

  return <div>{renderUsers}</div>;
}
