// @ts-check
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useDispatch, useSelector } from "react-redux";
import { meSelectLocationAction } from "../../../features/me/presentation/redux/actions/me-actions";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: "12.5px",
    minWidth: 120,
  },
}));

export function ControlledOpenSelect() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { me, location } = useSelector((state) => state.me);

  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    const id = event.target.value;

    const index = me.permissions.findIndex((x) => x.location_id === id);

    if (index > -1) {
      const loc = me.permissions[index];
      localStorage.setItem("location_id", loc.location.id);

      dispatch(meSelectLocationAction(loc.location));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      {location && (
        <FormControl
          variant="outlined"
          size="small"
          className={classes.formControl}
          margin="normal"
        >
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            inputProps={{ "aria-label": "Without label" }}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={location.id}
            onChange={handleChange}
          >
            {me.permissions.map((x, key) => (
              <MenuItem key={key} value={x.location.id}>
                {x.location.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
}
