// @ts-check
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getBarChartData } from "../components/generate-bar-chart-data";

import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { getBarChartTimeData } from "../components/generate-time-data";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  sectionHeading: {
    margin: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  clientLogo: {
    height: "8rem",
    width: "8rem",
    objectFit: "contain",
  },
  icon: {
    fontSize: "4em",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(2),
  },
}));

window.Highcharts = Highcharts; //this line did the magic

function BarChart({ xy, name, day, subtitle }) {
  const config = {
    /* HighchartsConfig */
    chart: {
      type: "column",
      height: "308",
    },
    title: {
      text: `<small>${name}</small>`,
      align: "center",
    },
    subtitle: {
      text: `${subtitle}`,
    },
    xAxis: {
      categories: xy.categories,
      title: {
        text: null,
      },
      min: 0,
      crosshair: true,
      width: "100%",
    },
    yAxis: {
      min: 0,
      title: {
        text: "# of appointments",
        align: "high",
      },
      labels: {
        overflow: "justify",
      },
    },
    tooltip: {
      valueSuffix: null,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: false,
        },
      },
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "top",
      x: -40,
      y: 80,
      floating: true,
      borderWidth: 1,
      backgroundColor: "#FFFFFF",
      shadow: true,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        showInLegend: false,
        name: "Appointments",
        data: xy.data,
      },
    ],
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={config} />
    </div>
  );
}

function DornutPredictions({ data }) {
  const config = {
    chart: {
      type: "pie",
      height: "308",
      options3d: {
        enabled: false,
        alpha: 45,
      },
    },
    title: {
      text: `Weekly appointment reasons`, //"Contents of Highsoft's weekly fruit delivery"
    },
    subtitle: {
      text: "For the next 7 days",
    },
    plotOptions: {
      pie: {
        innerSize: 100,
        depth: 45,
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: "# of appointments",
        data: data,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={config} />;
}

export function WeeklyTraffic({ bookings, reasons }) {
  const classes = useStyles();
  const history = useHistory();

  const weekly_clinic_appointments = getBarChartData(bookings, "date");

  const hourly_clinic_appointments = getBarChartTimeData(bookings, "time");

  console.log(hourly_clinic_appointments);

  const date = new Date();
  const month = date.toLocaleString("default", {
    month: "long",
    year: "numeric",
  });

  const pie_data = reasons.map((x) => [x["reason"], x["count"]]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Paper className={classes.paper} elevation={0}>
          <BarChart
            name={"Weekly Overview"}
            subtitle={"For the next 7 days"}
            day={`${date.getDate()} ${month}`}
            xy={weekly_clinic_appointments}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={12}>
        <Paper
          className={classes.paper}
          elevation={0}
          onClick={() => history.push("/billing/cash/account")}
        >
          <DornutPredictions data={pie_data} />
        </Paper>
      </Grid>
    </Grid>
  );
}
