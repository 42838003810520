// @ts-check
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { ListItemSecondaryAction } from "@material-ui/core";

import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  heading: {
    margin: theme.spacing(2),
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
}));

export function GuardianMinimunInfo({ guardian }) {
  const classes = useStyles();

  return (
    <div className="uk-margin">
      <List className={classes.root}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar
              alt={guardian.name}
              src={guardian.image || "/static/images/avatar/1.jpg"}
            />
          </ListItemAvatar>
          <ListItemText
            primary={guardian.name}
            secondary={
              <React.Fragment>{guardian.application.profession}</React.Fragment>
            }
          />

          <ListItemSecondaryAction>
            <FiberManualRecordIcon
              style={{
                color:
                  guardian.application.status === "approved"
                    ? "#4caf50"
                    : "#f44336",
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </div>
  );
}
