// @ts-check
import React, { useState, useEffect } from "react";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AvailableDate from "../pages/available-date.js";

export function CalendarControlComponent({
  selected,
  choosenDate,
  calendar_availability,
}) {
  const [slots, setSlots] = useState([]);
  const [step, setStep] = useState(0);

  useEffect(() => {
    async function compute() {
      const dates = calendar_availability.map((availability) => {
        const totalSlots = [
          ...availability.normal_slots,
          ...availability.telehealth_slots,
          ...availability.afterhours_slots,
        ];

        // @ts-ignore
        let uniq = [...new Set(totalSlots)];

        return {
          date: new Date(availability.date),
          count: uniq.length,
        };
      });

      // @ts-ignore
      setSlots(dates);
    }
    compute();
  }, [calendar_availability]);

  const next = () => {
    // if (step > -1 && step < Math.ceil(slots.length / 3) + 1) {
    if (step > -1 && step < slots.length - 3) {
      const normal_slots = calendar_availability[step + 3].normal_slots;
      const telehealth_slots = calendar_availability[step + 3].telehealth_slots;
      const afterhours_slots = calendar_availability[step + 3].afterhours_slots;
      setStep(step + 3);
      choosenDate(
        slots[step + 3]["date"],
        0,
        normal_slots,
        telehealth_slots,
        afterhours_slots
      );
    }
  };

  const prev = () => {
    if (step > 0 && step < slots.length + 1) {
      const normal_slots = calendar_availability[step - 3].normal_slots;
      const telehealth_slots = calendar_availability[step - 3].telehealth_slots;
      const afterhours_slots = calendar_availability[step - 3].afterhours_slots;
      setStep(step - 3);
      choosenDate(
        slots[step - 3]["date"],
        0,
        normal_slots,
        telehealth_slots,
        afterhours_slots
      );
    }
  };

  const today = () => {
    const normal_slots = calendar_availability[0].normal_slots;
    const telehealth_slots = calendar_availability[0].telehealth_slots;
    const afterhours_slots = calendar_availability[0].afterhours_slots;
    setStep(0);
    choosenDate(
      slots[0]["date"],
      0,
      normal_slots,
      telehealth_slots,
      afterhours_slots
    );
  };

  return (
    <>
      <div className="uk-margin-">
        <button
          className="uk-button uk-button-primary uk-button-small"
          onClick={today}
          style={{
            border: "1px solid",
            background: "unset",
            color: "initial",
          }}
        >
          Today
        </button>

        <button
          className="uk-button uk-button-primary uk-button-small uk-float-right uk-margin-small-left"
          onClick={next}
          style={{
            border: "1px solid",
            background: "unset",
            color: "initial",
          }}
        >
          <ArrowForwardIcon fontSize={"small"} />
        </button>

        <button
          className="uk-button uk-button-primary uk-button-small uk-float-right"
          onClick={prev}
          style={{
            border: "1px solid",
            background: "unset",
            color: "initial",
          }}
        >
          <ArrowBackIcon fontSize={"small"} />
        </button>
      </div>

      <div
        className="uk-grid-small uk-child-width-expand uk-child-width-expand@m uk-text-center uk-margin-small-top"
        data-uk-grid
      >
        <AvailableDate
          selected={selected}
          choosenDate={choosenDate}
          slots={slots.slice(step, step + 3)}
          step={step}
          calendar_availability={calendar_availability}
        />
      </div>
    </>
  );
}
