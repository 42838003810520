import { Api, IApi } from "../../../../core/api/api";
import config from "../../../../core/config/config";
import { Appointment } from "../../domain/entities/appointment-entity";
import { AppointmentRepository } from "../../domain/repositories/appointment-repository";

export class AppointmentRepositoryImpl implements AppointmentRepository {
  private api: IApi;

  constructor() {
    this.api = new Api();
  }

  async CreateAppointmentExternal(
    guardian_id: string,
    type: string,
    payment_method: string,
    reason: string,
    datetime: Date,
    patient: any,
    booking_notes: string,
    duration: number,
    location_id: string
  ): Promise<Appointment> {
    const uri = `${config.uri.appointments_url}/frontdesk/a/locations/${location_id}/guardians/${guardian_id}/appointments/create`;

    const body = {
      type,
      payment_method,
      reason,
      datetime,
      patient,
      booking_notes,
      duration,
    };

    const result = await this.api.Post(uri, body);

    return new Appointment(result);
  }

  EditAppointmentExternalPatient(
    guardian_id: string,
    appointment_id: string,
    patient: any,
    location_id: string
  ): Promise<Appointment> {
    throw new Error("Method not implemented.");
  }

  async GetAppointment(
    guardian_id: string,
    appointment_id: string,
    location_id: string
  ): Promise<Appointment> {
    const uri = `${config.uri.appointments_url}/frontdesk/a/locations/${location_id}/guardians/${guardian_id}/appointments/${appointment_id}`;
    const result = await this.api.Get(uri);

    return new Appointment(result);
  }

  async GetAppointmentRequests(
    guardian_id: string,
    level: string,
    location_id: string
  ): Promise<Appointment[]> {
    const uri = `${config.uri.appointments_url}/frontdesk/a/locations/${location_id}/guardians/${guardian_id}/appointments/requests?level=${level}`;
    const result = await this.api.Get(uri);

    return result.map((appointment) => new Appointment(appointment));
  }

  async AcceptAppointmentRequest(
    guardian_id: string,
    appointment_id: string,
    location_id: string
  ): Promise<Appointment> {
    const uri = `${config.uri.appointments_url}/frontdesk/a/locations/${location_id}/guardians/${guardian_id}/appointments/${appointment_id}/accept/request`;
    const result = await this.api.Post(uri);

    return new Appointment(result);
  }

  async RejectAppointmentRequest(
    guardian_id: string,
    appointment_id: string,
    location_id: string
  ): Promise<Appointment> {
    const uri = `${config.uri.appointments_url}/frontdesk/a/locations/${location_id}/guardians/${guardian_id}/appointments/${appointment_id}/reject/request`;
    const result = await this.api.Post(uri);

    return new Appointment(result);
  }

  async CancelAppointment(
    guardian_id: string,
    appointment_id: string,
    location_id: string
  ): Promise<Appointment> {
    const uri = `${config.uri.appointments_url}/frontdesk/a/locations/${location_id}/guardians/${guardian_id}/appointments/${appointment_id}/cancel`;
    const result = await this.api.Post(uri);

    return new Appointment(result);
  }

  async RescheduleAppointment(
    guardian_id: string,
    appointment_id: string,
    datetime: Date,
    location_id: string
  ): Promise<Appointment> {
    const uri = `${config.uri.appointments_url}/frontdesk/a/locations/${location_id}/guardians/${guardian_id}/appointments/${appointment_id}/reschedule`;
    const body = {
      datetime,
    };
    const result = await this.api.Post(uri, body);

    return new Appointment(result);
  }

  async OfflinePayment(
    guardian_id: string,
    appointment_id: string,
    location_id: string
  ): Promise<Appointment> {
    const uri = `${config.uri.appointments_url}/frontdesk/a/locations/${location_id}/guardians/${guardian_id}/appointments/${appointment_id}/paid/offline`;
    const result = await this.api.Post(uri);

    return new Appointment(result);
  }

  async GetAppointments(
    level: string,
    past: boolean,
    unpaid: boolean,
    location_id: string,
    guardian_id?: string
  ): Promise<Appointment[]> {
    const uri = `${config.uri.appointments_url}/frontdesk/a/locations/${location_id}/appointments`;
    const body = {
      level,
      past,
      unpaid,
      guardian_id,
    };
    const result = await this.api.Post(uri, body);

    return result.map((appointment) => new Appointment(appointment));
  }
}
