// @ts-check
import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper/Paper";
import Typography from "@material-ui/core/Typography/Typography";
import config from "../../config/config";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(0),
    // paddingBottom: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  heading: {
    margin: theme.spacing(2),
  },
  margin: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
  },
  clientLogo: {
    height: "3rem",
    // width: '8rem',
    objectFit: "contain",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
}));

export function Products() {
  const { app_images, uri } = config;

  const classes = useStyles();
  return (
    <div>
      <Typography
        variant="subtitle2"
        component="h3"
        align="center"
        className={classes.heading}
      >
        <small>
          <b>Products</b>
        </small>
      </Typography>

      <Grid container spacing={0} justify="center" alignItems="center">
        <Grid item xs={6}>
          <Paper className={classes.paper} elevation={0}>
            <a
              className={classes.link}
              target="_blank"
              href={uri.health_app}
              rel="noopener noreferrer"
            >
              <img
                className={classes.clientLogo}
                src={app_images.icon_logo}
                alt="logo"
              />
              <div className="uk-margin-small">
                <small className="uk-display-block">Patients</small>
              </div>
            </a>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper} elevation={0}>
            <a
              className={classes.link}
              target="_blank"
              href={uri.health_pro_app}
              rel="noopener noreferrer"
            >
              <img
                className={classes.clientLogo}
                src={app_images.pro_icon_logo}
                alt="logo"
              />
              <div className="uk-margin-small">
                <small className="uk-display-block">Health Pro's</small>
              </div>
            </a>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={0} justify="center" alignItems="center">
        <Grid item xs={6}>
          <Paper className={classes.paper} elevation={0}>
            <a className={classes.link} href="/" rel="noopener noreferrer">
              <img
                className={classes.clientLogo}
                src={`https://storage.googleapis.com/guardian-health-brand-identity/frontdesk-icon.png`}
                alt="logo"
              />
              <div className="uk-margin-small">
                <small className="uk-display-block">FrontDesk</small>
              </div>
            </a>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper} elevation={0}>
            <a
              className={classes.link}
              target="_blank"
              href={uri.organization_app}
              rel="noopener noreferrer"
            >
              <img
                className={classes.clientLogo}
                src={
                  "https://storage.googleapis.com/guardian-health-brand-identity/organization-icon.png"
                }
                alt="logo"
              />
              <div className="uk-margin-small">
                <small className="uk-display-block">Organization</small>
              </div>
            </a>
          </Paper>
        </Grid>
      </Grid>

      <div>
        <hr className="uk-margin-remove" />

        <div className="">
          <div className="uk-padding-small uk-padding-remove-vertical">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.guardianhealth.gh_app.prod"
            >
              <img
                alt="Get it on Google Play"
                src="https://storage.googleapis.com/guardian-health-brand-identity/app-stores/icon-play.png"
                style={{ width: "15rem" }}
              />
            </a>
          </div>

          <div className="uk-padding-small uk-padding-remove-vertical">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://apps.apple.com/za/app/guardian-health/id1577491477"
            >
              <img
                alt="Get it on Google Play"
                src="https://storage.googleapis.com/guardian-health-brand-identity/app-stores/icon-appstore.png"
                style={{ width: "15rem" }}
              />
            </a>
          </div>

          <div className="uk-padding-small uk-padding-remove-vertical">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://appgallery.huawei.com/#/app/C103843121"
            >
              <img
                alt="Get it on Huawei AppGallery"
                src="https://storage.googleapis.com/guardian-health-brand-identity/app-stores/app-link-huawei.png"
                style={{ width: "15rem" }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
