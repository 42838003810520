// @ts-check
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import {
  AppointmentPaid,
  AppointmentStatus,
} from "../../../../../core/constants";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  indicator: {},
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  card: {
    marginBottom: theme.spacing(2),
  },
}));

function Guardian({ user }) {
  return (
    <ListItem>
      <ListItemAvatar style={{ minWidth: "25px" }}>
        <Avatar
          style={{ height: "20px", width: "20px" }}
          alt={user.name}
          src={user.image || "/static/images/avatar/1.jpg"}
        />
      </ListItemAvatar>
      <ListItemText
        primary={<small>{user.name} (Guardian)</small>}
        secondary={null}
      />
    </ListItem>
  );
}

export function AppointmentHeaderGuardianComponent({ appointment }) {
  const classes = useStyles();

  const { status } = appointment;

  const date = appointment.datetime;
  const day = date.toLocaleDateString("default", { weekday: "short" });
  const month = date.toLocaleString("default", { month: "short" });
  const time = date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  const formated_day = `${day}, ${date.getDate()} ${month} at ${time}`;

  return (
    <div>
      <List className={classes.root}>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={
              <small>
                {appointment.rescheduled && status === "accepted" && (
                  <span
                    className="uk-label uk-border-rounded "
                    style={{
                      background: "#fd8361",
                      marginRight: "4px",
                    }}
                  >
                    <b>R</b>
                  </span>
                )}
                <AppointmentStatus status={status} />{" "}
                {["video", "afterhours", "inperson"].includes(
                  appointment.type
                ) && (
                  <AppointmentPaid
                    payment_status={appointment.payment_status}
                  />
                )}
              </small>
            }
          />

          <ListItemText
            className={classes.right}
            primary={<small>{formated_day}</small>}
          />
        </ListItem>

        <Divider
          variant="middle"
          style={{
            borderTop: "1px dashed #e5e5e5",
            background: "none",
          }}
        />

        <Grid container spacing={2} style={{ padding: "0.5rem 0.7rem" }}>
          <Grid xs={6} md={6}>
            <Guardian user={appointment.medical_guardian} />
          </Grid>
          <Grid xs={6} md={6}>
            <ListItem alignItems="flex-start">
              <ListItemText
                className={classes.right}
                primary={<small>Appointment #{appointment.reference}</small>}
              />
            </ListItem>
          </Grid>
        </Grid>
      </List>
    </div>
  );
}
