import { BookingAnalytics } from '../entities/booking-analytics';
import { ReasonAnalytics } from '../entities/reason-analytics';
import { IAnalyticsRepository } from '../repositories/ianalytics-repository';

export interface IAnalyticsUsecase {
  GetWeeklyBookings(level: string, filter: any): Promise<BookingAnalytics[]>;
  GetWeeklyReasons(level: string, filter: any): Promise<ReasonAnalytics[]>;
}

export class AnalyticsUsecase implements IAnalyticsUsecase {
  analyticsRepository: IAnalyticsRepository;

  constructor(or: IAnalyticsRepository) {
    this.analyticsRepository = or;
  }

  GetWeeklyBookings(level: string, filter: any): Promise<BookingAnalytics[]> {
    return this.analyticsRepository.GetWeeklyBookings(level, filter);
  }

  GetWeeklyReasons(level: string, filter: any): Promise<ReasonAnalytics[]> {
    return this.analyticsRepository.GetWeeklyReasons(level, filter);
  }
}
