// @ts-check
import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Avatar from "@material-ui/core/Avatar/Avatar";
import Paper from "@material-ui/core/Paper";
import LocalHospitalOutlinedIcon from "@material-ui/icons/LocalHospitalOutlined";
import NightsStayOutlinedIcon from "@material-ui/icons/NightsStayOutlined";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  guardianAvatarContainer: {
    justifyContent: "center",
    display: "flex",
  },
  guardianAvatar: {
    width: "6rem",
    height: "6rem",
  },
  guardianLogo: {
    height: "10rem",
    width: "10rem",
    objectFit: "contain",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
}));

export function GuardianImageComponent({ guardian }) {
  const classes = useStyles();

  const types = {
    afterhours: (
      <span
        className="uk-margin-small-left uk-margin-small-right"
        data-uk-tooltip="afterhours video appointments"
      >
        <NightsStayOutlinedIcon fontSize={"small"}></NightsStayOutlinedIcon>
      </span>
    ),
    video: (
      <span
        className="uk-margin-small-left uk-margin-small-right"
        data-uk-tooltip="video appointments"
      >
        <VideocamOutlinedIcon fontSize={"default"}></VideocamOutlinedIcon>
      </span>
    ),
    inperson: (
      <span
        className="uk-margin-small-left uk-margin-small-right"
        data-uk-tooltip="inperson or in-clinic appointments"
      >
        <LocalHospitalOutlinedIcon
          fontSize={"small"}
        ></LocalHospitalOutlinedIcon>
      </span>
    ),
  };

  return (
    <Paper elevation={0} className={classes.paper}>
      <div className={classes.root}>
        <div className={classes.guardianAvatarContainer}>
          <Avatar
            className={classes.guardianAvatar}
            alt={guardian.name}
            src={guardian.image || "/static/images/avatar/1.jpg"}
          />
        </div>

        <div className="uk-text-center uk-margin">
          <span
            className="uk-badge uk-padding-small"
            style={{ background: "#fd8361" }}
          >
            {guardian.application.type_of_appointments_offered.includes(
              "video"
            ) && types.video}
            {guardian.application.type_of_appointments_offered.includes(
              "inperson"
            ) && types.inperson}
            {guardian.application.type_of_appointments_offered.includes(
              "afterhours"
            ) && types.afterhours}
          </span>
        </div>

        {/* <div className="uk-margin-small">
          <b>{guardian.name}</b>
        </div> */}
      </div>
    </Paper>
  );
}
