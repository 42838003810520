export class AuthOrganization {
  id: string;
  name: string;
  organization: string;
  logo: string;

  constructor(args: any) {
    this.id = args.id;
    this.name = args.name;
    this.organization = args.organization;
    this.logo = args.logo;
  }
}
