import { Me } from '../entities/me-entity';
import { MeRepository } from '../repositories/me-repository';

interface MeUsecase {
  GetMe(): Promise<Me>;
  EditMe(title: string, name: string, gender: string): Promise<Me>;
  EditEmail(email: string): Promise<Me>;
  EditContact(contact: string): Promise<Me>;
  UploadProfileImage(file: any): Promise<Me>;
  UploadSignature(file: any): Promise<Me>;
}

export class MeUsecaseImpl implements MeUsecase {
  meRepository: MeRepository;

  constructor(mr: MeRepository) {
    this.meRepository = mr;
  }

  GetMe(): Promise<Me> {
    return this.meRepository.GetMe();
  }

  EditMe(title: string, name: string, gender: string): Promise<Me> {
    return this.meRepository.EditMe(title, name, gender);
  }

  EditEmail(email: string): Promise<Me> {
    return this.meRepository.EditEmail(email);
  }

  EditContact(contact: string): Promise<Me> {
    return this.meRepository.EditContact(contact);
  }

  UploadProfileImage(file: any): Promise<Me> {
    return this.meRepository.UploadProfileImage(file);
  }

  UploadSignature(file: any): Promise<Me> {
    return this.meRepository.UploadSignature(file);
  }
}
