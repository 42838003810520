import { IGuardianRepository } from "../../domain/repositories/iguardian-repository";
import { Guardian } from "../../domain/entities/guardian-entity";

import { ApiUtil } from "../../../../core/utils/api-util";
import config from "../../../../core/config/config";

export class GuardianRepository implements IGuardianRepository {
  api: ApiUtil;

  constructor() {
    this.api = new ApiUtil();
  }

  async GetOrganizationGuardians(location_id?: string): Promise<Guardian[]> {
    let uri = `${config.uri.guardians_url}/guardians/organization`;

    if (location_id) {
      uri = `${config.uri.guardians_url}/guardians/organization?location_id=${location_id}`;
    }

    const result = await this.api.Get(uri);

    return result.map((guardian) => new Guardian(guardian));
  }

  async GetOrganizationGuardian(guardian_id: string): Promise<Guardian> {
    const uri = `${config.uri.guardians_url}/guardians/${guardian_id}/organization`;
    const result = await this.api.Get(uri);

    return new Guardian(result);
  }
}
