import { Dispatch } from "redux";

import { AppointmentRepositoryImpl } from "../../../data/repositories/appointment-repository-impl";
import { AppointmentUsecaseImpl } from "../../../domain/usecases/appointment-usecase";
import {
  APPOINTMENT_LOAD_FAILURE,
  APPOINTMENT_LOAD_REQUEST,
  APPOINTMENT_LOAD_SUCCESS,
} from "../types/appointment-types";

import {
  APPOINTMENTS_LOAD_FAILURE,
  APPOINTMENTS_LOAD_REQUEST,
  APPOINTMENTS_LOAD_SUCCESS,
} from "../types/appointments-types";
import {
  REQUESTS_LOAD_FAILURE,
  REQUESTS_LOAD_REMOVE,
  REQUESTS_LOAD_REQUEST,
  REQUESTS_LOAD_SUCCESS,
} from "../types/requests-types";

export const getAppointmentsAction = (
  level: string,
  past: boolean,
  unpaid: boolean,
  location_id: string,
  guardian_id?: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: APPOINTMENTS_LOAD_REQUEST });

    try {
      const appointmentRepository = new AppointmentRepositoryImpl();
      const appointmentUsecase = new AppointmentUsecaseImpl(
        appointmentRepository
      );

      const result = await appointmentUsecase.GetAppointments(
        level,
        past,
        unpaid,
        location_id,
        guardian_id
      );

      dispatch({ type: APPOINTMENTS_LOAD_SUCCESS, payload: result });
    } catch (error) {
      console.log(error);
      dispatch({ type: APPOINTMENTS_LOAD_FAILURE, error });
    }
  };
};

export const getAppointmentAction = (
  guardian_id: string,
  appointment_id: string,
  location_id: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: APPOINTMENT_LOAD_REQUEST });

    try {
      const appointmentRepository = new AppointmentRepositoryImpl();
      const appointmentUsecase = new AppointmentUsecaseImpl(
        appointmentRepository
      );

      const result = await appointmentUsecase.GetAppointment(
        guardian_id,
        appointment_id,
        location_id
      );

      dispatch({ type: APPOINTMENT_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: APPOINTMENT_LOAD_FAILURE, error });
    }
  };
};

export const createAppointmentAction = (
  guardian_id: string,
  type: string,
  payment_method: string,
  reason: string,
  datetime: Date,
  patient: any,
  booking_notes: string,
  duration: number,
  location_id: string,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: APPOINTMENT_LOAD_REQUEST });

    try {
      const appointmentRepository = new AppointmentRepositoryImpl();
      const appointmentUsecase = new AppointmentUsecaseImpl(
        appointmentRepository
      );

      const result = await appointmentUsecase.CreateAppointmentExternal(
        guardian_id,
        type,
        payment_method,
        reason,
        datetime,
        patient,
        booking_notes,
        duration,
        location_id
      );

      dispatch({ type: APPOINTMENT_LOAD_SUCCESS, payload: result });
      props.history.push("/appointments");
    } catch (error) {
      dispatch({ type: APPOINTMENT_LOAD_FAILURE, error });
    }
  };
};

export const getAppointmentRequestsAction = (
  guardian_id: string,
  level: string,
  location_id: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: REQUESTS_LOAD_REQUEST });

    try {
      const appointmentRepository = new AppointmentRepositoryImpl();
      const appointmentUsecase = new AppointmentUsecaseImpl(
        appointmentRepository
      );

      const result = await appointmentUsecase.GetAppointmentRequests(
        guardian_id,
        level,
        location_id
      );

      dispatch({ type: REQUESTS_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: REQUESTS_LOAD_FAILURE, error });
    }
  };
};

export const acceptAppointmentRequestAction = (
  guardian_id: string,
  appointment_id: string,
  location_id: string,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const appointmentRepository = new AppointmentRepositoryImpl();
      const appointmentUsecase = new AppointmentUsecaseImpl(
        appointmentRepository
      );

      const result = await appointmentUsecase.AcceptAppointmentRequest(
        guardian_id,
        appointment_id,
        location_id
      );

      dispatch({ type: REQUESTS_LOAD_REMOVE, payload: result });
      props.closeBackdrop();
      props.showSnackbar(
        "success",
        "Appointment request accepted",
        "/account/appointments"
      );
    } catch (error) {
      props.closeBackdrop();
      props.showSnackbar("error", error.message);
    }
  };
};

export const rejectAppointmentRequestAction = (
  guardian_id: string,
  appointment_id: string,
  location_id: string,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const appointmentRepository = new AppointmentRepositoryImpl();
      const appointmentUsecase = new AppointmentUsecaseImpl(
        appointmentRepository
      );

      const result = await appointmentUsecase.RejectAppointmentRequest(
        guardian_id,
        appointment_id,
        location_id
      );

      dispatch({ type: REQUESTS_LOAD_REMOVE, payload: result });
      props.closeBackdrop();
      props.showSnackbar("info", "Appointment request rejected");
    } catch (error) {
      props.closeBackdrop();
      props.showSnackbar("error", error.message);
    }
  };
};

export const rescheduleAppointmentAction = (
  guardian_id: string,
  appointment_id: string,
  datetime: Date,
  location_id: string,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const appointmentRepository = new AppointmentRepositoryImpl();
      const appointmentUsecase = new AppointmentUsecaseImpl(
        appointmentRepository
      );

      const result = await appointmentUsecase.RescheduleAppointment(
        guardian_id,
        appointment_id,
        datetime,
        location_id
      );

      dispatch({ type: APPOINTMENT_LOAD_SUCCESS, payload: result });
      props.closeBackdrop();
      props.showSnackbar("success", "Appointment rescheduled");
      props.history.push(
        `/guardians/${guardian_id}/appointments/${appointment_id}`
      );
    } catch (error) {
      props.closeBackdrop();
      props.showSnackbar("error", error.message);
    }
  };
};

export const cancelAppointmentAction = (
  guardian_id: string,
  appointment_id: string,
  location_id: string,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const appointmentRepository = new AppointmentRepositoryImpl();
      const appointmentUsecase = new AppointmentUsecaseImpl(
        appointmentRepository
      );

      const result = await appointmentUsecase.CancelAppointment(
        guardian_id,
        appointment_id,
        location_id
      );

      dispatch({ type: APPOINTMENT_LOAD_SUCCESS, payload: result });
      props.closeBackdrop();
      props.showSnackbar("success", "Appointment cancelled");
      // props.history.push(
      //   `/guardians/${guardian_id}/appointments/${appointment_id}`
      // );
    } catch (error) {
      props.closeBackdrop();
      props.showSnackbar("error", error.message);
    }
  };
};

export const captureOfflinePaymentAction = (
  guardian_id: string,
  appointment_id: string,
  input_reference: string,
  actual_reference: string,
  location_id: string,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      if (input_reference.toUpperCase() !== actual_reference.toUpperCase()) {
        throw new Error("Provide a valid appointment reference code");
      }

      const appointmentRepository = new AppointmentRepositoryImpl();
      const appointmentUsecase = new AppointmentUsecaseImpl(
        appointmentRepository
      );

      const result = await appointmentUsecase.OfflinePayment(
        guardian_id,
        appointment_id,
        location_id
      );

      dispatch({ type: APPOINTMENT_LOAD_SUCCESS, payload: result });
      props.closeBackdrop();
      props.showSnackbar("success", "Appointment marked as paid for offline");
      // props.history.push(
      //   `/guardians/${guardian_id}/appointments/${appointment_id}`
      // );
    } catch (error) {
      props.closeBackdrop();
      props.showSnackbar("error", error.message);
    }
  };
};
