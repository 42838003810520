// @ts-check
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { rejectAppointmentRequestAction } from "../../redux/actions/appointment-actions";

export function RejectAppointmentRequestComponent({
  guardian_id,
  appointment_id,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
}) {
  const dispatch = useDispatch();
  const { location } = useSelector((state) => state.me);

  const reject = () => {
    openBackdrop();
    const props = { showSnackbar, closeBackdrop };
    dispatch(
      rejectAppointmentRequestAction(
        guardian_id,
        appointment_id,
        location.id,
        props
      )
    );
  };

  return (
    <>
      <div className="uk-text-right">
        <button
          className="uk-button uk-button-danger uk-button-small"
          type="button"
          onClick={reject}
          style={{
            lineHeight: "20px",
            color: "#f0506e",
            background: "transparent",
            border: "1px solid #f0506e",
          }}
        >
          Reject
        </button>
      </div>
    </>
  );
}
