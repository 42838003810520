import {
  GUARDIANS_LOAD_REQUEST,
  GUARDIANS_LOAD_SUCCESS,
  GUARDIANS_LOAD_FAILURE
} from '../types/guardians-types';

const initialState = {
  loading: false,
  error: null,
  success: false,
  guardians: []
};

function guardians(state = initialState, action: any = null) {
  switch (action.type) {
    case GUARDIANS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case GUARDIANS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case GUARDIANS_LOAD_SUCCESS:
      return {
        ...state,
        guardians: action.payload,
        success: true,
        loading: false,
        error: null
      };

    default:
      return state;
  }
}

export default guardians;
