// @ts-check
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGuardiansAction } from "../redux/actions/guardian-actions";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

import { GuardianListComponent } from "../components/guardians-list-component";
import { BackNavigationComponent } from "../../../../core/routes/components/back-navigation-component";
import { SearchDriversComponent } from "../components/search-drivers-component";
import { LoadingComponent } from "../components/loading-component";
import Grid from "@material-ui/core/Grid";
import { TextBackgroundComponent } from "../../../../core/utils/text-background-component";
import { NoResultsFound } from "../../../../core/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(2),
  },
}));

export default function GuardiansPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { location } = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(getGuardiansAction(location.id));
  }, [dispatch, location]);

  const { guardians, loading, error } = useSelector((state) => state.guardians);

  if (error) return <div>Error! {error.message}</div>;

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {loading && <LoadingComponent />}
          {!loading && (
            <>
              <BackNavigationComponent
                page="Guardians"
                path={[
                  {
                    page: "Home",
                    uri: "/",
                  },
                ]}
              />

              <Grid container spacing={2} justify="center">
                <Grid item xs={12} md={12}>
                  <TextBackgroundComponent text={"Health Professionals"} />
                </Grid>
                <Grid item xs={12} md={8}>
                  {guardians.length === 0 && (
                    <>
                      <NoResultsFound
                        message={"no health professionals found"}
                      />
                    </>
                  )}

                  {guardians.length > 0 && (
                    <div className="uk-margin">
                      <SearchDriversComponent />

                      <GuardianListComponent guardians={guardians} />
                    </div>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
