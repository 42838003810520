import { CalendarRepository } from "../../domain/repositories/calendar-repository";
import { CalendarSchedule } from "../../domain/entities/calendar-entity";

import { GuardianCalendar } from "../../domain/entities/guardian-calendar-entity";

import { CalendarAppointments } from "../../domain/entities/calendar-appointments-entity";
import { getGuardian } from "../../../guardians/data/datasources/get-guardian";
import { Guardian } from "../../../guardians/domain/entities/guardian-entity";
import { ApiUtil } from "../../../../core/utils/api-util";
import config from "../../../../core/config/config";

export class CalendarRepositoryImpl implements CalendarRepository {
  api: ApiUtil;

  constructor() {
    this.api = new ApiUtil();
  }

  async GetCalendarSchedule(guardian_id: string): Promise<CalendarSchedule> {
    const uri = `${config.uri.guardians_url}/org/calendar/${guardian_id}/schedule`;
    const result = await this.api.Get(uri);

    return new CalendarSchedule(result);
  }

  async GetCalendarAppointments(
    guardian_id: string
  ): Promise<CalendarAppointments[]> {
    const uri = `${config.uri.guardians_url}/org/calendar/${guardian_id}/appointments`;
    const result = await this.api.Get(uri);

    return result.map(
      (x) => new CalendarAppointments(x.patient, x.type, x.datetime)
    );
  }

  async UpdateGuardianCalendarSchedule(
    guardian_id: string,
    timezone: string,
    duration: number,
    daily_schedule: any[],
    telehealth_schedule: any[],
    after_hours_schedule: any[]
  ): Promise<CalendarSchedule> {
    const uri = `${config.uri.guardians_url}/org/calendar/${guardian_id}/schedule/edit`;
    const body = {
      timezone,
      duration,
      daily_schedule,
      telehealth_schedule,
      after_hours_schedule,
    };
    const result = await this.api.Post(uri, body);

    return new CalendarSchedule(result);
  }

  async GetGuardianCalendar(
    guardian_id: string,
    appointment_type: string
  ): Promise<GuardianCalendar> {
    const uri = `${config.uri.guardians_url}/calendar/guardian/${guardian_id}/availability`;
    const result = await this.api.Get(uri);

    // Get guardian
    const _guardian = await getGuardian(guardian_id);
    const guardian = new Guardian(_guardian);
    const appointmet_types = guardian.application.type_of_appointments_offered;
    const pricing = guardian.application.consultation_pricing;

    let calendar_slots = result;

    if (!appointmet_types.includes("afterhours")) {
      calendar_slots = calendar_slots.map((x) => ({
        ...x,
        afterhours_slots: [],
      }));
    }

    if (!appointmet_types.includes("video")) {
      calendar_slots = calendar_slots.map((x) => ({
        ...x,
        telehealth_slots: [],
      }));
    }

    if (!appointmet_types.includes("inperson")) {
      calendar_slots = calendar_slots.map((x) => ({
        ...x,
        normal_slots: [],
      }));
    }

    return new GuardianCalendar(appointmet_types, pricing, calendar_slots);
  }
}
