// @ts-check
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import { Grid } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import Container from "@material-ui/core/Container/Container";

import { AccountDetailsComponent } from "../components/account-details-component";
import { ImageNameComponent } from "../components/image-name-component";
import { BackNavigationComponent } from "../../../../core/routes/components/back-navigation-component";
import { logoutAction } from "../../../../core/redux/actions/logout-action";

const useStyles = makeStyles((theme) => ({
  driverAvatarContainer: {
    justifyContent: "center",
    display: "flex",
  },
  driverAvatar: {
    width: "7rem",
    height: "7rem",
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  extendedIcon: {
    // marginRight: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  heading: {
    margin: theme.spacing(2),
  },
  margin: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
  },
  clientLogo: {
    height: "2.5rem",
    objectFit: "contain",
  },
}));

export function Account({ me }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const signout = () => {
    const props = { history };
    dispatch(logoutAction(props));
  };

  return (
    <div>
      <div>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} md={12} justify="center">
            <ImageNameComponent me={me} />
          </Grid>
          <Grid item xs={12} md={8} justify="center">
            <AccountDetailsComponent account={me} />
            <div className="uk-margin">
              <button
                className="uk-button uk-button-danger uk-width-1-1"
                onClick={signout}
              >
                Log Out
              </button>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export function ProfilePage() {
  const classes = useStyles();

  const { me, loading } = useSelector((state) => state.me);

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {loading && <>loading</>}
          {!loading && me && (
            <>
              <BackNavigationComponent page="Account" />
              <Account me={me} />
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
