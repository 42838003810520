// @ts-check
import React from "react";

import Time from "../pages/time-slot.js";

export function SlotsRescheduleComponent({
  slots,
  date,
  bookAppointment,
  afterhours,
}) {
  return (
    <>
      {slots.length > 0 && (
        <div className="uk-margin">
          <Time
            date={date}
            afterhours={afterhours}
            bookAppointment={bookAppointment}
            time_slots={slots}
          />
        </div>
      )}
    </>
  );
}
