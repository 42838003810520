export class DailySchedule {
  active: boolean;
  day: string;
  short_day_name: string;
  start: string;
  end: string;

  constructor(args: any) {
    this.active = args.active;
    this.day = args.day;
    this.short_day_name = args.short_day_name;
    this.start = args.start;
    this.end = args.end;
  }
}

export class CalendarSchedule {
  timezone: string;
  duration: number;
  daily_schedule: DailySchedule[];
  telehealth_schedule: DailySchedule[];
  after_hours_schedule: DailySchedule[];

  constructor(args: any) {
    this.timezone = args.timezone;
    this.duration = args.duration;
    this.daily_schedule = args.daily_schedule;
    this.telehealth_schedule = args.telehealth_schedule;
    this.after_hours_schedule = args.after_hours_schedule;
  }
}
