// @ts-check
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CssBaseline from "@material-ui/core/CssBaseline";

import { LoadingComponent } from "../components/loading-component";

import { NormalScheduleComponent } from "../components/schedule/normal-schedule-component";
import { AfterHoursScheduleComponent } from "../components/schedule/after-hours-schedule-component";
import {
  getGuardianScheduleAction,
  updateGuardianScheduleAction,
} from "../../../calendar/presentation/redux/actions/calendar-actions";
import { BackdropComponent } from "../../../../core/utils/backdrop-component";
import { SnackbarComponent } from "../../../../core/utils/snackbar-component";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { timezones } from "../../../calendar/presentation/components/timezone";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

function AvailabilityTimes({
  guardian_id,
  schedule,
  appointment_types,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
}) {
  const dispatch = useDispatch();

  const [timezone, setTimezone] = useState(schedule.timezone);
  const [duration, setDuration] = useState(parseInt(schedule.duration));
  const [daily_schedule, updateDailySchedule] = useState(
    schedule.daily_schedule
  );
  const [telehealth_schedule, updateTelehealthSchedule] = useState(
    schedule.telehealth_schedule
  );
  const [after_hours_schedule, updateAfterHoursSchedule] = useState(
    schedule.after_hours_schedule
  );

  const _handleTextFieldChange = (e) => {
    e.preventDefault();
    setTimezone(e.target.value);
  };

  const _handleDurationChange = (e) => {
    e.preventDefault();
    setDuration(parseInt(e.target.value));
  };

  const onSave = async (e) => {
    e.preventDefault();

    openBackdrop();

    const props = { closeBackdrop, showSnackbar };
    dispatch(
      updateGuardianScheduleAction(
        guardian_id,
        timezone,
        duration,
        daily_schedule,
        telehealth_schedule,
        after_hours_schedule,
        props
      )
    );
  };

  return (
    <form onSubmit={onSave}>
      <TextField
        name="timezone"
        value={timezone}
        id="outlined-select-year"
        select
        label="Timezone"
        variant="outlined"
        fullWidth={true}
        onChange={_handleTextFieldChange}
      >
        {timezones.map((x, i) => (
          <MenuItem key={i} value={x.name}>
            {x.name}
          </MenuItem>
        ))}
      </TextField>
      <div className="uk-margin uk-card uk-card-default uk-card-body uk-padding-small uk-padding-remove-top uk-padding-remove-bottom  uk-border-rounded">
        <div className="uk-margin-small-top">
          <small>Slot/Session Duration</small>
        </div>

        <RadioGroup
          aria-label="duration"
          name="duration"
          onChange={_handleDurationChange}
          value={duration}
        >
          <div className="uk-grid-small  uk-child-width-expand" data-uk-grid>
            <div>
              <FormControlLabel
                value={30}
                control={<Radio />}
                label={
                  <small>
                    <b>30 minutes</b>
                  </small>
                }
              />
            </div>

            <div>
              <FormControlLabel
                value={60}
                control={<Radio />}
                label={
                  <small>
                    <b>60 minutes</b>
                  </small>
                }
              />
            </div>
          </div>
        </RadioGroup>
      </div>

      {appointment_types.includes("inperson") && (
        <>
          <div className="uk-margin  uk-text-center-">
            <div className="uk-margin uk-card uk-card-default uk-card-body uk-border-rounded uk-padding-small">
              <h6 className="uk-text-center">Normal In-Person Schedule</h6>
            </div>

            <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-padding-small">
              <NormalScheduleComponent
                daily_schedule={daily_schedule}
                updateDailySchedule={updateDailySchedule}
              />
            </div>
          </div>
        </>
      )}

      {appointment_types.includes("video") && (
        <>
          <div className="uk-margin  uk-text-center-">
            <div className="uk-margin uk-card uk-card-default uk-card-body uk-border-rounded uk-padding-small">
              <h6 className="uk-text-center">Normal Telehealth Schedule</h6>
            </div>

            <div className="uk-card uk-card-default uk-card-body uk-border-rounded uk-padding-small">
              <NormalScheduleComponent
                daily_schedule={telehealth_schedule}
                updateDailySchedule={updateTelehealthSchedule}
              />
            </div>
          </div>
        </>
      )}
      {appointment_types.includes("afterhours") && (
        <>
          <div className="uk-margin  uk-text-center-">
            <div className="uk-margin uk-text-center">
              <h6 className="uk-text-center uk-margin-remove">
                After Hours Schedule
              </h6>
              <small>
                Day's you can offer after hours virtual appointments
              </small>
            </div>

            <div
              className="uk-card uk-card-default uk-card-body uk-border-rounded uk-padding-small uk-light"
              style={{ background: "#012535" }}
            >
              <AfterHoursScheduleComponent
                after_hours_schedule={after_hours_schedule}
                updateAfterHoursSchedule={updateAfterHoursSchedule}
              />
            </div>
          </div>
        </>
      )}
      <>
        <div className="uk-margin">
          <button
            className="uk-button uk-button-primary uk-width-1-1 uk-margin-top"
            type="submit"
          >
            Save
          </button>
        </div>
      </>
    </form>
  );
}

function CalendarSchedule({
  guardian_id,
  schedule,
  appointment_types,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
}) {
  return (
    <div className="uk-width-1-1">
      <AvailabilityTimes
        guardian_id={guardian_id}
        schedule={schedule}
        appointment_types={appointment_types}
        openBackdrop={openBackdrop}
        closeBackdrop={closeBackdrop}
        showSnackbar={showSnackbar}
      />
    </div>
  );
}

export default function GuardianSchedulePage({ id, guardian }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGuardianScheduleAction(id));
  }, [dispatch, id]);

  const { schedule, loading, success, error } = useSelector(
    (state) => state.schedule
  );

  // helpers
  const [backdrop, setBackdrop] = useState(false);

  const openBackdrop = () => {
    setBackdrop(true);
  };

  const closeBackdrop = () => {
    setBackdrop(false);
  };

  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState({
    status: "success",
    description: "N/A",
  });

  const showSnackbar = (status, description) => {
    setSnackbar(true);
    setMessage({
      status,
      description,
    });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(false);
  };

  if (error) return <div>Error! {error.message}</div>;

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        {(loading || !success) && <LoadingComponent />}
        {!loading && success && (
          <>
            <CalendarSchedule
              guardian_id={id}
              schedule={schedule}
              appointment_types={
                guardian.application.type_of_appointments_offered
              }
              openBackdrop={openBackdrop}
              closeBackdrop={closeBackdrop}
              showSnackbar={showSnackbar}
            />

            <BackdropComponent open={backdrop} />
            <SnackbarComponent
              open={snackbar}
              onClose={closeSnackbar}
              severity={message.status}
              message={message.description}
            />
          </>
        )}
      </React.Fragment>
    </>
  );
}
