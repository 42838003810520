// @ts-check
import React from "react";

export function TextBackgroundComponent({ text }) {
  return (
    <div className="uk-section-default">
      <div
        className="uk-section uk-light- uk-background-cover"
        style={{
          background: `#012535`,
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
        }}
        data-uk-img
      >
        <div className="uk-container uk-text-center">
          <div className="uk-margin uk-light">
            <h3
              className="uk-margin-remove uk-visible@m"
              style={{ fontWeight: 900, fontSize: "2rem" }}
            >
              {text}
            </h3>

            <h3
              className="uk-margin-remove uk-hidden@m"
              style={{ fontWeight: 500, fontSize: "1.4rem" }}
            >
              {text}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
