import { Dispatch } from "redux";
import Cookies from "universal-cookie";

import { SET_USER_TOKEN } from "../../../../../core/redux/types/token-types";
import { AuthRepositoryImpl } from "../../../data/repositories/auth-repository-impl";
import { AuthUsecaseImpl } from "../../../domain/usecases/auth-usecase";
import {
  LOGIN_LOAD_REQUEST,
  LOGIN_LOAD_SUCCESS,
  LOGIN_LOAD_FAILURE,
} from "../types/login-types";
import {
  ORGANIZATIONS_LOAD_FAILURE,
  ORGANIZATIONS_LOAD_REQUEST,
  ORGANIZATIONS_LOAD_SUCCESS,
} from "../types/organizations-types";

export const loginAction = (
  organization_id: string,
  organization: string,
  email: string,
  password: string,
  props: any
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOGIN_LOAD_REQUEST });

    try {
      const loginRepository = new AuthRepositoryImpl();
      const loginUsecase = new AuthUsecaseImpl(loginRepository);

      const result = await loginUsecase.Login(
        organization_id,
        organization,
        email,
        password
      );

      const expiry_date = new Date();
      expiry_date.setDate(expiry_date.getDate() + 1);

      const cookies = new Cookies();
      cookies.set("gh_org_tok", result.token, {
        path: "/",
        expires: expiry_date,
      });

      dispatch({ type: LOGIN_LOAD_SUCCESS, payload: result });
      dispatch({ type: SET_USER_TOKEN, payload: result.token });

      props.history.push("/");
    } catch (error) {
      dispatch({ type: LOGIN_LOAD_FAILURE, error });
    }
  };
};

export const getOrganizationsAction = (keyword: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ORGANIZATIONS_LOAD_REQUEST });

    try {
      const loginRepository = new AuthRepositoryImpl();
      const loginUsecase = new AuthUsecaseImpl(loginRepository);

      const result = await loginUsecase.GetOrganizations();

      dispatch({ type: ORGANIZATIONS_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: ORGANIZATIONS_LOAD_FAILURE, error });
    }
  };
};
