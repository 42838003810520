import fetch from "isomorphic-fetch";
import config from "../../../../core/config/config";
import store from "../../../../core/redux/store";

export const uploadSignature = async (file: any): Promise<any> => {
  try {
    const state = store.getState();

    const body = new FormData();
    body.append("file", file);

    const uri = `${config.uri.upload_url}/upload-user-signature`;

    const options = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${state.token.token}`,
      }),
      body: body,
    };

    const response = await fetch(uri, options);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || "Something went wrong");
    }

    return result;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
