import fetch from "isomorphic-fetch";
import config from "../../../../core/config/config";

export const getOrganizations = async (): Promise<any> => {
  try {
    const uri = `${config.uri.organization_url}/organizations/auth`;

    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    const response = await fetch(uri, options);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || "Something went wrong");
    }

    return result;
  } catch (err) {
    throw err;
  }
};
