// @ts-check
import React from "react";

const styles = {
  pricing: {
    border: "1px solid rgb(73, 168, 97)",
    background: "rgb(73, 168, 97)",
    paddingTop: "5px",
    paddingBottom: "5px",
    color: "#fff",
    fontFamily: "Gilroy-ExtraBold",
  },
  appointment: {
    border: "1px solid",
    paddingTop: "5px",
    paddingBottom: "5px",
    fontFamily: "Gilroy-ExtraBold",
  },
};

export function AppointmentDetailsComponent({ preappointment }) {
  const date = preappointment.datetime;
  const day = date.toLocaleDateString("default", { weekday: "short" });
  const month = date.toLocaleString("default", { month: "short" });
  const time = date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  const formated_day = `${day}, ${date.getDate()} ${month} at ${time}`;

  return (
    <div>
      {/* <Infoo slotsDay={formated_day} type={preappointment.type} /> */}
      <AppointmentBookingInfo
        slotsDay={formated_day}
        type={preappointment.type}
      />
      {/* <AppointmentPersonComponent appointment={preappointment} /> */}
    </div>
  );
}

function AppointmentBookingInfo({ slotsDay, type }) {
  return (
    <div className="uk-margin-medium-top-">
      <div className="uk-card uk-padding-small uk-padding-remove-vertical">
        <div className="uk-grid-small uk-child-width-expand" data-uk-grid>
          <div className="uk-display-inline uk-width-3-5">
            <div>
              <small className="uk-margin-left-">
                <h6 className="uk-margin-remove uk-display-inline">
                  {slotsDay}
                </h6>
              </small>
            </div>
          </div>

          <div className="uk-text-right uk-width-2-5">
            <small
              className="uk-card uk-card-default uk-padding-small uk-border-rounded uk-h6 uk-margin-small-right-"
              style={styles.appointment}
            >
              {type}
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}
