// @ts-check
import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import EmailIcon from "@material-ui/icons/Email";
import CallIcon from "@material-ui/icons/Call";
import { IconButton, ListItemSecondaryAction } from "@material-ui/core";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import BusinessIcon from "@material-ui/icons/Business";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2)
  },
  inline: {
    display: "inline",
  },
  heading: {
    margin: theme.spacing(2),
  },

  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  margin: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  avatar: {
    "& > *": {
      objectFit: "contain",
    },
  },
}));

function Mail({ account }) {
  const classes = useStyles();

  return (
    <List className={classes.margin}>
      <ListItem>
        <ListItemText
          primary={<small>Email</small>}
          secondary={account.email}
        ></ListItemText>

        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="vie key">
            <EmailIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}

function Call({ account }) {
  const classes = useStyles();

  return (
    <List className={classes.margin}>
      <ListItem>
        <ListItemText
          primary={<small>Phone no.</small>}
          secondary={account.contact}
        ></ListItemText>

        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="vie key">
            <CallIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}

function Account({ account }) {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      <ListItem>
        <ListItemAvatar>
          <Avatar
            variant="square"
            alt={account.organization.name}
            src={account.organization.logo || "/static/images/avatar/1.jpg"}
            className={classes.avatar}
          />
        </ListItemAvatar>
        <ListItemText
          primary={<small>{account.organization.name}</small>}
          secondary={account.organization.organization}
        ></ListItemText>

        <ListItemSecondaryAction>
          <IconButton edge="end">
            <BusinessIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}

export function AccountDetailsComponent({ account }) {
  return (
    <div>
      <Account account={account} />
      <Mail account={account} />
      <Call account={account} />
    </div>
  );
}
