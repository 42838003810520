class Calendar {
  date: Date;
  duration: number;
  normal_slots: Date[];
  telehealth_slots: Date[];
  afterhours_slots: Date[];

  constructor(
    date: string,
    duration: number,
    normal_slots: string[],
    telehealth_slots: string[],
    afterhours_slots: string[]
  ) {
    this.date = new Date(date);
    this.duration = duration;
    this.normal_slots = normal_slots.map((slot) => new Date(slot));
    this.telehealth_slots = telehealth_slots.map((slot) => new Date(slot));
    this.afterhours_slots = afterhours_slots.map((slot) => new Date(slot));
  }
}

class ConsultationPricing {
  currency: string;
  inperson_amount: number;
  video_amount: number;
  after_hours_amount: number;

  constructor(pricing: any) {
    this.currency = pricing.currency;
    this.inperson_amount = pricing.inperson_amount;
    this.video_amount = pricing.video_amount;
    this.after_hours_amount = pricing.after_hours_amount;
  }
}

export class GuardianCalendar {
  appointment_types: string[];
  pricing: ConsultationPricing;
  days: any[];

  constructor(appointment_types: string[], pricing: any, days: any[]) {
    this.appointment_types = appointment_types;
    this.pricing = new ConsultationPricing(pricing);
    this.days = days.map(
      (day) =>
        new Calendar(
          day.date,
          day.duration,
          day.normal_slots,
          day.telehealth_slots,
          day.afterhours_slots
        )
    );
  }
}
