interface CalendarAppointmentsInterface {
  patient: string;
  type: string;
  datetime: Date;
}

export class CalendarAppointments implements CalendarAppointmentsInterface {
  patient: string;
  type: string;
  datetime: Date;

  constructor(patient: string, type: string, datetime: Date) {
    this.patient = patient;
    this.type = type;
    this.datetime = new Date(datetime);
  }
}
