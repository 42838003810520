// @ts-check
import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import { GuardianHeader } from "../../../guardians/presentation/components/guardian-list-item-component";

import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AnalyticsPage from "../../../analytics/presentation/pages/analytics-page";

export default function GuardianAnalyticsModal({ guardian }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton edge="end" onClick={handleClickOpen}>
        <AssessmentIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div style={{ minWidth: "552px" }}>
            <List>
              <GuardianHeader guardian={guardian} />
            </List>

            <hr className="uk-margin-remove" />

            <div className="uk-margin">
              <AnalyticsPage level="guardian" id={guardian.id} />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
