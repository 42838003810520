import {
  REQUESTS_LOAD_REQUEST,
  REQUESTS_LOAD_SUCCESS,
  REQUESTS_LOAD_FAILURE,
  REQUESTS_LOAD_REMOVE
} from '../types/requests-types';

const initialState = {
  loading: false,
  error: null,
  success: false,
  requests: []
};

function requests(state = initialState, action: any = null) {
  switch (action.type) {
    case REQUESTS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case REQUESTS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case REQUESTS_LOAD_SUCCESS:
      return {
        ...state,
        requests: action.payload,
        success: true,
        loading: false,
        error: null
      };

    case REQUESTS_LOAD_REMOVE:
      return {
        ...state,
        requests: state.requests.filter((request: any) => request.id !== action.payload.id)
      };

    default:
      return state;
  }
}

export default requests;
