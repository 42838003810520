export class Guardian {
  id: string;
  name: string;
  email: string;
  contact: string;
  image: string;
  gender: string;
  application: any;
  organization: any;

  constructor(args: any) {
    this.id = args.id;
    this.name = args.name;
    this.email = args.email;
    this.contact = args.contact;
    this.image = args.image;
    this.gender = args.gender;
    this.application = args.application;
    this.organization = args.organization;
  }
}
