// @ts-check
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { cancelAppointmentAction } from "../../redux/actions/appointment-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export function CancelButtonComponent({
  guardian_id,
  appointment_id,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { location } = useSelector((state) => state.me);

  const end = () => {
    openBackdrop();

    const props = { history, closeBackdrop, showSnackbar };
    dispatch(
      cancelAppointmentAction(guardian_id, appointment_id, location.id, props)
    );
  };

  return (
    <div className="uk-margin">
      <List className={classes.root}>
        <ListItem>
          <ListItemText
            primary={
              <button
                className={`uk-button uk-button-danger uk-width-1-1`}
                onClick={end}
              >
                Cancel Appointment
              </button>
            }
          />
        </ListItem>
      </List>
    </div>
  );
}
