// @ts-check
import React from "react";
import { Link, useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

import IconButton from "@material-ui/core/IconButton/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(0),
    padding: theme.spacing(0),
    marginBottom: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(0),
  },
  crumb: {
    color: "inherit",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  space: {
    padding: theme.spacing(1),
  },
}));

function SimpleBreadcrumbs({ page, path }) {
  const classes = useStyles();

  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.space}>
      {path.map((x, key) => (
        <Link key={key} color="inherit" to={x.uri} className={classes.crumb}>
          {x.page}
        </Link>
      ))}

      <Typography color="textPrimary">{page}</Typography>
    </Breadcrumbs>
  );
}

export function BackNavigationComponent({
  page,
  uri = "/",
  path = [
    {
      page: "Home",
      uri: "/",
    },
  ],
}) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <List className={classes.root}>
        <ListItem className={classes.padding}>
          <ListItemIcon>
            <IconButton
              onClick={() => history.push(path[path.length - 1]["uri"])}
            >
              <ArrowBackIcon />
            </IconButton>
          </ListItemIcon>
          <ListItemText
            primary={<SimpleBreadcrumbs page={page} path={path} />}
            secondary={null}
          />
        </ListItem>
      </List>
    </>
  );
}
