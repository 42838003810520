// @ts-check

import React from "react";

import { styles } from "../../../../core/constants";

function TimeSlot({ setTime, time, afterhours }) {
  const timeStyle = !afterhours
    ? styles.time
    : { ...styles.time, color: "white" };

  const cardStyle = afterhours
    ? { ...styles.cursorPointer, background: "#002637" }
    : { ...styles.cursorPointer };

  const slot = new Date(time).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return (
    <div>
      <div
        className="uk-card uk-card-default uk-card-body uk-card-hover uk-padding-small uk-padding@m uk-border-rounded"
        onClick={() => setTime(time)}
        style={cardStyle}
      >
        <h5
          // @ts-ignore
          style={timeStyle}
        >
          {slot.toLowerCase()}
        </h5>
      </div>
    </div>
  );
}

function Grid({ setTime, afterhours, time_slots }) {
  return (
    <div
      className="uk-grid-small uk-child-width-1-3 uk-child-width-1-3@s uk-child-width-1-4@m uk-text-center uk-margin-small-top"
      data-uk-grid
    >
      {time_slots.map((time, key) => (
        <TimeSlot
          key={key}
          setTime={setTime}
          time={time}
          afterhours={afterhours}
        />
      ))}
    </div>
  );
}

export default function App({ date, afterhours, bookAppointment, time_slots }) {
  const setTime = (time) => {
    bookAppointment(time, afterhours);
  };

  return (
    <div className="uk-text-center">
      <Grid setTime={setTime} afterhours={afterhours} time_slots={time_slots} />
    </div>
  );
}
