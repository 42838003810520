// @ts-check
import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

import { BackNavigationComponent } from "../../../../core/routes/components/back-navigation-component";
import { TextBackgroundComponent } from "../components/text-background-component";
import Grid from "@material-ui/core/Grid";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAppointmentAction } from "../redux/actions/appointment-actions";
import { BookLoadingComponent } from "../../../booking/presentation/components/book-loading-component";
import { AppointmentComponent } from "../components/appointment-component";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(2),
  },
}));

export default function AppointmentPage(props) {
  // @ts-ignore
  const { guardian_id, appointment_id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { location } = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(getAppointmentAction(guardian_id, appointment_id, location.id));
  }, [dispatch, guardian_id, appointment_id, location]);

  const { appointment, loading, success, error } = useSelector(
    (state) => state.appointment
  );

  if (error) return <div>Error! {error.message}</div>;

  const params = new URLSearchParams(props.location.search);
  const level = params.get("level");
  let path = [
    {
      page: "Home",
      uri: "/",
    },
    {
      page: "Appointments",
      uri: "/appointments",
    },
  ];

  if (level === "guardian") {
    path = [
      {
        page: "Home",
        uri: "/",
      },
      {
        page: "Guardians",
        uri: "/guardians",
      },
      {
        page: "Guardian",
        uri: `/guardians/${guardian_id}`,
      },
    ];
  }

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {(loading || !success) && <BookLoadingComponent />}
          {!loading && success && (
            <>
              <BackNavigationComponent
                page="Appointment"
                uri="/appointment"
                path={path}
              />

              <Grid container spacing={2} justify="center">
                <Grid item xs={12} md={12}>
                  <TextBackgroundComponent text={"Appointment"} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <AppointmentComponent appointment={appointment} />
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
