import { Dispatch } from "redux";

import { CalendarRepositoryImpl } from "../../../data/repositories/calendar-repository-impl";
import { CalendarUsecaseImpl } from "../../../domain/usecases/calendar-usecase";
import {
  CALENDAR_LOAD_FAILURE,
  CALENDAR_LOAD_REQUEST,
  CALENDAR_LOAD_SUCCESS,
} from "../types/calendar-types";
import {
  SCHEDULE_LOAD_FAILURE,
  SCHEDULE_LOAD_REQUEST,
  SCHEDULE_LOAD_SUCCESS,
} from "../types/schedule-types";

export const getGuardianCalendarAction = (
  guardian_id: string,
  appointment_type: string
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: CALENDAR_LOAD_REQUEST });

    try {
      const calenderRepository = new CalendarRepositoryImpl();
      const calenderUsecase = new CalendarUsecaseImpl(calenderRepository);

      const result = await calenderUsecase.GetGuardianCalendar(
        guardian_id,
        appointment_type
      );

      dispatch({ type: CALENDAR_LOAD_SUCCESS, payload: result });
    } catch (error) {
      alert(error);
      dispatch({ type: CALENDAR_LOAD_FAILURE, error });
    }
  };
};

export const getGuardianScheduleAction = (guardian_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: SCHEDULE_LOAD_REQUEST });

    try {
      const calenderRepository = new CalendarRepositoryImpl();
      const calenderUsecase = new CalendarUsecaseImpl(calenderRepository);

      const result = await calenderUsecase.GetCalendarSchedule(guardian_id);

      dispatch({ type: SCHEDULE_LOAD_SUCCESS, payload: result });
    } catch (error) {
      alert(error);
      dispatch({ type: SCHEDULE_LOAD_FAILURE, error });
    }
  };
};

export const updateGuardianScheduleAction = (
  guardian_id: string,
  timezone: string,
  duration: number,
  daily_schedule: any[],
  telehealth_schedule: any[],
  after_hours_schedule: any[],
  props: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      const calenderRepository = new CalendarRepositoryImpl();
      const calenderUsecase = new CalendarUsecaseImpl(calenderRepository);

      daily_schedule = daily_schedule.map(function (item) {
        delete item._id;
        return item;
      });
      telehealth_schedule = telehealth_schedule.map(function (item) {
        delete item._id;
        return item;
      });
      after_hours_schedule = after_hours_schedule.map(function (item) {
        delete item._id;
        return item;
      });

      const result = await calenderUsecase.UpdateGuardianCalendarSchedule(
        guardian_id,
        timezone,
        duration,
        daily_schedule,
        telehealth_schedule,
        after_hours_schedule
      );

      dispatch({ type: SCHEDULE_LOAD_SUCCESS, payload: result });
      props.closeBackdrop();
      props.showSnackbar("success", "Schedule updated.");
    } catch (error) {
      props.closeBackdrop();
      props.showSnackbar("error", error.message);
    }
  };
};
