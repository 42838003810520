import { Dispatch } from "redux";
import { AnalyticsRepository } from "../../../data/repositories/analytics-repository";
import { AnalyticsUsecase } from "../../../domain/usecases/analytics-usecase";
import {
  ANALYTICS_LOAD_FAILURE,
  ANALYTICS_LOAD_REQUEST,
  ANALYTICS_LOAD_SUCCESS,
} from "../types/analytics-types";

export const getAnalyticsAction = (level: string, filter: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: ANALYTICS_LOAD_REQUEST });

    try {
      const analyticsRepository = new AnalyticsRepository();
      const analyticsUsecase = new AnalyticsUsecase(analyticsRepository);

      const [bookings, reasons] = await Promise.all([
        analyticsUsecase.GetWeeklyBookings(level, filter),
        analyticsUsecase.GetWeeklyReasons(level, filter),
      ]);

      const payload = {
        bookings,
        reasons,
      };

      dispatch({ type: ANALYTICS_LOAD_SUCCESS, payload: payload });
    } catch (error) {
      console.log(error);
      dispatch({ type: ANALYTICS_LOAD_FAILURE, error });
    }
  };
};
