// @ts-check
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  indicator: {},
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  card: {
    marginBottom: theme.spacing(2),
  },
}));

export function CancelReschedule({ appointment }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <List className={classes.root}>
        <ListItem>
          <Grid
            container
            justify="center"
            spacing={2}
            style={{ padding: "8px" }}
          >
            <Grid xs={6} md={6}>
              <ListItemText
                className="uk-margin-small-right"
                primary={
                  <button
                    className="uk-button uk-button-secondary uk-button-small uk-width-1-1"
                    onClick={() =>
                      history.push(
                        `/guardians/${appointment.medical_guardian.id}/appointments/${appointment.id}/cancel`
                      )
                    }
                    style={{
                      background: "unset",
                      color: "#012535",
                      border: "1px solid #012535",
                    }}
                  >
                    <small>
                      Cancel <span className="uk-visible@m">Appointment</span>
                    </small>
                  </button>
                }
              />
            </Grid>
            <Grid xs={6} md={6}>
              <ListItemText
                className="uk-margin-small-left"
                primary={
                  <button
                    className="uk-button uk-button-secondary uk-button-small uk-width-1-1"
                    onClick={() =>
                      history.push(
                        `/guardians/${appointment.medical_guardian.id}/appointments/${appointment.id}/reschedule`
                      )
                    }
                  >
                    <small>
                      Reschedule{" "}
                      <span className="uk-visible@m">Appointment</span>
                    </small>
                  </button>
                }
              />
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </div>
  );
}

export function OfflinePayment({ appointment }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <List className={classes.root}>
      <ListItem>
        <ListItemText
          primary={
            <button
              className="uk-button uk-button-secondary uk-button-small uk-width-1-1"
              onClick={() =>
                history.push(
                  `/guardians/${appointment.medical_guardian.id}/appointments/${appointment.id}/offline/payment`
                )
              }
              style={{
                background: "unset",
                color: "#49a861",
                border: "1px solid #49a861",
              }}
            >
              <small>Capture Offline Paymment</small>
            </button>
          }
        />
      </ListItem>
    </List>
  );
}
