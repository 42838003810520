import fetch from 'isomorphic-fetch';
import store from '../redux/store';

export class ApiUtil {
  token: String;

  constructor() {
    const state = store.getState();

    this.token = state.token.token;
  }

  async Get(uri: string): Promise<any> {
    try {
      const state = store.getState();

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.token.token}`
        }
      };

      const response = await fetch(uri, options);
      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message || 'Something went wrong');
      }

      return result;
    } catch (err) {
      throw err;
    }
  }

  async Post(uri: string, body: any = undefined): Promise<any> {
    try {
      const state = store.getState();

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.token.token}`
        },
        body: JSON.stringify(body)
      };

      const response = await fetch(uri, options);
      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message || 'Something went wrong');
      }

      return result;
    } catch (err) {
      throw err;
    }
  }
}
