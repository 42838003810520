// @ts-check
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

import { BackNavigationComponent } from "../../../../core/routes/components/back-navigation-component";
import ScheduleIcon from "@material-ui/icons/Schedule";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";

import { LoadingComponent } from "../components/loading-component";
import Grid from "@material-ui/core/Grid";
import { GuardianImageComponent } from "../components/guardian-image-component";
import { GuardianApplicationComponent } from "../components/guardian-application-component.js";
import { getGuardianAction } from "../redux/actions/guardian-actions";
import { BookingCalendarPage } from "../../../calendar/presentation/pages/calender-page";

import DateRangeIcon from "@material-ui/icons/DateRange";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { GuardianAppointmentsPage } from "../../../appointments/presentation/pages/guardian-appointments-page";
import GuardianSchedulePage from "./guardian-schedule-page";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(2),
  },
}));

function Guardian({ guardian }) {
  const [view, setView] = useState("calendar");

  return (
    <Grid container spacing={2} justify="center">
      <Grid item xs={12} md={12}>
        <GuardianImageComponent guardian={guardian} />
      </Grid>

      <Grid item xs={12} md={8}>
        <>
          <div>
            <ul className="uk-child-width-expand" data-uk-tab>
              <li className={(view === `calendar` && "uk-active") || ""}>
                {/* eslint-disable-next-line */}
                <a onClick={() => setView("calendar")}>
                  <small className="uk-display-block uk-padding-small">
                    <span className="uk-margin-small-right">
                      <DateRangeIcon fontSize={"small"} />
                    </span>
                    <b>Calendar</b>
                  </small>
                </a>
              </li>

              <li className={(view === `appointments` && "uk-active") || ""}>
                {/* eslint-disable-next-line */}
                <a onClick={() => setView("appointments")}>
                  <small className="uk-display-block uk-padding-small">
                    <span className="uk-margin-small-right">
                      <ScheduleIcon fontSize={"small"} />
                    </span>

                    <b>Appointments</b>
                  </small>
                </a>
              </li>

              <li className={(view === `profile` && "uk-active") || ""}>
                {/* eslint-disable-next-line */}
                <a onClick={() => setView("profile")}>
                  <small className="uk-display-block uk-padding-small">
                    <span className="uk-margin-small-right">
                      <AccountCircleIcon fontSize={"small"} />
                    </span>

                    <b>Profile</b>
                  </small>
                </a>
              </li>

              <li className={(view === `schedule` && "uk-active") || ""}>
                {/* eslint-disable-next-line */}
                <a onClick={() => setView("schedule")}>
                  <small className="uk-display-block uk-padding-small">
                    <span className="uk-margin-small-right">
                      <EventAvailableIcon fontSize={"small"} />
                    </span>
                    <b>Schedule</b>
                  </small>
                </a>
              </li>
            </ul>
          </div>
        </>

        {view === "calendar" && <BookingCalendarPage id={guardian.id} />}

        {view === "appointments" && (
          <GuardianAppointmentsPage id={guardian.id} />
        )}
        {view === "profile" && (
          <GuardianApplicationComponent guardian={guardian} />
        )}
        {view === "schedule" && (
          <GuardianSchedulePage id={guardian.id} guardian={guardian} />
        )}
      </Grid>
    </Grid>
  );
}

export default function GuardianPage() {
  // @ts-ignore
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { location } = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(getGuardianAction(id, location.id));
  }, [dispatch, id, location]);

  const { guardian, loading, success, error } = useSelector(
    (state) => state.guardian
  );

  if (error) return <div>Error! {error.message}</div>;

  // return <Appointments guardians={guardians} />;
  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {(loading || !success) && <LoadingComponent />}
          {!loading && success && (
            <>
              <BackNavigationComponent
                page="Guardian"
                uri="/guardians"
                path={[
                  {
                    page: "Home",
                    uri: "/",
                  },
                  {
                    page: "Guardians",
                    uri: "/guardians",
                  },
                ]}
              />

              <Guardian guardian={guardian} />
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
