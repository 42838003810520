// @ts-check
import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import PastAppointmentsComponent from "./past-appointments-page";
import UpcomingAppointmentsComponent from "./upcoming-appointments-page";
import { makeStyles } from "@material-ui/core/styles";

import { BackNavigationComponent } from "../../../../core/routes/components/back-navigation-component";
import { TextBackgroundComponent } from "../components/text-background-component";
import Grid from "@material-ui/core/Grid";
import AppointmentRequestsPage from "./appointment-requests-page";
import UpaidAppointmentsPage from "./unpaid-appointments-page";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(2),
  },
}));

export function AppointmentsMenu() {
  const [view, setView] = useState("upcoming");

  return (
    <React.Fragment>
      <CssBaseline />

      <div className="uk-margin">
        {/* <hr className="uk-margin-remove " /> */}
        <>
          <ul
            className="uk-child-width-expand uk-margin-remove-top"
            data-uk-tab
          >
            <li className={"uk-active"}>
              {/* eslint-disable-next-line */}
              <a
                onClick={() => setView("upcoming")}
                style={{
                  border: view === "upcoming" ? "1px solid #f96268" : "",
                  background: view === "upcoming" ? "#f96268" : "",
                  color: view === "upcoming" ? "#ffffff" : "",
                  padding: view === "upcoming" ? 0 : "",
                  borderRadius: view === "upcoming" ? "0.2em" : "",
                  marginBottom: view === "upcoming" ? "10px" : "",
                }}
              >
                <small className="uk-display-block uk-padding-small">
                  <b>Upcoming</b>
                </small>
              </a>
            </li>

            <li>
              {/* eslint-disable-next-line */}
              <a
                onClick={() => setView("requests")}
                style={{
                  border: view === "requests" ? "1px solid #f96268" : "",
                  background: view === "requests" ? "#f96268" : "",
                  color: view === "requests" ? "#ffffff" : "",
                  padding: view === "requests" ? 0 : "",
                  borderRadius: view === "requests" ? "0.2em" : "",
                  marginBottom: view === "requests" ? "10px" : "",
                }}
              >
                <small className="uk-display-block uk-padding-small">
                  <b>Requests</b>
                </small>
              </a>
            </li>

            <li>
              {/* eslint-disable-next-line */}
              <a
                onClick={() => setView("unpaid")}
                style={{
                  border: view === "unpaid" ? "1px solid #f96268" : "",
                  background: view === "unpaid" ? "#f96268" : "",
                  color: view === "unpaid" ? "#ffffff" : "",
                  padding: view === "unpaid" ? 0 : "",
                  borderRadius: view === "unpaid" ? "0.2em" : "",
                  marginBottom: view === "unpaid" ? "10px" : "",
                }}
              >
                <small className="uk-display-block uk-padding-small">
                  <b>Unpaid</b>
                </small>
              </a>
            </li>

            <li>
              {/* eslint-disable-next-line */}
              <a
                onClick={() => setView("past")}
                style={{
                  border: view === "past" ? "1px solid #f96268" : "",
                  background: view === "past" ? "#f96268" : "",
                  color: view === "past" ? "#ffffff" : "",
                  padding: view === "past" ? 0 : "",
                  borderRadius: view === "past" ? "0.2em" : "",
                  marginBottom: view === "past" ? "10px" : "",
                }}
              >
                <small className="uk-display-block uk-padding-small">
                  <b>Past</b>
                </small>
              </a>
            </li>
          </ul>
        </>
      </div>

      {view === "upcoming" && (
        <UpcomingAppointmentsComponent
          level={"organization"}
          guardian_id={"null"}
        />
      )}

      {view === "requests" && (
        <AppointmentRequestsPage level={"organization"} guardian_id={"null"} />
      )}

      {view === "unpaid" && (
        <UpaidAppointmentsPage level={"organization"} guardian_id={"null"} />
      )}

      {view === "past" && (
        <PastAppointmentsComponent
          level={"organization"}
          guardian_id={"null"}
        />
      )}
    </React.Fragment>
  );
}

export default function AppointmentsPage() {
  const classes = useStyles();

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          <BackNavigationComponent
            page="Appointments"
            uri="/appointments"
            path={[
              {
                page: "Home",
                uri: "/",
              },
            ]}
          />

          <Grid container spacing={2} justify="center">
            <Grid item xs={12} md={12}>
              <TextBackgroundComponent text={"Appointments"} />
            </Grid>
            <Grid item xs={12} md={8}>
              <AppointmentsMenu />
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    </>
  );
}
