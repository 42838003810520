// @ts-check
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { acceptAppointmentRequestAction } from "../../redux/actions/appointment-actions";

export function AcceptAppointmentRequestComponent({
  guardian_id,
  appointment_id,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
}) {
  const dispatch = useDispatch();
  const { location } = useSelector((state) => state.me);

  const accept = () => {
    openBackdrop();

    const props = { showSnackbar, closeBackdrop };
    dispatch(
      acceptAppointmentRequestAction(
        guardian_id,
        appointment_id,
        location.id,
        props
      )
    );
  };

  return (
    <>
      <div className="uk-text-center">
        <button
          className="uk-button uk-button-primary uk-border-rounded uk-width-1-1"
          type="button"
          // style={styles.confirm}
          onClick={accept}
        >
          Accept Request
        </button>
      </div>
    </>
  );
}
