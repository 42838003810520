// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

function Media(props) {
  return (
    <div>
      <div className="uk-container">
        <Box width={'100%'} my={3}>
          <Skeleton variant="rect" width={'100%'} height={'2.5rem'} />
        </Box>
      </div>

      <Box width={'100%'} mt={3}>
        <Skeleton variant="rect" width={'100%'} height={'10rem'} />
      </Box>

      <div className="uk-container">
        <div className="uk-width-3-5@m uk-width-1-1 uk-align-center  uk-margin-top">
          <Box width={'100%'} my={3}>
            <Skeleton variant="rect" width={'100%'} height={'2.5rem'} />
          </Box>

          <Box width={'100%'} marginRight={0.5} my={3}>
            <Box display="flex" alignItems="center">
              <Box margin={1}>
                <Skeleton variant="circle">
                  <Avatar />
                </Skeleton>
              </Box>
              <Box width="100%">
                <Skeleton width="100%">
                  <Typography>.</Typography>
                </Skeleton>
                <Skeleton animation="wave" width="40%"></Skeleton>
              </Box>
            </Box>
          </Box>

          <Box width={'100%'} my={3}>
            <Skeleton variant="rect" width={'100%'} />
          </Box>

          <Box width={'100%'} my={3}>
            <Skeleton variant="rect" width={'100%'} height={'4rem'} />
          </Box>

          <Box width={'100%'} my={2}>
            <Skeleton variant="rect" width={'100%'} />
          </Box>

          <Grid container spacing={1}>
            {Array.from(new Array(2)).map((item, index) => (
              <Grid key={index} item xs={6} md={6}>
                <Box key={index} width={'100%'} marginRight={0.5} my={1}>
                  <Skeleton variant="rect" width={'100%'} height={'3.5rem'} />
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box width={'100%'} my={3}>
            <Skeleton variant="rect" width={'100%'} height={'3.5rem'} />
          </Box>

          <Box width={'100%'} my={3}>
            <Skeleton variant="rect" width={'100%'} height={'3.5rem'} />
          </Box>
        </div>
      </div>
    </div>
  );
}

Media.propTypes = {
  loading: PropTypes.bool
};

export function BookLoadingComponent() {
  return (
    <Box overflow="hidden">
      <Media loading />
    </Box>
  );
}
