// @ts-check
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { Grid, ListItemSecondaryAction } from "@material-ui/core";

import { AppointmentTypeIcon } from "./helpers";
import { RejectAppointmentRequestComponent } from "./requests/reject-appointment-request-component";
import { AcceptAppointmentRequestComponent } from "./requests/accept-appointment-request-component";
import CallIcon from "@material-ui/icons/Call";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  indicator: {},
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  card: {
    marginBottom: theme.spacing(2),
  },
}));

function Guardian({ user }) {
  return (
    <ListItem>
      <ListItemAvatar style={{ minWidth: "25px" }}>
        <Avatar
          style={{ height: "20px", width: "20px" }}
          alt={user.name}
          src={user.image || "/static/images/avatar/1.jpg"}
        />
      </ListItemAvatar>
      <ListItemText
        primary={<small>{user.name} (Guardian)</small>}
        secondary={null}
      />
    </ListItem>
  );
}

function Patient({ user, reason, type }) {
  const classes = useStyles();

  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar
          alt={user.name}
          src={user.image || "/static/images/avatar/1.jpg"}
        />
      </ListItemAvatar>
      <ListItemText
        primary={<small>{user.name}</small>}
        secondary={<React.Fragment>{reason}</React.Fragment>}
      />

      <ListItemSecondaryAction>
        <ListItemText
          className={classes.right}
          primary={
            <>
              <AppointmentTypeIcon type={type} />
              {type === "afterhours" && <AppointmentTypeIcon type={"video"} />}
            </>
          }
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export function AppointmentRequestsListComponent({
  appointments,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
}) {
  const classes = useStyles();

  const renderUsers = appointments.map((appointment, index) => {
    const type = appointment.type;

    const date = appointment.datetime;
    const day = date.toLocaleDateString("default", { weekday: "short" });
    const month = date.toLocaleString("default", { month: "short" });
    const time = date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });

    const formated_day = `${day}, ${date.getDate()} ${month} at ${time}`;

    return (
      <div key={index}>
        <List className={classes.root}>
          <ListItem alignItems="flex-start">
            <ListItemText primary={<small>{formated_day}</small>} />

            <ListItemText
              className={classes.right}
              primary={
                <RejectAppointmentRequestComponent
                  guardian_id={appointment.medical_guardian.id}
                  appointment_id={appointment.id}
                  openBackdrop={openBackdrop}
                  closeBackdrop={closeBackdrop}
                  showSnackbar={showSnackbar}
                />
              }
            />
          </ListItem>

          <Divider
            variant="middle"
            style={{
              borderTop: "1px dashed #e5e5e5",
              background: "none",
            }}
          />

          <Grid container spacing={2} style={{ padding: "0.5rem 0.7rem" }}>
            <Grid xs={6} md={6}>
              <Guardian user={appointment.medical_guardian} />
            </Grid>
            <Grid xs={6} md={6}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  className={classes.right}
                  primary={<small>Appointment #{appointment.reference}</small>}
                />
              </ListItem>
            </Grid>
          </Grid>

          <Divider
            variant="middle"
            style={{
              borderTop: "1px dashed #e5e5e5",
              background: "none",
            }}
          />

          <Patient
            user={appointment.patient}
            reason={appointment.reason}
            type={type}
          />
          <Divider
            variant="middle"
            style={{
              borderTop: "1px dashed #e5e5e5",
              background: "none",
            }}
          />

          <ListItem
            alignItems="flex-start"
            // style={{ borderTop: "1px dashed #e5e5e5" }}
          >
            <ListItemText
              primary={<small>{appointment.payment_method}</small>}
              // secondary={<React.Fragment>{user.name}</React.Fragment>}
            />

            <ListItemSecondaryAction>
              <ListItemText
                primary={
                  <small>
                    <b>{`${appointment.currency} ${appointment.amount.toFixed(
                      2
                    )}`}</b>
                  </small>
                }
              />
            </ListItemSecondaryAction>
          </ListItem>

          <Divider
            variant="middle"
            style={{
              borderTop: "1px dashed #e5e5e5",
              background: "none",
            }}
          />

          <ListItem>
            <ListItemText
              primary={<small>Phone no.</small>}
              secondary={appointment.patient.contact}
            />

            <IconButton
              edge="end"
              // onClick={handleClickOpen}
            >
              <CallIcon />
            </IconButton>
          </ListItem>

          <Divider
            variant="middle"
            style={{
              borderTop: "1px dashed #e5e5e5",
              background: "none",
            }}
          />

          <ListItem alignItems="flex-start">
            <ListItemText
              primary={
                <AcceptAppointmentRequestComponent
                  guardian_id={appointment.medical_guardian.id}
                  appointment_id={appointment.id}
                  openBackdrop={openBackdrop}
                  closeBackdrop={closeBackdrop}
                  showSnackbar={showSnackbar}
                />
              }
            />
          </ListItem>
        </List>
      </div>
    );
  });

  return renderUsers;
}
