import fetch from "isomorphic-fetch";
import config from "../../../../core/config/config";
import store from "../../../../core/redux/store";

export const editContact = async (contact: string): Promise<any> => {
  try {
    const state = store.getState();

    const uri = `${config.uri.auth_url}/me/edit/contact`;

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token.token}`,
      },
      body: JSON.stringify({ contact }),
    };

    const response = await fetch(uri, options);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || "Something went wrong");
    }

    return result;
  } catch (err) {
    throw err;
  }
};
