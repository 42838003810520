// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';

function Media(props) {
  return (
    <div>
      <Box width={'100%'} mt={2} mb={3}>
        <Skeleton variant="rect" width={'100%'} />
      </Box>

      <Box width={'100%'} my={2}>
        <Skeleton variant="rect" width={'100%'} height={'2.5rem'} />
      </Box>

      <Grid container spacing={1}>
        {Array.from(new Array(3)).map((item, index) => (
          <Grid key={index} item xs={4} md={4}>
            <Box key={index} width={'100%'} marginRight={0.5} my={1}>
              <Skeleton variant="rect" width={'100%'} height={'3.5rem'} />
            </Box>
          </Grid>
        ))}
      </Grid>

      <Box width={'100%'} my={2}>
        <Skeleton variant="rect" width={'100%'} />
      </Box>

      <Grid container spacing={1}>
        {Array.from(new Array(12)).map((item, index) => (
          <Grid key={index} item xs={4} md={3}>
            <Box key={index} width={'100%'} marginRight={0.5} my={1}>
              <Skeleton variant="rect" width={'100%'} height={'3.5rem'} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

Media.propTypes = {
  loading: PropTypes.bool
};

export function CalendarLoadingComponent() {
  return (
    <Box overflow="hidden">
      <Media loading />
    </Box>
  );
}
