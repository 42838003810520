import { User } from "../../domain/entities/user-entity";
import { IUserRepository } from "../../domain/repositories/iuser-repository";
import { findUsers } from "../datasources/find-users";
import { getUser } from "../datasources/get-user";
import { getUsers } from "../datasources/get-users";

export class UserRepository implements IUserRepository {
  async FindUsers(keyword: string): Promise<User[]> {
    const result = await findUsers(keyword);

    return result.map((user: any) => new User(user));
  }

  async GetUsers(location_id: string): Promise<User[]> {
    const result = await getUsers(location_id);

    return result.map((user: any) => new User(user));
  }

  async GetUser(organization_user_id: string): Promise<User> {
    const result = await getUser(organization_user_id);

    return new User(result);
  }
}
