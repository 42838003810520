// @ts-check
import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

import Avatar from "@material-ui/core/Avatar/Avatar";
import Typography from "@material-ui/core/Typography/Typography";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  userAvatarContainer: {
    justifyContent: "center",
    display: "flex",
    paddingTop: theme.spacing(2),
  },
  userAvatar: {
    width: "4rem",
    height: "4rem",
  },
  heading: {
    margin: theme.spacing(2),
  },
}));

export function ImageNameComponent({ me }) {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper} elevation={0}>
        <div className={classes.userAvatarContainer}>
          <Avatar
            className={classes.userAvatar}
            src={me.image || "/"}
            alt={me.name}
          />
        </div>
        <Typography
          // variant="p"
          component="p"
          align="center"
          className={classes.heading}
        >
          Hi, {me.name}
        </Typography>
      </Paper>
    </>
  );
}
