// @ts-check
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import throttle from "lodash/throttle";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

import { getOrganizationsAction } from "../redux/actions/auth-actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";

const nappi = [];

const useStyles = makeStyles((theme) => ({
  avatar: {
    "& > *": {
      objectFit: "contain",
    },
  },
}));

// @ts-ignore
export const OrganizationLookupComponent = ({
  formikOrganizationProps,
  callbackOrganization,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(null);

  const { organizations, loading } = useSelector((state) => state.orgs);

  useEffect(() => {
    setOptions(organizations);
  }, [organizations]);

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        console.log(request);

        // autocomplete.getPlacePredictions(request, callback);
        dispatch(getOrganizationsAction(request.input));

        return nappi;
      }, 200),
    [dispatch]
  );

  useEffect(() => {
    // let active = true;

    if (inputValue === "") {
      // @ts-ignore
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {});
  }, [value, inputValue, fetch]);

  return (
    <div className="uk-margin">
      <>
        <Autocomplete
          // @ts-ignore
          getOptionLabel={(option) =>
            // @ts-ignore
            typeof option === "string" ? option : option.name
          }
          filterOptions={(x) => x}
          loading={loading}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={value}
          onChange={(event, newValue) => {
            // @ts-ignore
            setOptions(newValue ? [newValue, ...options] : options);
            // @ts-ignore
            setValue(newValue);
            console.log("newValue", newValue);

            callbackOrganization(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Organization"
              variant="outlined"
              InputProps={{
                ...params.InputProps,

                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              {...formikOrganizationProps("organization")}
            />
          )}
          renderOption={(option) => {
            // @ts-ignore
            const matches = match(option.name, inputValue);
            // @ts-ignore
            const parts = parse(
              // @ts-ignore
              option.name,
              matches
            );

            return (
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Avatar
                    variant="square"
                    // @ts-ignore
                    alt={option.name}
                    // @ts-ignore
                    src={option.logo || "/static/images/avatar/1.jpg"}
                    className={classes.avatar}
                  />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                    >
                      {part.text}
                    </span>
                  ))}

                  <Typography variant="body2" color="textSecondary">
                    {
                      // @ts-ignore
                      option.organization
                    }
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
        />
      </>
    </div>
  );
};
