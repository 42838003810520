// @ts-check
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppointmentRequestsAction } from "../redux/actions/appointment-actions";
import CssBaseline from "@material-ui/core/CssBaseline";

import { LoadingComponent } from "../components/loading-component";
import { NoResultsFound } from "../../../../core/constants";
import { BackdropComponent } from "../../../../core/utils/backdrop-component";
import { SnackbarComponent } from "../../../../core/utils/snackbar-component";
import { AppointmentRequestsListComponent } from "../components/appointment-requests-list";

export default function AppointmentRequestsPage({
  level = "organization",
  guardian_id = null,
}) {
  const dispatch = useDispatch();
  const { location } = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(getAppointmentRequestsAction(guardian_id, level, location.id));
  }, [dispatch, guardian_id, level, location]);

  const { requests, loading, error } = useSelector((state) => state.requests);

  // actions helpers
  const [backdrop, setBackdrop] = useState(false);

  const openBackdrop = () => {
    setBackdrop(true);
  };

  const closeBackdrop = () => {
    setBackdrop(false);
  };

  const [snackbar, setSnackbar] = useState(false);
  const [uri, setUri] = useState(undefined);
  const [message, setMessage] = useState({
    status: "success",
    description: "N/A",
  });

  const showSnackbar = (status, description, _uri = undefined) => {
    setUri(_uri);
    setSnackbar(true);
    setMessage({
      status,
      description,
    });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(false);
  };

  if (error) return <div>Error! {error.message}</div>;

  return (
    <>
      <React.Fragment>
        <CssBaseline />

        {loading && <LoadingComponent />}
        {!loading && (
          <>
            {requests.length === 0 && (
              <>
                <NoResultsFound message={"No new appointment requests found"} />
              </>
            )}
            <AppointmentRequestsListComponent
              appointments={requests}
              openBackdrop={openBackdrop}
              closeBackdrop={closeBackdrop}
              showSnackbar={showSnackbar}
            />

            <BackdropComponent open={backdrop} />
            <SnackbarComponent
              uri={uri}
              open={snackbar}
              onClose={closeSnackbar}
              severity={message.status}
              message={message.description}
            />
          </>
        )}
      </React.Fragment>
    </>
  );
}
