// @ts-check
import React from "react";

export function BookBackgroundComponent() {
  return (
    <div className="uk-section-default">
      <div
        className="uk-section uk-light- uk-background-cover"
        style={{
          // background: `linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.2)) 0% 0% / contain, url(${images.health_future}),  center center no-repeat `,
          background: `#012535`,
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
        }}
        data-uk-img
      >
        <div className="uk-container uk-text-center">
          <div className="uk-margin uk-light">
            <h3
              className="uk-margin-remove uk-visible@m"
              style={{ fontWeight: 900, fontSize: "2rem" }}
            >
              Book Patient
              {/* Book <span>&bull;</span> Consult <span>&bull;</span> Monitor
              <br />
              <small>Book your appointment.</small> */}
            </h3>

            <h3
              className="uk-margin-remove uk-hidden@m"
              style={{ fontWeight: 500, fontSize: "1.4rem" }}
            >
              Book Patient
              {/* Book <span>&bull;</span> Pay <span>&bull;</span> Consult <span>&bull;</span> Monitor
              <br />
              <small>Book your appointment.</small> */}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
