import { REMOVE_USER_TOKEN, SET_USER_TOKEN } from "../types/token-types";

const initialState = {
  token: undefined,
};

function token(state = initialState, action: any = null) {
  switch (action.type) {
    case SET_USER_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

    case REMOVE_USER_TOKEN:
      return {
        ...state,
        token: undefined,
      };

    default:
      return state;
  }
}

export default token;
