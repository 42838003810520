import { Dispatch } from "redux";

import { UserRepository } from "../../../data/repositories/user-repository";
import { UserUsecase } from "../../../domain/usecases/user-usecase";

import {
  FIND_USERS_LOAD_FAILURE,
  FIND_USERS_LOAD_REQUEST,
  FIND_USERS_LOAD_SUCCESS,
} from "../types/find-users-types";
import {
  USERS_LOAD_FAILURE,
  USERS_LOAD_REQUEST,
  USERS_LOAD_SUCCESS,
} from "../types/users-types";
import {
  USER_LOAD_FAILURE,
  USER_LOAD_REQUEST,
  USER_LOAD_SUCCESS,
} from "../types/user-types";

export const getUsersAction = (location_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: USERS_LOAD_REQUEST });

    try {
      const userRepository = new UserRepository();
      const userUsecase = new UserUsecase(userRepository);

      const result = await userUsecase.GetUsers(location_id);

      dispatch({ type: USERS_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: USERS_LOAD_FAILURE, error });
    }
  };
};

export const getUserAction = (user_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: USER_LOAD_REQUEST });

    try {
      const userRepository = new UserRepository();
      const userUsecase = new UserUsecase(userRepository);

      const result = await userUsecase.GetUser(user_id);

      dispatch({ type: USER_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: USER_LOAD_FAILURE, error });
    }
  };
};

export const searchUsersAction = (keyword: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: FIND_USERS_LOAD_REQUEST });

    try {
      const userRepository = new UserRepository();
      const userUsecase = new UserUsecase(userRepository);

      const result = await userUsecase.FindUsers(keyword);

      dispatch({ type: FIND_USERS_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: FIND_USERS_LOAD_FAILURE, error });
    }
  };
};
