import { Auth } from "../../domain/entities/auth";
import { AuthRepository } from "../../domain/repositories/auth-repository";
import { login } from "../datasources/login";
import { getOrganizations } from "../datasources/get-organizations";
import { AuthOrganization } from "../../domain/entities/auth-organization";

export class AuthRepositoryImpl implements AuthRepository {
  async Login(
    organization_id: string,
    organization: string,
    email: string,
    password: string
  ): Promise<Auth> {
    const result = await login(organization_id, organization, email, password);

    return new Auth(result.token);
  }

  async GetOrganizations(): Promise<AuthOrganization[]> {
    const result = await getOrganizations();

    return result.map((org) => new AuthOrganization(org));
  }
}
