// @ts-check
import React from "react";

import { Formik } from "formik";
import * as Yup from "yup";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import {
  Divider,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { LoadingButton } from "../../../../core/constants";
import { createAppointmentAction } from "../../../appointments/presentation/redux/actions/appointment-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  sectionHeading: {
    margin: theme.spacing(2),
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formButtons: {
    marginTop: theme.spacing(0),
  },
  errorText: {
    color: "#f44336",
    textAlign: "center",
    display: "block",
    margin: theme.spacing(3),
  },
}));

function BookAppointmentForm(props) {
  const classes = useStyles();

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    services_offered,
    preappointment,
    setFieldValue,
  } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = "") => ({
    name: name,
    value: typeof values[name] !== "undefined" ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : "",
  });

  /**
   * @param {string} name
   */
  const contactProps = (name, initialValue = "") => ({
    name: name,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    autoFocus: false,
    helperText: touched[name] ? errors[name] : "",
  });

  const reasons = services_offered.map((reason) => ({
    label: reason,
    value: reason,
  }));

  const { loading, error } = useSelector((state) => state.appointment);

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <div
        className="uk-margin uk-grid-small uk-child-width-expand"
        data-uk-grid
      >
        <div>
          <TextField
            {...formikProps("firstname")}
            id="outlined-full-width"
            label="First Name"
            type="text"
            variant="outlined"
            fullWidth={true}
          />
        </div>

        <div>
          <TextField
            {...formikProps("lastname")}
            id="outlined-full-width"
            label="Last Name"
            type="text"
            variant="outlined"
            fullWidth={true}
          />
        </div>
      </div>

      <TextField
        {...formikProps("email")}
        id="outlined-full-width"
        label="Email"
        type="email"
        variant="outlined"
        fullWidth={true}
      />

      <div className="uk-margin-small uk-margin-bottom ">
        <PhoneInput
          specialLabel="Mobile No."
          inputProps={contactProps("contact")}
          country={"za"}
          regions={["africa", "america", "europe"]}
          autoFormat={false}
          countryCodeEditable={false}
          value={values["contact"]}
          // onChange={handleChange}
          onChange={(value, country, e, formattedValue) => {
            console.log(value, country, e, formattedValue);
            setFieldValue("contact", formattedValue);
          }}
          // inputClass="MuiInputBase-input MuiOutlinedInput-input"
          inputStyle={{
            width: "100%",
            background: "inherit",
            // borderColor: touched['contact'] && Boolean(errors['contact']) ? '#f44336' : ''
          }}
        />

        <FormHelperText error={touched["contact"]}>
          <div className="uk-text-center- uk-margin-left">
            {touched["contact"]
              ? errors["contact"]
              : `Provide a phone no. that can receive an SMS.`}
          </div>
        </FormHelperText>
      </div>

      <Divider />

      <div className="uk-margin">
        <span>Gender</span>
      </div>

      <RadioGroup aria-label="gender" {...formikProps("gender")}>
        <div
          className="uk-margin- uk-grid-small  uk-child-width-expand"
          data-uk-grid
        >
          <div>
            <FormControlLabel
              value="male"
              control={<Radio />}
              label={
                <small>
                  <b>Male</b>
                </small>
              }
            />
          </div>

          <div>
            <FormControlLabel
              value="female"
              control={<Radio />}
              label={
                <small>
                  <b>Female</b>
                </small>
              }
            />
          </div>

          <div>
            <FormControlLabel
              value="unspecified"
              control={<Radio />}
              label={
                <small>
                  <b>Other</b>
                </small>
              }
            />
          </div>
        </div>

        <FormHelperText error={true}>
          <div className="uk-margin-small-left">
            {touched["gender"] ? errors["gender"] : ""}
          </div>
        </FormHelperText>
      </RadioGroup>

      <Divider />

      <div className="uk-margin">
        <span>Payment Type</span>
      </div>

      <RadioGroup aria-label="payment" {...formikProps("payment")}>
        <div
          className="uk-margin- uk-grid-small uk-child-width-expand"
          data-uk-grid
        >
          <div>
            <FormControlLabel value="cash" control={<Radio />} label=" Cash" />
          </div>

          {preappointment.type === "inperson" && (
            <div>
              <FormControlLabel
                value="medical aid"
                control={<Radio />}
                label=" Medical aid"
              />
            </div>
          )}
        </div>

        <FormHelperText error={true}>
          <div className="uk-text-center-">
            {touched["payment"] ? errors["payment"] : ""}
          </div>
        </FormHelperText>
      </RadioGroup>
      <Divider />

      <TextField
        {...formikProps("reason")}
        id="outlined-select-currency"
        select
        label="Reason for appointment"
        variant="outlined"
        fullWidth={true}
      >
        {reasons.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        {...formikProps("booking_notes")}
        id="outlined-full-width"
        label="Note"
        type="text"
        variant="outlined"
        multiline
        rows={4}
        fullWidth={true}
      />

      <div className={classes.formButtons}>
        {!loading && (
          <>
            <div className="uk-margin  uk-margin-top">
              <button
                className="uk-button uk-button-primary uk-width-1-1"
                type="submit"
              >
                Book Appointment
              </button>
            </div>
          </>
        )}

        {loading && (
          <div className="uk-margin  uk-margin-top">
            <LoadingButton />
          </div>
        )}

        {error && (
          <div>
            <span className={classes.errorText}>{error.message}</span>
          </div>
        )}
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  firstname: Yup.string().required("First name is required"),
  lastname: Yup.string().required("Last name is required"),
  email: Yup.string().trim().email("Enter a valid email").optional(),
  contact: Yup.string()
    .test("Phone Test", "Enter a valid mobile phone no.", (value) => {
      try {
        return validator.isMobilePhone(value, undefined, { strictMode: true });
      } catch (err) {
        return true;
      }
    })
    .required("Mobile phone number is required"),
  gender: Yup.string().required("Gender is required"),
  reason: Yup.string().required("Appointment reason is required"),
  payment: Yup.string().required("Payment type is required"),
  booking_notes: Yup.string().optional(),
});

export function BookAppointmentFormComponent({
  medical_guardian,
  preappointment,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { location } = useSelector((state) => state.me);

  const values = {
    firstname: "",
    lastname: "",
    gender: "",
    email: "",
    contact: "",
    payment: "",
    reason: "",
    booking_notes: "",
  };

  const submit = async (data, actions) => {
    const {
      payment,
      reason,
      firstname,
      lastname,
      gender,
      email,
      contact,
      booking_notes,
    } = data;

    actions.setSubmitting(true);

    const patient = {
      firstname,
      lastname,
      gender,
      email,
      contact,
    };

    const props = { history };

    dispatch(
      createAppointmentAction(
        medical_guardian.id,
        preappointment.type,
        payment,
        reason,
        preappointment.datetime,
        patient,
        booking_notes,
        preappointment.duration,
        location.id,
        props
      )
    );
  };

  const services_offered = [
    ...medical_guardian.application.services_offered,
    "Other",
  ];

  return (
    <div className="uk-margin">
      <Formik
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {(props) => (
          <BookAppointmentForm
            {...props}
            services_offered={services_offered}
            medical_guardian={medical_guardian}
            preappointment={preappointment}
          />
        )}
      </Formik>
    </div>
  );
}
