// @ts-check
import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import { CardActionArea, ListItemSecondaryAction } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import GuardianCalendarModal from "../../../calendar/presentation/components/calendar-modal";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import GuardianAnalyticsModal from "../../../calendar/presentation/components/analytics-modal";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  indicator: {},
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  card: {
    marginBottom: theme.spacing(0),
  },
}));

export function GuardianHeader({ guardian }) {
  const classes = useStyles();

  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar
          alt={guardian.name}
          src={guardian.image || "/static/images/avatar/1.jpg"}
        />
      </ListItemAvatar>
      <ListItemText
        primary={<small>{guardian.name}</small>}
        secondary={
          <React.Fragment>{guardian.application.profession}</React.Fragment>
        }
      />

      <ListItemSecondaryAction>
        <ListItemText
          className={classes.right}
          primary={<>{guardian.application.board.name}</>}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export function GuardianListItemComponent({ guardian }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className="uk-margin">
      <CardActionArea
        className={classes.card}
        onClick={() => history.push(`/guardians/${guardian.id}`)}
      >
        <List className={classes.root}>
          <GuardianHeader guardian={guardian} />

          <ListItem
            alignItems="flex-start"
            style={{
              borderTop: "1px dashed #e5e5e5",
              // borderBottom: "1px dashed #e5e5e5",
            }}
          >
            <ListItemText
              primary={<small>{guardian.organization.location.name}</small>}
              secondary={null}
            />

            <ListItemSecondaryAction>
              <IconButton edge="end">
                <LocationOnIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </CardActionArea>

      <List>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={<small>Quick Actions</small>}
            secondary={null}
          />

          <ListItemSecondaryAction>
            <GuardianAnalyticsModal guardian={guardian} />
            <GuardianCalendarModal guardian={guardian} />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </div>
  );
}
