// @ts-check
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import { ListItemSecondaryAction } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  indicator: {},
  right: {
    textAlign: "right",
  },
}));

export function UsersList({ users }) {
  const classes = useStyles();
  const history = useHistory();

  const n = users.length - 1;
  const renderUsers = users.map((user, index) => {
    let role = "Member";
    if (user.owner) {
      role = "Owner";
    } else if (user.administrator) {
      role = "Admin";
    }

    return (
      <div key={index}>
        <ListItem
          alignItems="flex-start"
          button
          onClick={() => history.push(`/users/${user.id}`)}
        >
          <ListItemAvatar>
            <Avatar
              alt={user.name}
              src={user.image || "/static/images/avatar/1.jpg"}
            />
          </ListItemAvatar>
          <ListItemText
            primary={user.name}
            secondary={<React.Fragment>{user.email}</React.Fragment>}
          />

          <ListItemSecondaryAction>
            <span
              className="uk-label uk-border-rounded "
              style={{
                background: user.administrator ? "#25a9e0" : "#fd8361",
                marginRight: "4px",
              }}
            >
              <b>{role}</b>
            </span>
          </ListItemSecondaryAction>
        </ListItem>
        {index !== n && <Divider variant="inset" component="li" />}
      </div>
    );
  });

  return <List className={classes.root}>{renderUsers}</List>;
}
