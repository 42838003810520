export class Organization {
  id: string;
  name: string;
  organization: string;
  logo: string;
  invites: any[];

  constructor(args: any, users: any[] = []) {
    this.id = args.id;
    this.name = args.name;
    this.organization = args.organization;
    this.logo = args.logo;
    this.invites = args.invites || [];
  }
}
