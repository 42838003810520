import { CalendarAppointments } from "../entities/calendar-appointments-entity";
import { CalendarSchedule } from "../entities/calendar-entity";
import { GuardianCalendar } from "../entities/guardian-calendar-entity";
import { CalendarRepository } from "../repositories/calendar-repository";

interface CalendarUsecase extends CalendarRepository {}

export class CalendarUsecaseImpl implements CalendarUsecase {
  calendarRepository: CalendarRepository;

  constructor(ar: CalendarRepository) {
    this.calendarRepository = ar;
  }

  GetCalendarSchedule(guardian_id: string): Promise<CalendarSchedule> {
    return this.calendarRepository.GetCalendarSchedule(guardian_id);
  }

  GetCalendarAppointments(
    guardian_id: string
  ): Promise<CalendarAppointments[]> {
    return this.calendarRepository.GetCalendarAppointments(guardian_id);
  }

  UpdateGuardianCalendarSchedule(
    guardian_id: string,
    timezone: string,
    duration: number,
    daily_schedule: any[],
    telehealth_schedule: any[],
    after_hours_schedule: any[]
  ): Promise<CalendarSchedule> {
    return this.calendarRepository.UpdateGuardianCalendarSchedule(
      guardian_id,
      timezone,
      duration,
      daily_schedule,
      telehealth_schedule,
      after_hours_schedule
    );
  }

  GetGuardianCalendar(
    guardian_id: string,
    appointment_type: string
  ): Promise<GuardianCalendar> {
    return this.calendarRepository.GetGuardianCalendar(
      guardian_id,
      appointment_type
    );
  }
}
