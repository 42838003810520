// @ts-check
import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CallIcon from "@material-ui/icons/Call";

import { PatientPriceComponent } from "./item/patient-price-component";
import {
  CancelReschedule,
  OfflinePayment,
} from "./item/appointment-actions-component";
import { AppointmentHeaderGuardianComponent } from "./item/appointment-header-guardian-component";
import ListItemIcon from "@material-ui/core/ListItemIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export function AppointmentComponent({ appointment }) {
  const classes = useStyles();

  return (
    <div>
      <AppointmentHeaderGuardianComponent appointment={appointment} />

      {appointment.status === "accepted" && (
        <CancelReschedule appointment={appointment} />
      )}

      {["accepted", "completed", "inprogress"].includes(appointment.status) &&
        appointment.payment_status === "unpaid" && (
          <>
            <OfflinePayment appointment={appointment} />
          </>
        )}

      <PatientPriceComponent appointment={appointment} />

      <List className={classes.root}>
        <ListItem>
          <ListItemIcon>
            <CallIcon />
          </ListItemIcon>
          <ListItemText
            primary={<small>Phone no.</small>}
            secondary={appointment.patient.contact}
          ></ListItemText>
        </ListItem>
      </List>
    </div>
  );
}
