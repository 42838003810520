import { Auth } from "../entities/auth";
import { AuthOrganization } from "../entities/auth-organization";
import { AuthRepository } from "../repositories/auth-repository";

interface AuthUsecase {
  Login(
    organization_id: string,
    organization: string,
    email: string,
    password: string
  ): Promise<Auth>;
  GetOrganizations(): Promise<AuthOrganization[]>;
}

export class AuthUsecaseImpl implements AuthUsecase {
  authRepository: AuthRepository;

  constructor(ar: AuthRepository) {
    this.authRepository = ar;
  }

  Login(
    organization_id: string,
    organization: string,
    email: string,
    password: string
  ): Promise<Auth> {
    return this.authRepository.Login(
      organization_id,
      organization,
      email,
      password
    );
  }

  GetOrganizations(): Promise<AuthOrganization[]> {
    return this.authRepository.GetOrganizations();
  }
}
