// @ts-check
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { SelectAppointmentTypeComponent } from "../components/select-appointment-type-component";
import { NoDaySlotsComponent } from "../components/no-slots-component";
import { SlotsComponent } from "../components/slots-component";

import { CalendarControlComponent } from "../components/calendar-control-component";

export function SlotsPage({
  modal = false,
  id,
  calendar_availability,
  type_of_appointments_offered,
  pricing,
}) {
  const history = useHistory();

  const list = [];
  type_of_appointments_offered.includes("inperson") && list.push("inperson");
  type_of_appointments_offered.includes("video") && list.push("video");
  type_of_appointments_offered.includes("afterhours") &&
    list.push("afterhours");
  const [type, setType] = useState(list[0]);

  const [state, setState] = useState({
    selected: 0,
    date: new Date(),
    duration: calendar_availability[0].duration,
    normal_slots: calendar_availability[0].normal_slots,
    telehealth_slots: calendar_availability[0].telehealth_slots,
    afterhours_slots: calendar_availability[0].afterhours_slots,
  });

  const choosenDate = (
    date,
    key,
    normal_slots,
    telehealth_slots,
    afterhours_slots
  ) => {
    setState({
      selected: key,
      date: date,
      duration: calendar_availability[0].duration,
      normal_slots: normal_slots,
      telehealth_slots: telehealth_slots,
      afterhours_slots: afterhours_slots,
    });
  };

  const bookAppointment = (date, afterhours = false) => {
    if (type === "") {
      return;
    }

    let _type = type;

    if (afterhours) {
      _type = "afterhours";
    }

    const localTime = date.getTime();

    const query = {
      id: id,
      date: localTime,
      type: _type,
      duration: state.duration,
    };
    // const searchString = qs.stringify(query);
    // @ts-ignore
    const searchParams = new URLSearchParams(query);

    history.push({
      pathname: `/guardians/${id}/book/appointment`,
      search: searchParams.toString(),
    });
  };

  const weekday = state.date.toLocaleDateString("default", {
    weekday: "short",
  });
  const day = state.date.getDate();
  const month = state.date.toLocaleString("default", { month: "short" });
  const slotsDay = `${weekday} ${day} ${month}`;

  const totalCumulativeSlots =
    state.normal_slots.length +
    state.telehealth_slots.length +
    state.afterhours_slots.length;

  return (
    <div>
      <CalendarControlComponent
        // modal={modal}
        selected={state.selected}
        choosenDate={choosenDate}
        calendar_availability={calendar_availability}
      />

      {totalCumulativeSlots === 0 && (
        <NoDaySlotsComponent slotsDay={slotsDay} />
      )}

      {totalCumulativeSlots > 0 && (
        <>
          <SelectAppointmentTypeComponent
            setType={setType}
            type_of_appointments_offered={type_of_appointments_offered}
          />

          {type && (
            <>
              {type === "inperson" && (
                <>
                  <SlotsComponent
                    date={state.date}
                    type={type}
                    slots={state.normal_slots}
                    bookAppointment={bookAppointment}
                    afterhours={false}
                    pricing={pricing}
                    duration={state.duration}
                  />
                </>
              )}

              {type === "video" && (
                <>
                  <SlotsComponent
                    date={state.date}
                    type={type}
                    slots={state.telehealth_slots}
                    bookAppointment={bookAppointment}
                    afterhours={false}
                    pricing={pricing}
                    duration={state.duration}
                  />
                </>
              )}

              {type === "afterhours" && (
                <SlotsComponent
                  date={state.date}
                  type={type}
                  slots={state.afterhours_slots}
                  bookAppointment={bookAppointment}
                  afterhours={true}
                  pricing={pricing}
                  duration={state.duration}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
