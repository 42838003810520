import React from "react";

function AvailableDate({ date, count, setDate, selected = false, item }) {
  return (
    <li className={selected ? "uk-active" : ""}>
      {/* eslint-disable-next-line */}
      <a onClick={() => setDate(date, item)}>
        <h6 className="uk-margin-remove">
          {date.toLocaleDateString("default", { weekday: "short" })}{" "}
          {date.getDate()} {date.toLocaleString("default", { month: "short" })}
        </h6>
        <small>{count} slots</small>
      </a>
    </li>
  );
}

function Grid({ setDate, selected, slots }) {
  const days = slots.map((slot, key) => {
    return (
      <AvailableDate
        key={key}
        date={slot.date}
        count={slot.count}
        setDate={setDate}
        selected={key === selected}
        item={key}
      />
    );
  });

  return (
    <ul className="uk-child-width-expand dates-tab " data-uk-tab>
      {days}
    </ul>
  );
}

export default function App({
  selected,
  choosenDate,
  slots,
  step,
  calendar_availability,
}) {
  const setDate = (date, key) => {
    const normal_slots = calendar_availability[step + key].normal_slots;
    const telehealth_slots = calendar_availability[step + key].telehealth_slots;
    const afterhours_slots = calendar_availability[step + key].afterhours_slots;
    choosenDate(date, key, normal_slots, telehealth_slots, afterhours_slots);
  };

  return (
    <div className="uk-margin-small">
      <Grid setDate={setDate} selected={selected} slots={slots} />
    </div>
  );
}
