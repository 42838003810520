// @ts-check
import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";

import PastAppointmentsComponent from "./past-appointments-page";
import UpcomingAppointmentsComponent from "./upcoming-appointments-page";

import AppointmentRequestsPage from "./appointment-requests-page";
import UpaidAppointmentsPage from "./unpaid-appointments-page";

export function GuardianAppointmentsPage({ id }) {
  const [view, setView] = useState("upcoming");

  return (
    <React.Fragment>
      <CssBaseline />

      <div className="uk-margin-">
        <>
          <ul
            className="uk-child-width-expand uk-margin-remove-top"
            data-uk-tab
          >
            <li className={"uk-active"}>
              {/* eslint-disable-next-line */}
              <a
                onClick={() => setView("upcoming")}
                style={{
                  border: view === "upcoming" ? "1px solid #f96268" : "",
                  background: view === "upcoming" ? "#f96268" : "",
                  color: view === "upcoming" ? "#ffffff" : "",
                  padding: view === "upcoming" ? 0 : "",
                  borderRadius: view === "upcoming" ? "0.2em" : "",
                  marginBottom: view === "upcoming" ? "10px" : "",
                }}
              >
                <small className="uk-display-block uk-padding-small">
                  <b>Upcoming</b>
                </small>
              </a>
            </li>

            <li>
              {/* eslint-disable-next-line */}
              <a
                onClick={() => setView("requests")}
                style={{
                  border: view === "requests" ? "1px solid #f96268" : "",
                  background: view === "requests" ? "#f96268" : "",
                  color: view === "requests" ? "#ffffff" : "",
                  padding: view === "requests" ? 0 : "",
                  borderRadius: view === "requests" ? "0.2em" : "",
                  marginBottom: view === "requests" ? "10px" : "",
                }}
              >
                <small className="uk-display-block uk-padding-small">
                  <b>Requests</b>
                </small>
              </a>
            </li>

            <li>
              {/* eslint-disable-next-line */}
              <a
                onClick={() => setView("unpaid")}
                style={{
                  border: view === "unpaid" ? "1px solid #f96268" : "",
                  background: view === "unpaid" ? "#f96268" : "",
                  color: view === "unpaid" ? "#ffffff" : "",
                  padding: view === "unpaid" ? 0 : "",
                  borderRadius: view === "unpaid" ? "0.2em" : "",
                  marginBottom: view === "unpaid" ? "10px" : "",
                }}
              >
                <small className="uk-display-block uk-padding-small">
                  <b>Unpaid</b>
                </small>
              </a>
            </li>

            <li>
              {/* eslint-disable-next-line */}
              <a
                onClick={() => setView("past")}
                style={{
                  border: view === "past" ? "1px solid #f96268" : "",
                  background: view === "past" ? "#f96268" : "",
                  color: view === "past" ? "#ffffff" : "",
                  padding: view === "past" ? 0 : "",
                  borderRadius: view === "past" ? "0.2em" : "",
                  marginBottom: view === "past" ? "10px" : "",
                }}
              >
                <small className="uk-display-block uk-padding-small">
                  <b>Past</b>
                </small>
              </a>
            </li>
          </ul>
        </>
      </div>

      {view === "upcoming" && (
        <UpcomingAppointmentsComponent level={"guardian"} guardian_id={id} />
      )}

      {view === "requests" && (
        <AppointmentRequestsPage
          level={"organization_guardian"}
          guardian_id={id}
        />
      )}

      {view === "unpaid" && (
        <UpaidAppointmentsPage level={"guardian"} guardian_id={id} />
      )}

      {view === "past" && (
        <PastAppointmentsComponent level={"guardian"} guardian_id={id} />
      )}
    </React.Fragment>
  );
}
