import { Appointment } from "../entities/appointment-entity";
import { AppointmentRepository } from "../repositories/appointment-repository";

interface AppointmentUsecase {
  GetAppointments(
    level: string,
    past: boolean,
    unpaid: boolean,
    location_id: string,
    guardian_id?: string
  ): Promise<Appointment[]>;
  CreateAppointmentExternal(
    guardian_id: string,
    type: string,
    payment_method: string,
    reason: string,
    datetime: Date,
    patient: any,
    booking_notes: string,
    duration: number,
    location_id: string
  ): Promise<Appointment>;
  EditAppointmentExternalPatient(
    guardian_id: string,
    appointment_id: string,
    patient: any,
    location_id: string
  ): Promise<Appointment>;
  GetAppointment(
    guardian_id: string,
    appointment_id: string,
    location_id: string
  ): Promise<Appointment>;
  GetAppointmentRequests(
    guardian_id: string,
    level: string,
    location_id: string
  ): Promise<Appointment[]>;
  AcceptAppointmentRequest(
    guardian_id: string,
    appointment_id: string,
    location_id: string
  ): Promise<Appointment>;
  RejectAppointmentRequest(
    guardian_id: string,
    appointment_id: string,
    location_id: string
  ): Promise<Appointment>;
  CancelAppointment(
    guardian_id: string,
    appointment_id: string,
    location_id: string
  ): Promise<Appointment>;
  RescheduleAppointment(
    guardian_id: string,
    appointment_id: string,
    datetime: Date,
    location_id: string
  ): Promise<Appointment>;
  OfflinePayment(
    guardian_id: string,
    appointment_id: string,
    location_id: string
  ): Promise<Appointment>;
}

export class AppointmentUsecaseImpl implements AppointmentUsecase {
  appointmentRepository: AppointmentRepository;

  constructor(ar: AppointmentRepository) {
    this.appointmentRepository = ar;
  }

  CreateAppointmentExternal(
    guardian_id: string,
    type: string,
    payment_method: string,
    reason: string,
    datetime: Date,
    patient: any,
    booking_notes: string,
    duration: number,
    location_id: string
  ): Promise<Appointment> {
    return this.appointmentRepository.CreateAppointmentExternal(
      guardian_id,
      type,
      payment_method,
      reason,
      datetime,
      patient,
      booking_notes,
      duration,
      location_id
    );
  }

  EditAppointmentExternalPatient(
    guardian_id: string,
    appointment_id: string,
    patient: any,
    location_id: string
  ): Promise<Appointment> {
    return this.appointmentRepository.EditAppointmentExternalPatient(
      guardian_id,
      appointment_id,
      patient,
      location_id
    );
  }

  GetAppointment(
    guardian_id: string,
    appointment_id: string,
    location_id: string
  ): Promise<Appointment> {
    return this.appointmentRepository.GetAppointment(
      guardian_id,
      appointment_id,
      location_id
    );
  }

  GetAppointments(
    level: string,
    past: boolean,
    unpaid: boolean,
    location_id: string,
    guardian_id?: string
  ): Promise<Appointment[]> {
    return this.appointmentRepository.GetAppointments(
      level,
      past,
      unpaid,
      location_id,
      guardian_id
    );
  }

  GetAppointmentRequests(
    guardian_id: string,
    level: string,
    location_id: string
  ): Promise<Appointment[]> {
    return this.appointmentRepository.GetAppointmentRequests(
      guardian_id,
      level,
      location_id
    );
  }

  AcceptAppointmentRequest(
    guardian_id: string,
    appointment_id: string,
    location_id: string
  ): Promise<Appointment> {
    return this.appointmentRepository.AcceptAppointmentRequest(
      guardian_id,
      appointment_id,
      location_id
    );
  }

  RejectAppointmentRequest(
    guardian_id: string,
    appointment_id: string,
    location_id: string
  ): Promise<Appointment> {
    return this.appointmentRepository.RejectAppointmentRequest(
      guardian_id,
      appointment_id,
      location_id
    );
  }

  CancelAppointment(
    guardian_id: string,
    appointment_id: string,
    location_id: string
  ): Promise<Appointment> {
    return this.appointmentRepository.CancelAppointment(
      guardian_id,
      appointment_id,
      location_id
    );
  }

  RescheduleAppointment(
    guardian_id: string,
    appointment_id: string,
    datetime: Date,
    location_id: string
  ): Promise<Appointment> {
    return this.appointmentRepository.RescheduleAppointment(
      guardian_id,
      appointment_id,
      datetime,
      location_id
    );
  }

  OfflinePayment(
    guardian_id: string,
    appointment_id: string,
    location_id: string
  ): Promise<Appointment> {
    return this.appointmentRepository.OfflinePayment(
      guardian_id,
      appointment_id,
      location_id
    );
  }
}
