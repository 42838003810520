// @ts-check
import React from "react";

const styles = {
  professional: {
    height: "5.0em",
    width: "5.0em",
    objectFit: "cover",
  },
  phrase: {
    background: "#222",
  },
  filter: {
    background: "#222",
    cursor: "pointer",
  },
  duration: {
    // background: '#012535',
    border: "#000000",
    background: "#000000",
    paddingTop: "5px",
    paddingBottom: "5px",
    color: "#fff",
    fontFamily: "Gilroy-ExtraBold",
  },
  pricing: {
    // background: '#012535',
    border: "1px solid rgb(73, 168, 97)",
    background: "rgb(73, 168, 97)",
    paddingTop: "5px",
    paddingBottom: "5px",
    color: "#fff",
    fontFamily: "Gilroy-ExtraBold",
  },
};

function Price({ amount, currency, duration }) {
  return (
    <div>
      <span
        className="uk-card uk-card-default uk-padding-small uk-border-rounded uk-light uk-h6 uk-margin-small-right"
        style={styles.pricing}
      >
        {currency} {amount.toFixed(2)}
      </span>

      <span
        className="uk-card uk-card-default uk-padding-small uk-border-rounded uk-light uk-h6"
        style={styles.duration}
      >
        {duration} mins
      </span>
    </div>
  );
}

function Pricing({ article, amount, currency, duration }) {
  return (
    <div className="uk-margin-medium-top-">
      <div className="uk-card uk-card-default uk-card-body uk-padding-small">
        <div className="uk-grid-small uk-child-width-expand" data-uk-grid>
          <div className="uk-display-inline uk-width-3-5">
            <div>
              <small className="uk-margin-left-">
                <h6 className="uk-margin-remove uk-display-inline">
                  {article}
                </h6>
              </small>
            </div>
          </div>

          <div className="uk-text-right uk-width-2-5">
            <Price amount={amount} currency={currency} duration={duration} />
          </div>
        </div>
      </div>
    </div>
  );
}

export function ConsultationPriceComponent({
  type,
  medical_guardian,
  duration,
}) {
  const { currency, inperson_amount, video_amount, after_hours_amount } =
    medical_guardian.application.consultation_pricing;
  let amount = 0.0;
  switch (type) {
    case "inperson":
      amount = inperson_amount;
      break;

    case "video":
      amount = video_amount;
      break;

    case "afterhours":
      amount = after_hours_amount;
      break;

    default:
      amount = 0.0;
      break;
  }

  return (
    <div className="uk-margin">
      <Pricing
        article={"From"}
        amount={amount}
        currency={currency}
        duration={duration}
      />
    </div>
  );
}
