// @ts-check
import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

import { BackNavigationComponent } from "../../../../core/routes/components/back-navigation-component";
import { TextBackgroundComponent } from "../components/text-background-component";
import Grid from "@material-ui/core/Grid";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAppointmentAction } from "../redux/actions/appointment-actions";
import { BookLoadingComponent } from "../../../booking/presentation/components/book-loading-component";

import { BackdropComponent } from "../../../../core/utils/backdrop-component";
import { AppointmentHeaderGuardianComponent } from "../components/item/appointment-header-guardian-component";
import { PatientPriceComponent } from "../components/item/patient-price-component";
import { Divider } from "@material-ui/core";
import { NoResultsFound } from "../../../../core/constants";
import { SnackbarComponent } from "../../../../core/utils/snackbar-component";
import { CancelButtonComponent } from "../components/actions/cancel-button-component";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(2),
  },
}));

export default function AppointmentCancelPage() {
  // @ts-ignore
  const { guardian_id, appointment_id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { location } = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(getAppointmentAction(guardian_id, appointment_id, location.id));
  }, [dispatch, guardian_id, appointment_id, location]);

  const { appointment, loading, success, error } = useSelector(
    (state) => state.appointment
  );

  // helpers
  const [backdrop, setBackdrop] = useState(false);

  const openBackdrop = () => {
    setBackdrop(true);
  };

  const closeBackdrop = () => {
    setBackdrop(false);
  };

  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState({
    status: "success",
    description: "N/A",
  });

  const showSnackbar = (status, description) => {
    setSnackbar(true);
    setMessage({
      status,
      description,
    });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(false);
  };

  if (error) return <div>Error! {error.message}</div>;

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {(loading || !success) && <BookLoadingComponent />}

          {!loading && success && (
            <>
              <BackNavigationComponent
                page="Cancel"
                uri="/cancel"
                path={[
                  {
                    page: "Home",
                    uri: "/",
                  },
                  {
                    page: "Appointments",
                    uri: "/appointments",
                  },
                  {
                    page: "Appointment",
                    uri: `/guardians/${guardian_id}/appointments/${appointment_id}`,
                  },
                ]}
              />

              <Grid container spacing={2} justify="center">
                <Grid item xs={12} md={12}>
                  <TextBackgroundComponent text={"Cancel Appointment"} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <AppointmentHeaderGuardianComponent
                    appointment={appointment}
                  />

                  <PatientPriceComponent appointment={appointment} />
                  <Divider />

                  {["accepted"].includes(appointment.status) && (
                    <CancelButtonComponent
                      guardian_id={appointment.medical_guardian.id}
                      appointment_id={appointment.id}
                      openBackdrop={openBackdrop}
                      closeBackdrop={closeBackdrop}
                      showSnackbar={showSnackbar}
                    />
                  )}
                  {appointment.status !== "accepted" && (
                    <>
                      <NoResultsFound
                        message={"Can't cancel appointment in this state"}
                      />
                    </>
                  )}
                </Grid>
              </Grid>

              <BackdropComponent open={backdrop} />
              <SnackbarComponent
                open={snackbar}
                onClose={closeSnackbar}
                severity={message.status}
                message={message.description}
              />
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
