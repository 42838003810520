// @ts-check
import React from "react";

import { AppointmentItemComponent } from "./appointment-item-component";

export function AppointmentListComponent({ level, appointments }) {
  const renderUsers = appointments.map((appointment, index) => {
    return (
      <AppointmentItemComponent
        key={index}
        appointment={appointment}
        level={level}
      />
    );
  });

  return renderUsers;
}
