// @ts-check
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { ListItemSecondaryAction } from "@material-ui/core";
import { AppointmentTypeIcon } from "../../../../../core/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  indicator: {},
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  card: {
    marginBottom: theme.spacing(2),
  },
}));

function Patient({ user, reason, type }) {
  const classes = useStyles();

  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar
          alt={user.name}
          src={user.image || "/static/images/avatar/1.jpg"}
        />
      </ListItemAvatar>
      <ListItemText
        primary={<small>{user.name}</small>}
        secondary={<React.Fragment>{reason}</React.Fragment>}
      />

      <ListItemSecondaryAction>
        <ListItemText
          className={classes.right}
          primary={
            <>
              <small>{type.toUpperCase()}</small>{" "}
              <AppointmentTypeIcon type={type} />
              {type === "afterhours" && <AppointmentTypeIcon type={"video"} />}
            </>
          }
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
}

function PaymentTypeNAmount({ payment_method, currency, amount }) {
  return (
    <ListItem
      alignItems="flex-start"
      style={{ borderTop: "1px dashed #e5e5e5" }}
    >
      <ListItemText primary={<small>{payment_method}</small>} />

      <ListItemSecondaryAction>
        <ListItemText
          primary={
            <small>
              <b>{`${currency} ${amount.toFixed(2)}`}</b>
            </small>
          }
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export function PatientPriceComponent({ appointment }) {
  const classes = useStyles();

  return (
    <div>
      <List className={classes.root}>
        <Patient
          user={appointment.patient}
          reason={appointment.reason}
          type={appointment.type}
        />

        <PaymentTypeNAmount
          payment_method={appointment.payment_method}
          currency={appointment.currency}
          amount={appointment.amount}
        />
      </List>
    </div>
  );
}
