import { Dispatch } from "redux";

import { GuardianRepository } from "../../../data/repositories/guardian-repository";
import { GuardianUsecase } from "../../../domain/usecases/guardian-usecase";
import {
  GUARDIAN_LOAD_FAILURE,
  GUARDIAN_LOAD_REQUEST,
  GUARDIAN_LOAD_SUCCESS,
} from "../types/guardian-types";
import {
  GUARDIANS_LOAD_FAILURE,
  GUARDIANS_LOAD_REQUEST,
  GUARDIANS_LOAD_SUCCESS,
} from "../types/guardians-types";

export const getGuardiansAction = (location_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: GUARDIANS_LOAD_REQUEST });

    try {
      const guardianRepository = new GuardianRepository();
      const guardianUsecase = new GuardianUsecase(guardianRepository);

      const result = await guardianUsecase.GetOrganizationGuardians(
        location_id
      );

      dispatch({ type: GUARDIANS_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: GUARDIANS_LOAD_FAILURE, error });
    }
  };
};

export const getGuardianAction = (guardian_id: string, location_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: GUARDIAN_LOAD_REQUEST });

    try {
      const guardianRepository = new GuardianRepository();
      const guardianUsecase = new GuardianUsecase(guardianRepository);

      const result = await guardianUsecase.GetOrganizationGuardian(guardian_id);

      dispatch({ type: GUARDIAN_LOAD_SUCCESS, payload: result });
    } catch (error) {
      dispatch({ type: GUARDIAN_LOAD_FAILURE, error });
    }
  };
};
