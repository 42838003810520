// @ts-check
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { fade, makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";

import InputBase from "@material-ui/core/InputBase";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

// function sleep(delay = 0) {
//   return new Promise((resolve) => {
//     setTimeout(resolve, delay);
//   });
// }

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.black, 0.25),
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginRight: 0,
    marginLeft: 0,
    width: "100%",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    // padding: theme.spacing(1, 1, 1, 0),
    padding: theme.spacing(2, 1, 2, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
}));

export function SearchDriversComponent() {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const loading = open && options.length === 0;

  const { guardians } = useSelector((state) => state.guardians);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    async function fetch() {
      if (active) {
        setOptions(guardians);
      }
    }

    fetch();

    return () => {
      active = false;
    };
  }, [loading, guardians]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>

      <Autocomplete
        // id="asynchronous-demo"
        // style={{ width: 300 }}
        fullWidth={true}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(event, driver) => {
          // @ts-ignore
          history.push(`/guardians/${driver.id}/`);
        }}
        // @ts-ignore
        getOptionSelected={(option, value) => option.name === value.name}
        // @ts-ignore
        getOptionLabel={(option) => option.name}
        options={options}
        loading={loading}
        includeInputInList
        filterSelectedOptions
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <InputBase
            placeholder="Search guardians…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            color="secondary"
            // inputProps={{ 'aria-label': 'search' }}
            ref={params.InputProps.ref}
            {...params}
            // @ts-ignore
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        renderOption={(option) => {
          // @ts-ignore
          const matches = match(option.name, inputValue);
          // @ts-ignore
          const parts = parse(
            // @ts-ignore
            option.name,
            matches
          );

          console.log(matches);
          console.log(parts);

          return (
            <ListItem alignItems="flex-start" style={{ padding: "0" }}>
              <ListItemAvatar>
                <Avatar
                  // @ts-ignore
                  alt={option.name}
                  // @ts-ignore
                  src={option.image || "/static/images/avatar/1.jpg"}
                />
              </ListItemAvatar>
              <ListItemText
                // @ts-ignore
                primary={parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}
                secondary={
                  <React.Fragment>
                    {
                      // @ts-ignore
                      option.application.profession
                    }
                  </React.Fragment>
                }
              />
            </ListItem>
          );
        }}
      />
    </div>
  );
}
