// @ts-check

import React, { useState } from "react";

import LocalHospitalOutlinedIcon from "@material-ui/icons/LocalHospitalOutlined";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import NightsStayOutlinedIcon from "@material-ui/icons/NightsStayOutlined";

export function SelectAppointmentTypeComponent({
  setType,
  type_of_appointments_offered,
}) {
  const list = [];
  type_of_appointments_offered.includes("inperson") && list.push("inperson");
  type_of_appointments_offered.includes("video") && list.push("video");
  type_of_appointments_offered.includes("afterhours") &&
    list.push("afterhours");

  const [view, setView] = useState(list[0]);

  return (
    <>
      <ul
        className=" uk-grid-collapse uk-child-width-expand uk-margin-remove-top"
        data-uk-tab
      >
        {type_of_appointments_offered.includes("inperson") && (
          <li className={"uk-active"}>
            {/* eslint-disable-next-line */}
            <a
              onClick={() => {
                setType("inperson");
                setView("inperson");
              }}
              style={{
                border: view === "inperson" ? "1px solid #fd8361" : "",
                background: view === "inperson" ? "#fd8361" : "",
                color: view === "inperson" ? "#ffffff" : "",
                borderRadius: view === "inperson" ? "0.2em" : "",
                marginBottom: view === "inperson" ? "10px" : "",
              }}
            >
              <small
                className="uk-display-block uk-padding-small uk-padding-remove-horizontal"
                style={{
                  padding: "0px",
                }}
              >
                <LocalHospitalOutlinedIcon
                  fontSize={"small"}
                ></LocalHospitalOutlinedIcon>
                <span className="uk-display-block">
                  <b>In-clinic</b>
                </span>
              </small>
            </a>
          </li>
        )}

        {type_of_appointments_offered.includes("video") && (
          <li>
            {/* eslint-disable-next-line */}
            <a
              onClick={() => {
                setType("video");
                setView("video");
              }}
              style={{
                border: view === "video" ? "1px solid #fd8361" : "",
                background: view === "video" ? "#fd8361" : "",
                color: view === "video" ? "#ffffff" : "",
                borderRadius: view === "video" ? "0.2em" : "",
                marginBottom: view === "video" ? "10px" : "",
              }}
            >
              <small
                className="uk-display-block uk-padding-small uk-padding-remove-horizontal"
                style={{
                  padding: "0px",
                }}
              >
                <VideocamOutlinedIcon fontSize={"small"}></VideocamOutlinedIcon>
                <span className="uk-display-block">
                  <b>video</b>
                </span>
              </small>
            </a>
          </li>
        )}

        {type_of_appointments_offered.includes("afterhours") && (
          <li>
            {/* eslint-disable-next-line */}
            <a
              onClick={() => {
                setType("afterhours");
                setView("afterhours");
              }}
              style={{
                border: view === "afterhours" ? "1px solid #fd8361" : "",
                background: view === "afterhours" ? "#fd8361" : "",
                color: view === "afterhours" ? "#ffffff" : "",
                borderRadius: view === "afterhours" ? "0.2em" : "",
                marginBottom: view === "afterhours" ? "10px" : "",
              }}
            >
              <small
                className="uk-display-block uk-padding-small uk-padding-remove-horizontal"
                style={{
                  padding: "0px",
                }}
              >
                <NightsStayOutlinedIcon
                  fontSize={"small"}
                ></NightsStayOutlinedIcon>
                {/* <span style={{ paddingLeft: '5px' }}>
                  <VideocamOutlinedIcon fontSize={'small'} />
                </span> */}
                <span className="uk-display-block">
                  <b>afterhours</b>
                </span>
              </small>
            </a>
          </li>
        )}
      </ul>
    </>
  );
}
