// @ts-check
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGuardiansAction } from "../redux/actions/guardian-actions";

import CssBaseline from "@material-ui/core/CssBaseline";

import { GuardianListComponent } from "../components/guardians-list-component";
import { LoadingComponent } from "../components/loading-component";
import { NoResultsFound } from "../../../../core/constants";

export default function GuardiansHomePage() {
  const dispatch = useDispatch();
  const { location } = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(getGuardiansAction(location.id));
  }, [dispatch, location]);

  const { guardians, loading, error } = useSelector((state) => state.guardians);

  if (error) return <div>Error! {error.message}</div>;

  return (
    <>
      <React.Fragment>
        <CssBaseline />

        {loading && <LoadingComponent />}
        {!loading && (
          <>
            {guardians.length === 0 && (
              <>
                <NoResultsFound message={"no health professionals found"} />
              </>
            )}
            <GuardianListComponent guardians={guardians} />
          </>
        )}
      </React.Fragment>
    </>
  );
}
