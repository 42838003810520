import fetch from "isomorphic-fetch";
import config from "../../../../core/config/config";

export const login = async (
  organization_id: string,
  organization: string,
  email: string,
  password: string
): Promise<any> => {
  try {
    const uri = `${config.uri.auth_url}/auth/login/organization`;

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        organization_id,
        organization,
        email: email.trim(),
        password,
        administrator: false,
      }),
    };

    const response = await fetch(uri, options);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || "Something went wrong");
    }

    return result;
  } catch (err) {
    throw err;
  }
};
