// @ts-check
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { getMeAction } from "../../../features/me/presentation/redux/actions/me-actions";
import config from "../../config/config";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    left: "50%",
    top: "45%",
    transform: "translate(-50%, -50%)",
  },
  logo: {
    height: "3.5em",
    objectFit: "contain",
  },
  text: {
    height: "1.5em",
    objectFit: "contain",
  },
}));

export function AccessComponent(props) {
  const classes = useStyles();

  const [state, setState] = useState("loading");

  const { app_images } = config;
  const me = useSelector((state) => state.me);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMeAction());
  }, [dispatch]);

  useEffect(() => {
    if (me.success) {
      setState("approved");
    }
  }, [me]);

  switch (state) {
    case "approved":
      return props.children;

    case "loading":
    default:
      return (
        <div className={classes.root}>
          <div className="uk-text-center">
            <div data-uk-spinner="ratio: 1"></div>
            <br />
            <br />
            <img
              className={classes.text}
              src={app_images.pro_text_only}
              alt="Guardian Health"
            />
          </div>
        </div>
      );
  }
}
