// @ts-check
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getGuardianCalendarAction } from "../redux/actions/calendar-actions";

import { CalendarLoadingComponent } from "../components/calender-loading-component";
import { SlotsPage } from "./slots-page";

export function BookingCalendarPage({ id, modal = false }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGuardianCalendarAction(id, "video"));
  }, [dispatch, id]);

  const { calendar, loading } = useSelector((state) => state.calendar);

  if (loading || !calendar) {
    return <CalendarLoadingComponent />;
  }

  return (
    <>
      <SlotsPage
        id={id}
        calendar_availability={calendar.days}
        type_of_appointments_offered={calendar.appointment_types}
        pricing={calendar.pricing}
      />
    </>
  );
}
