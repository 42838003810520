// @ts-check
import React from 'react';

export function NoSlotsComponent() {
  return (
    <div className="uk-margin uk-text-center">
      <span data-uk-icon={`icon:  minus-circle; ratio: 4`}></span>
      <br />
      <br />
      <small className="uk-display-block ">
        <b>no slots available</b>
        <br />
        please choose a different appointment type or date
      </small>
    </div>
  );
}

export function NoDaySlotsComponent({ slotsDay }) {
  return (
    <div className="uk-margin uk-text-center">
      <span data-uk-icon={`icon:  forward; ratio: 2`}></span>
      <br />
      <br />
      <small className="uk-display-block ">
        <b>no slots available for {slotsDay}</b>
        <br />
        <b>please choose a different date</b>
      </small>
    </div>
  );
}
