import fetch from "isomorphic-fetch";
import config from "../../../../core/config/config";
import store from "../../../../core/redux/store";

export const weeklyReasonsAnalytics = async (
  level: string,
  filter: any
): Promise<any> => {
  try {
    const state = store.getState();

    const uri = `${config.uri.appointments_url}/analytics/org/weekly/reasons`;

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token.token}`,
      },
      body: JSON.stringify({
        level,
        filter,
      }),
    };

    const response = await fetch(uri, options);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || "Something went wrong");
    }

    return result;
  } catch (err) {
    throw err;
  }
};
