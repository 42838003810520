// @ts-check
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";

import { makeStyles } from "@material-ui/core/styles";

import { captureOfflinePaymentAction } from "../../redux/actions/appointment-actions";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // backgroundColor: theme.palette.background.paper,
  },
  formButtons: {
    marginTop: theme.spacing(2),
  },
}));

function CaptureOfflinePaymentForm(props) {
  const classes = useStyles();

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = "") => ({
    name: name,
    value: typeof values[name] !== "undefined" ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : "",
  });

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <TextField
        {...formikProps("reference")}
        id="outlined-full-width"
        label="Reference code"
        type="text"
        variant="outlined"
        fullWidth={true}
      />

      <div className={classes.formButtons}>
        <div>
          <button
            className="uk-button uk-button-primary uk-width-1-1 uk-margin"
            type="submit"
            style={{ background: "#49a861" }}
          >
            Mark As Paid
          </button>
        </div>
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  reference: Yup.string().required(
    "Confirm the appointment reference code above."
  ),
});

export function OfflinePaymentComponent({
  guardian_id,
  appointment_id,
  reference,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { location } = useSelector((state) => state.me);

  const submit = async (data) => {
    openBackdrop();

    const props = { history, closeBackdrop, showSnackbar };

    dispatch(
      captureOfflinePaymentAction(
        guardian_id,
        appointment_id,
        data.reference,
        reference,
        location.id,
        props
      )
    );
  };

  const values = { reference: "" };

  return (
    <div>
      <div className="uk-margin">
        <small className="uk-text-center uk-margin-top uk-display-block">
          Appointment will be marked as paid via offline arrangments.
          <br />
          Enter the reference code <b>{reference}</b> below.
        </small>
      </div>

      <div className="uk-margin">
        <Formik
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={submit}
        >
          {(props) => <CaptureOfflinePaymentForm {...props} />}
        </Formik>
      </div>
    </div>
  );
}
