import {
  ME_LOAD_REQUEST,
  ME_LOAD_SUCCESS,
  ME_LOAD_FAILURE,
  ME_SELECT_LOCATION,
} from "../types/me-types";

const initialState = {
  loading: true,
  error: null,
  success: false,
  me: null,
  location: null,
};

function me(state = initialState, action: any = null) {
  switch (action.type) {
    case ME_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ME_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case ME_LOAD_SUCCESS:
      return {
        ...state,
        me: action.payload,
        success: true,
        loading: false,
        error: null,
      };

    case ME_SELECT_LOCATION:
      return {
        ...state,
        location: action.payload,
      };

    default:
      return state;
  }
}

export default me;
