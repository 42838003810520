// @ts-check
import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import GroupIcon from "@material-ui/icons/Group";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import ScheduleIcon from "@material-ui/icons/Schedule";
import OverviewPage from "./overview-page";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0),
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  sectionHeading: {
    margin: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  clientLogo: {
    height: "8rem",
    width: "8rem",
    objectFit: "contain",
  },
  icon: {
    fontSize: "3em",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(2),
  },
}));

function Menu() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Paper
          className={classes.paper}
          elevation={0}
          onClick={() => history.push("/appointments")}
        >
          <ScheduleIcon className={classes.icon} fontSize="large" />

          <div className="uk-margin-small">
            <span>Appointments</span>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper
          className={classes.paper}
          elevation={0}
          onClick={() => history.push("/guardians")}
        >
          <LocalHospitalIcon className={classes.icon} fontSize="large" />

          <div className="uk-margin-small">
            <span>Health Pro's</span>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper
          className={classes.paper}
          elevation={0}
          onClick={() => history.push("/users")}
        >
          <GroupIcon className={classes.icon} fontSize="large" />

          <div className="uk-margin-small">
            <span>Users</span>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default function HomePage() {
  const classes = useStyles();

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          <>
            <Menu />
            {/* <br /> */}
            <Divider className={classes.divider} />

            <OverviewPage />
          </>

          <></>
        </Container>
      </React.Fragment>
    </>
  );
}
