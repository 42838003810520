import {
  ANALYTICS_LOAD_REQUEST,
  ANALYTICS_LOAD_SUCCESS,
  ANALYTICS_LOAD_FAILURE,
} from "../types/analytics-types";

const initialState = {
  loading: false,
  error: null,
  success: false,
  analytics: null,
};

function analytics(state = initialState, action: any = null) {
  switch (action.type) {
    case ANALYTICS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ANALYTICS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case ANALYTICS_LOAD_SUCCESS:
      return {
        ...state,
        analytics: action.payload,
        success: true,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
}

export default analytics;
