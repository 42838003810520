// @ts-check
import React from "react";

import { NoSlotsComponent } from "./no-slots-component";
import Time from "../pages/time-slot.js";
import { NoResultsFound } from "../../../../core/constants";

const styles = {
  professional: {
    height: "5.0em",
    width: "5.0em",
    objectFit: "cover",
  },
  phrase: {
    background: "#222",
  },
  filter: {
    background: "#222",
    cursor: "pointer",
  },
  pricing: {
    // background: '#012535',
    border: "1px solid rgb(73, 168, 97)",
    background: "rgb(73, 168, 97)",
    paddingTop: "5px",
    paddingBottom: "5px",
    color: "#fff",
    fontFamily: "Gilroy-ExtraBold",
  },
  duration: {
    // background: '#012535',
    border: "#000000",
    background: "#000000",
    paddingTop: "5px",
    paddingBottom: "5px",
    color: "#fff",
    fontFamily: "Gilroy-ExtraBold",
  },
  appointment: {
    border: "1px solid",
    // background: '#012535',
    // background: 'rgb(73, 168, 97)',
    paddingTop: "5px",
    paddingBottom: "5px",
    // color: '#fff'
    fontFamily: "Gilroy-ExtraBold",
  },
};

function Price({ type, currency, duration }) {
  return (
    <div>
      {/* <small
        className="uk-card uk-card-default uk-padding-small uk-border-rounded uk-h6 uk-margin-small-right"
        style={styles.appointment}
      >
        {type.name}
      </small> */}

      <span
        className="uk-card uk-card-default uk-padding-small uk-border-rounded uk-light uk-h6 uk-margin-small-right"
        style={styles.pricing}
      >
        {currency} {type.amount}
      </span>

      <span
        className="uk-card uk-card-default uk-padding-small uk-border-rounded uk-light uk-h6"
        style={styles.duration}
      >
        {duration} mins
      </span>
    </div>
  );
}

function Infoo({ slotsDay, type, consultation_pricing, duration }) {
  const types = {
    inperson: (
      <Price
        type={{
          name: "inperson",
          amount: consultation_pricing.inperson_amount,
        }}
        currency={consultation_pricing.currency}
        duration={duration}
      />
    ),
    video: (
      <Price
        type={{
          name: "video",
          amount: consultation_pricing.video_amount,
        }}
        currency={consultation_pricing.currency}
        duration={duration}
      />
    ),
    afterhours: (
      <Price
        type={{
          name: "afterhours",
          amount: consultation_pricing.after_hours_amount,
        }}
        currency={consultation_pricing.currency}
        duration={duration}
      />
    ),
  };

  return (
    <div className="uk-margin-medium-top-">
      <div className="uk-card uk-card-default uk-card-hover- uk-card-body uk-border-rounded uk-padding-small">
        <div className="uk-grid-small uk-child-width-expand" data-uk-grid>
          <div className="uk-display-inline uk-width-1-4">
            <div>
              <small className="uk-margin-left-">
                <h6 className="uk-margin-remove uk-display-inline">
                  {slotsDay}
                </h6>
              </small>
            </div>
          </div>

          <div className="uk-text-right uk-width-3-4">
            {/* <ArrowForwardIcon style={styles.icon} /> */}
            {/* <Pricing type={'video'} amount={23} currency={'ZAR'} /> */}

            {types[type]}
          </div>
        </div>
      </div>
    </div>
  );
}

export function SlotsComponent({
  slots,
  date,
  type,
  bookAppointment,
  afterhours,
  pricing,
  duration,
}) {
  const weekday = date.toLocaleDateString("default", {
    weekday: "short",
  });
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const slotsDay = `${weekday} ${day} ${month}`;

  return (
    <div>
      <>
        {type === "inperson" && (
          <>
            <Infoo
              type={type}
              slotsDay={slotsDay}
              consultation_pricing={pricing}
              duration={duration}
            />

            {!afterhours && (
              <div className="uk-padding-small uk-text-center">
                <small>Select an available time slot 123</small>
              </div>
            )}

            {afterhours && (
              <div className="uk-padding-small uk-text-center">
                <small>After hours slots are video consultations</small>
              </div>
            )}

            <Time
              date={date}
              afterhours={afterhours}
              bookAppointment={bookAppointment}
              time_slots={slots}
            />

            {slots.length === 0 && (
              <div className="uk-card uk-card-default uk-card-hover uk-card-body uk-border-rounded uk-padding-small">
                <NoSlotsComponent />
              </div>
            )}
          </>
        )}

        {type !== "inperson" && (
          <NoResultsFound
            message={
              <span>
                Can't book virtual appointments via the Front Desk
                <br />. Patient should visit https://guardianhealth.io
              </span>
            }
          />
        )}
      </>
    </div>
  );
}
