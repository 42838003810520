// @ts-check
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { AppointmentDetailsComponent } from "../components/appointment-details-component";
import { ConsultationPriceComponent } from "../components/consultation-price-component";
import { BookAppointmentFormComponent } from "../components/book-appointment-form";
import { BackNavigationComponent } from "../../../../core/routes/components/back-navigation-component";

import { BookBackgroundComponent } from "../components/book-background-component";
import { BookLoadingComponent } from "../components/book-loading-component";
import { useDispatch, useSelector } from "react-redux";
import { getGuardianAction } from "../../../guardians/presentation/redux/actions/guardian-actions";
import Grid from "@material-ui/core/Grid";
import { GuardianMinimunInfo } from "../../../guardians/presentation/components/guardian-minimum-info";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(2),
  },
}));

function BookingSwitcher({
  medical_guardian,
  datetime,
  type,
  walkin,
  duration,
}) {
  const preappointment = {
    type: type,
    reference: "CZOVYSK",
    status: "pending",
    datetime: new Date(parseInt(datetime)),
    reason: "Immunization & Baby",
    walkin: walkin === "true",
    medical_guardian,
    duration: parseInt(duration),
  };

  return (
    <>
      <AppointmentDetailsComponent preappointment={preappointment} />
      <GuardianMinimunInfo guardian={medical_guardian} />
      <ConsultationPriceComponent
        type={preappointment.type}
        medical_guardian={medical_guardian}
        duration={duration}
      />
      <Divider />
      <BookAppointmentFormComponent
        medical_guardian={medical_guardian}
        preappointment={preappointment}
      />
    </>
  );
}

export default function BookingPage(props) {
  // @ts-ignore
  const { id } = useParams();

  const params = new URLSearchParams(props.location.search);
  let datetime = params.get("date");
  let type = params.get("type");
  let walkin = params.get("walkin") || "";
  let duration = params.get("duration");

  const classes = useStyles();
  const dispatch = useDispatch();
  const { location } = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(getGuardianAction(id, location.id));
  }, [dispatch, id, location]);

  const { guardian, loading, success, error } = useSelector(
    (state) => state.guardian
  );

  if (error) return <div>Error! {error.message}</div>;

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {(loading || !success) && <BookLoadingComponent />}
          {!loading && success && (
            <>
              <BackNavigationComponent
                page="Book Appointment"
                uri="/guardians"
                path={[
                  {
                    page: "Home",
                    uri: "/",
                  },
                  {
                    page: "Guardians",
                    uri: "/guardians",
                  },
                  {
                    page: "Guardian",
                    uri: `/guardians/${id}`,
                  },
                ]}
              />

              <Grid container spacing={2} justify="center">
                <Grid item xs={12} md={12}>
                  <BookBackgroundComponent />
                </Grid>
                <Grid item xs={12} md={8}>
                  <BookingSwitcher
                    medical_guardian={guardian}
                    datetime={datetime}
                    type={type}
                    walkin={walkin}
                    duration={duration}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
