import { Dispatch } from "redux";
import Cookies from "universal-cookie";

import { REMOVE_USER_TOKEN } from "../types/token-types";

export const logoutAction = (props: any) => {
  return async (dispatch: Dispatch) => {
    const cookies = new Cookies();
    cookies.remove("gh_org_tok");

    props.history.push("/login");
    // props.history.push("/");

    dispatch({ type: REMOVE_USER_TOKEN });
    dispatch({ type: "RESET_APPLICATION_STATE" });
  };
};

export const logoutKickoutAction = () => {
  return async (dispatch: Dispatch) => {
    const cookies = new Cookies();
    cookies.remove("gh_org_tok");

    dispatch({ type: REMOVE_USER_TOKEN });
    dispatch({ type: "RESET_APPLICATION_STATE" });
  };
};
