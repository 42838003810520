// @ts-check
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";

import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton/IconButton";

import { LoadingButton } from "../../../../core/constants";
import { loginAction } from "../redux/actions/auth-actions";
import { OrganizationLookupComponent } from "./organization-lookup-component";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  sectionHeading: {
    margin: theme.spacing(2),
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formButtons: {
    marginTop: theme.spacing(2),
  },
  errorText: {
    color: "#f44336",
    textAlign: "center",
    display: "block",
    margin: theme.spacing(3),
  },
}));

function LoginForm(props) {
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = props;

  /**
   * @param {string} name
   */
  const formikProps = (name, initialValue = "") => ({
    name: name,
    value: typeof values[name] !== "undefined" ? values[name] : initialValue,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    helperText: touched[name] ? errors[name] : "",
  });

  const formikOrganizationProps = (name, initialValue = "") => ({
    name: name,
    onChange: handleChange,
    onBlur: handleBlur,
    error: touched[name] && Boolean(errors[name]),
    autoFocus: false,
    helperText: touched[name] ? "Enter a organization" : "",
  });

  function callbackOrganization(organization) {
    if (organization == null) {
      setFieldValue("organization", null);
      return;
    }

    setFieldValue("organization.id", organization.id);
    setFieldValue("organization.unique_name", organization.organization);
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { error, loading } = useSelector((state) => state.login);

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <OrganizationLookupComponent
        formikOrganizationProps={formikOrganizationProps}
        callbackOrganization={callbackOrganization}
      />

      <TextField
        {...formikProps("email")}
        id="outlined-full-width"
        label="Email"
        type="email"
        variant="outlined"
        fullWidth={true}
      />

      <TextField
        {...formikProps("password")}
        id="outlined-full-width"
        label="Password"
        type={showPassword ? "text" : "password"}
        // autoComplete="new-password"
        variant="outlined"
        fullWidth={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <div className={classes.formButtons}>
        {!loading && (
          <div>
            <button
              className="uk-button uk-button-primary uk-width-1-1 uk-margin-top"
              type="submit"
            >
              Login
            </button>
          </div>
        )}

        {loading && <LoadingButton />}

        {error && (
          <div>
            <span className={classes.errorText}>{error.message}</span>
          </div>
        )}
      </div>
    </form>
  );
}

const validationSchema = Yup.object({
  organization: Yup.object()
    .shape({
      id: Yup.string().required(),
      unique_name: Yup.string().required(),
    })
    .required("Organization is required"),
  email: Yup.string()
    .trim()
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must contain at least 6 characters.")
    .required("Enter your password"),
});

export function LoginFormComponent() {
  const history = useHistory();
  const dispatch = useDispatch();

  const values = { organization: null, email: "", password: "" };

  const submit = async (data) => {
    const props = { history };

    dispatch(
      loginAction(
        data.organization.id,
        data.organization.unique_name,
        // "604b62739086d8000e38ee9b",
        // "gravidcare",
        data.email,
        data.password,
        props
      )
    );
  };

  return (
    <>
      <div className="uk-margin-">
        <Formik
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={submit}
        >
          {(props) => <LoginForm {...props} />}
        </Formik>
      </div>
    </>
  );
}
