// @ts-check
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

import { UsersList } from "../components/users-list-component";
import { BackNavigationComponent } from "../../../../core/routes/components/back-navigation-component";
import { LoadingComponent } from "../components/loading-component";

import Grid from "@material-ui/core/Grid";

import { getUsersAction } from "../redux/actions/user-actions";

import { TextBackgroundComponent } from "../../../../core/utils/text-background-component";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    margin: theme.spacing(2),
  },
}));

export default function UsersPage() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { location } = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(getUsersAction(location.id));
  }, [dispatch, location]);

  const { users, loading, error } = useSelector((state) => state.users);

  if (error) return <div>Error! {error.message}</div>;

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Container className={classes.container}>
          {loading && <LoadingComponent />}
          {!loading && users && (
            <>
              <BackNavigationComponent
                page="Users"
                uri="/"
                path={[
                  {
                    page: "Home",
                    uri: "/",
                  },
                ]}
              />

              <Grid container spacing={2} justify="center">
                <Grid item xs={12} md={12}>
                  <TextBackgroundComponent text={"Users"} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <div className="uk-margin">
                    <UsersList users={users} />
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </React.Fragment>
    </>
  );
}
