import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import token from "./reducers/token-reducers";
import loading from "./reducers/loading-reducers";

import login from "../../features/authentication/presentation/redux/reducers/login-reducers";
import orgs from "../../features/authentication/presentation/redux/reducers/organizations-reducers";

import me from "../../features/me/presentation/redux/reducers/me-reducer";

import guardian from "../../features/guardians/presentation/redux/reducers/guardian-reducer";
import guardians from "../../features/guardians/presentation/redux/reducers/guardians-reducer";

import calendar from "../../features/calendar/presentation/redux/reducers/calendar-reducer";
import schedule from "../../features/calendar/presentation/redux/reducers/schedule-reducer";

import appointment from "../../features/appointments/presentation/redux/reducers/appointment-reducer";
import appointments from "../../features/appointments/presentation/redux/reducers/appointments-reducer";
import requests from "../../features/appointments/presentation/redux/reducers/requests-reducer";

import user from "../../features/users/presentation/redux/reducers/user-reducer";
import users from "../../features/users/presentation/redux/reducers/users-reducer";
import find_users from "../../features/users/presentation/redux/reducers/find-users-reducer";

import analytics from "../../features/analytics/presentation/redux/reducers/analytics-reducer";

const reducers = combineReducers({
  token,
  login,
  orgs,
  loading,
  me,
  guardians,
  guardian,
  calendar,
  schedule,
  appointment,
  appointments,
  requests,
  users,
  user,
  find_users,
  analytics,
});

const store = createStore(reducers, applyMiddleware(thunk));

export default store;
export type AppState = ReturnType<typeof reducers>;
