export const timezones = [
  { name: 'Africa/Abidjan', gmt: '+00:00' },
  { name: 'Africa/Accra', gmt: '+00:00' },
  { name: 'Africa/Addis_Ababa', gmt: '+00:00' },
  { name: 'Africa/Algiers', gmt: '+00:00' },
  { name: 'Africa/Asmara', gmt: '+00:00' },
  { name: 'Africa/Asmera', gmt: '+00:00' },
  { name: 'Africa/Bamako', gmt: '+00:00' },
  { name: 'Africa/Bangui', gmt: '+00:00' },
  { name: 'Africa/Banjul', gmt: '+00:00' },
  { name: 'Africa/Bissau', gmt: '+00:00' },
  { name: 'Africa/Blantyre', gmt: '+00:00' },
  { name: 'Africa/Brazzaville', gmt: '+00:00' },
  { name: 'Africa/Bujumbura', gmt: '+00:00' },
  { name: 'Africa/Cairo', gmt: '+00:00' },
  { name: 'Africa/Casablanca', gmt: '+00:00' },
  { name: 'Africa/Ceuta', gmt: '+00:00' },
  { name: 'Africa/Conakry', gmt: '+00:00' },
  { name: 'Africa/Dakar', gmt: '+00:00' },
  { name: 'Africa/Dar_es_Salaam', gmt: '+00:00' },
  { name: 'Africa/Djibouti', gmt: '+00:00' },
  { name: 'Africa/Douala', gmt: '+00:00' },
  { name: 'Africa/El_Aaiun', gmt: '+00:00' },
  { name: 'Africa/Freetown', gmt: '+00:00' },
  { name: 'Africa/Gaborone', gmt: '+00:00' },
  { name: 'Africa/Harare', gmt: '+00:00' },
  { name: 'Africa/Johannesburg', gmt: '+00:00' },
  { name: 'Africa/Juba', gmt: '+00:00' },
  { name: 'Africa/Kampala', gmt: '+00:00' },
  { name: 'Africa/Khartoum', gmt: '+00:00' },
  { name: 'Africa/Kigali', gmt: '+00:00' },
  { name: 'Africa/Kinshasa', gmt: '+00:00' },
  { name: 'Africa/Lagos', gmt: '+00:00' },
  { name: 'Africa/Libreville', gmt: '+00:00' },
  { name: 'Africa/Lome', gmt: '+00:00' },
  { name: 'Africa/Luanda', gmt: '+00:00' },
  { name: 'Africa/Lubumbashi', gmt: '+00:00' },
  { name: 'Africa/Lusaka', gmt: '+00:00' },
  { name: 'Africa/Malabo', gmt: '+00:00' },
  { name: 'Africa/Maputo', gmt: '+00:00' },
  { name: 'Africa/Maseru', gmt: '+00:00' },
  { name: 'Africa/Mbabane', gmt: '+00:00' },
  { name: 'Africa/Mogadishu', gmt: '+00:00' },
  { name: 'Africa/Monrovia', gmt: '+00:00' },
  { name: 'Africa/Nairobi', gmt: '+00:00' },
  { name: 'Africa/Ndjamena', gmt: '+00:00' },
  { name: 'Africa/Niamey', gmt: '+00:00' },
  { name: 'Africa/Nouakchott', gmt: '+00:00' },
  { name: 'Africa/Ouagadougou', gmt: '+00:00' },
  { name: 'Africa/Porto-Novo', gmt: '+00:00' },
  { name: 'Africa/Sao_Tome', gmt: '+00:00' },
  { name: 'Africa/Timbuktu', gmt: '+00:00' },
  { name: 'Africa/Tripoli', gmt: '+00:00' },
  { name: 'Africa/Tunis', gmt: '+00:00' },
  { name: 'Africa/Windhoek', gmt: '+00:00' },
  { name: 'America/Adak', gmt: '+00:00' },
  { name: 'America/Anchorage', gmt: '+00:00' },
  { name: 'America/Anguilla', gmt: '+00:00' },
  { name: 'America/Antigua', gmt: '+00:00' },
  { name: 'America/Araguaina', gmt: '+00:00' },
  { name: 'America/Argentina/Buenos_Aires', gmt: '+00:00' },
  { name: 'America/Argentina/Catamarca', gmt: '+00:00' },
  { name: 'America/Argentina/ComodRivadavia', gmt: '+00:00' },
  { name: 'America/Argentina/Cordoba', gmt: '+00:00' },
  { name: 'America/Argentina/Jujuy', gmt: '+00:00' },
  { name: 'America/Argentina/La_Rioja', gmt: '+00:00' },
  { name: 'America/Argentina/Mendoza', gmt: '+00:00' },
  { name: 'America/Argentina/Rio_Gallegos', gmt: '+00:00' },
  { name: 'America/Argentina/Salta', gmt: '+00:00' },
  { name: 'America/Argentina/San_Juan', gmt: '+00:00' },
  { name: 'America/Argentina/San_Luis', gmt: '+00:00' },
  { name: 'America/Argentina/Tucuman', gmt: '+00:00' },
  { name: 'America/Argentina/Ushuaia', gmt: '+00:00' },
  { name: 'America/Aruba', gmt: '+00:00' },
  { name: 'America/Asuncion', gmt: '+00:00' },
  { name: 'America/Atikokan', gmt: '+00:00' },
  { name: 'America/Atka', gmt: '+00:00' },
  { name: 'America/Bahia', gmt: '+00:00' },
  { name: 'America/Bahia_Banderas', gmt: '+00:00' },
  { name: 'America/Barbados', gmt: '+00:00' },
  { name: 'America/Belem', gmt: '+00:00' },
  { name: 'America/Belize', gmt: '+00:00' },
  { name: 'America/Blanc-Sablon', gmt: '+00:00' },
  { name: 'America/Boa_Vista', gmt: '+00:00' },
  { name: 'America/Bogota', gmt: '+00:00' },
  { name: 'America/Boise', gmt: '+00:00' },
  { name: 'America/Buenos_Aires', gmt: '+00:00' },
  { name: 'America/Cambridge_Bay', gmt: '+00:00' },
  { name: 'America/Campo_Grande', gmt: '+00:00' },
  { name: 'America/Cancun', gmt: '+00:00' },
  { name: 'America/Caracas', gmt: '+00:00' },
  { name: 'America/Catamarca', gmt: '+00:00' },
  { name: 'America/Cayenne', gmt: '+00:00' },
  { name: 'America/Cayman', gmt: '+00:00' },
  { name: 'America/Chicago', gmt: '+00:00' },
  { name: 'America/Chihuahua', gmt: '+00:00' },
  { name: 'America/Coral_Harbour', gmt: '+00:00' },
  { name: 'America/Cordoba', gmt: '+00:00' },
  { name: 'America/Costa_Rica', gmt: '+00:00' },
  { name: 'America/Creston', gmt: '+00:00' },
  { name: 'America/Cuiaba', gmt: '+00:00' },
  { name: 'America/Curacao', gmt: '+00:00' },
  { name: 'America/Danmarkshavn', gmt: '+00:00' },
  { name: 'America/Dawson', gmt: '+00:00' },
  { name: 'America/Dawson_Creek', gmt: '+00:00' },
  { name: 'America/Denver', gmt: '+00:00' },
  { name: 'America/Detroit', gmt: '+00:00' },
  { name: 'America/Dominica', gmt: '+00:00' },
  { name: 'America/Edmonton', gmt: '+00:00' },
  { name: 'America/Eirunepe', gmt: '+00:00' },
  { name: 'America/El_Salvador', gmt: '+00:00' },
  { name: 'America/Ensenada', gmt: '+00:00' },
  { name: 'America/Fort_Nelson', gmt: '+00:00' },
  { name: 'America/Fort_Wayne', gmt: '+00:00' },
  { name: 'America/Fortaleza', gmt: '+00:00' },
  { name: 'America/Glace_Bay', gmt: '+00:00' },
  { name: 'America/Godthab', gmt: '+00:00' },
  { name: 'America/Goose_Bay', gmt: '+00:00' },
  { name: 'America/Grand_Turk', gmt: '+00:00' },
  { name: 'America/Grenada', gmt: '+00:00' },
  { name: 'America/Guadeloupe', gmt: '+00:00' },
  { name: 'America/Guatemala', gmt: '+00:00' },
  { name: 'America/Guayaquil', gmt: '+00:00' },
  { name: 'America/Guyana', gmt: '+00:00' },
  { name: 'America/Halifax', gmt: '+00:00' },
  { name: 'America/Havana', gmt: '+00:00' },
  { name: 'America/Hermosillo', gmt: '+00:00' },
  { name: 'America/Indiana/Indianapolis', gmt: '+00:00' },
  { name: 'America/Indiana/Knox', gmt: '+00:00' },
  { name: 'America/Indiana/Marengo', gmt: '+00:00' },
  { name: 'America/Indiana/Petersburg', gmt: '+00:00' },
  { name: 'America/Indiana/Tell_City', gmt: '+00:00' },
  { name: 'America/Indiana/Vevay', gmt: '+00:00' },
  { name: 'America/Indiana/Vincennes', gmt: '+00:00' },
  { name: 'America/Indiana/Winamac', gmt: '+00:00' },
  { name: 'America/Indianapolis', gmt: '+00:00' },
  { name: 'America/Inuvik', gmt: '+00:00' },
  { name: 'America/Iqaluit', gmt: '+00:00' },
  { name: 'America/Jamaica', gmt: '+00:00' },
  { name: 'America/Jujuy', gmt: '+00:00' },
  { name: 'America/Juneau', gmt: '+00:00' },
  { name: 'America/Kentucky/Louisville', gmt: '+00:00' },
  { name: 'America/Kentucky/Monticello', gmt: '+00:00' },
  { name: 'America/Knox_IN', gmt: '+00:00' },
  { name: 'America/Kralendijk', gmt: '+00:00' },
  { name: 'America/La_Paz', gmt: '+00:00' },
  { name: 'America/Lima', gmt: '+00:00' },
  { name: 'America/Los_Angeles', gmt: '+00:00' },
  { name: 'America/Louisville', gmt: '+00:00' },
  { name: 'America/Lower_Princes', gmt: '+00:00' },
  { name: 'America/Maceio', gmt: '+00:00' },
  { name: 'America/Managua', gmt: '+00:00' },
  { name: 'America/Manaus', gmt: '+00:00' },
  { name: 'America/Marigot', gmt: '+00:00' },
  { name: 'America/Martinique', gmt: '+00:00' },
  { name: 'America/Matamoros', gmt: '+00:00' },
  { name: 'America/Mazatlan', gmt: '+00:00' },
  { name: 'America/Mendoza', gmt: '+00:00' },
  { name: 'America/Menominee', gmt: '+00:00' },
  { name: 'America/Merida', gmt: '+00:00' },
  { name: 'America/Metlakatla', gmt: '+00:00' },
  { name: 'America/Mexico_City', gmt: '+00:00' },
  { name: 'America/Miquelon', gmt: '+00:00' },
  { name: 'America/Moncton', gmt: '+00:00' },
  { name: 'America/Monterrey', gmt: '+00:00' },
  { name: 'America/Montevideo', gmt: '+00:00' },
  { name: 'America/Montreal', gmt: '+00:00' },
  { name: 'America/Montserrat', gmt: '+00:00' },
  { name: 'America/Nassau', gmt: '+00:00' },
  { name: 'America/New_York', gmt: '+00:00' },
  { name: 'America/Nipigon', gmt: '+00:00' },
  { name: 'America/Nome', gmt: '+00:00' },
  { name: 'America/Noronha', gmt: '+00:00' },
  { name: 'America/North_Dakota/Beulah', gmt: '+00:00' },
  { name: 'America/North_Dakota/Center', gmt: '+00:00' },
  { name: 'America/North_Dakota/New_Salem', gmt: '+00:00' },
  { name: 'America/Ojinaga', gmt: '+00:00' },
  { name: 'America/Panama', gmt: '+00:00' },
  { name: 'America/Pangnirtung', gmt: '+00:00' },
  { name: 'America/Paramaribo', gmt: '+00:00' },
  { name: 'America/Phoenix', gmt: '+00:00' },
  { name: 'America/Port-au-Prince', gmt: '+00:00' },
  { name: 'America/Port_of_Spain', gmt: '+00:00' },
  { name: 'America/Porto_Acre', gmt: '+00:00' },
  { name: 'America/Porto_Velho', gmt: '+00:00' },
  { name: 'America/Puerto_Rico', gmt: '+00:00' },
  { name: 'America/Punta_Arenas', gmt: '+00:00' },
  { name: 'America/Rainy_River', gmt: '+00:00' },
  { name: 'America/Rankin_Inlet', gmt: '+00:00' },
  { name: 'America/Recife', gmt: '+00:00' },
  { name: 'America/Regina', gmt: '+00:00' },
  { name: 'America/Resolute', gmt: '+00:00' },
  { name: 'America/Rio_Branco', gmt: '+00:00' },
  { name: 'America/Rosario', gmt: '+00:00' },
  { name: 'America/Santa_Isabel', gmt: '+00:00' },
  { name: 'America/Santarem', gmt: '+00:00' },
  { name: 'America/Santiago', gmt: '+00:00' },
  { name: 'America/Santo_Domingo', gmt: '+00:00' },
  { name: 'America/Sao_Paulo', gmt: '+00:00' },
  { name: 'America/Scoresbysund', gmt: '+00:00' },
  { name: 'America/Shiprock', gmt: '+00:00' },
  { name: 'America/Sitka', gmt: '+00:00' },
  { name: 'America/St_Barthelemy', gmt: '+00:00' },
  { name: 'America/St_Johns', gmt: '+00:00' },
  { name: 'America/St_Kitts', gmt: '+00:00' },
  { name: 'America/St_Lucia', gmt: '+00:00' },
  { name: 'America/St_Thomas', gmt: '+00:00' },
  { name: 'America/St_Vincent', gmt: '+00:00' },
  { name: 'America/Swift_Current', gmt: '+00:00' },
  { name: 'America/Tegucigalpa', gmt: '+00:00' },
  { name: 'America/Thule', gmt: '+00:00' },
  { name: 'America/Thunder_Bay', gmt: '+00:00' },
  { name: 'America/Tijuana', gmt: '+00:00' },
  { name: 'America/Toronto', gmt: '+00:00' },
  { name: 'America/Tortola', gmt: '+00:00' },
  { name: 'America/Vancouver', gmt: '+00:00' },
  { name: 'America/Virgin', gmt: '+00:00' },
  { name: 'America/Whitehorse', gmt: '+00:00' },
  { name: 'America/Winnipeg', gmt: '+00:00' },
  { name: 'America/Yakutat', gmt: '+00:00' },
  { name: 'America/Yellowknife', gmt: '+00:00' },
  { name: 'Antarctica/Casey', gmt: '+00:00' },
  { name: 'Antarctica/Davis', gmt: '+00:00' },
  { name: 'Antarctica/DumontDUrville', gmt: '+00:00' },
  { name: 'Antarctica/Macquarie', gmt: '+00:00' },
  { name: 'Antarctica/Mawson', gmt: '+00:00' },
  { name: 'Antarctica/McMurdo', gmt: '+00:00' },
  { name: 'Antarctica/Palmer', gmt: '+00:00' },
  { name: 'Antarctica/Rothera', gmt: '+00:00' },
  { name: 'Antarctica/South_Pole', gmt: '+00:00' },
  { name: 'Antarctica/Syowa', gmt: '+00:00' },
  { name: 'Antarctica/Troll', gmt: '+00:00' },
  { name: 'Antarctica/Vostok', gmt: '+00:00' },
  { name: 'Arctic/Longyearbyen', gmt: '+00:00' },
  { name: 'Asia/Aden', gmt: '+00:00' },
  { name: 'Asia/Almaty', gmt: '+00:00' },
  { name: 'Asia/Amman', gmt: '+00:00' },
  { name: 'Asia/Anadyr', gmt: '+00:00' },
  { name: 'Asia/Aqtau', gmt: '+00:00' },
  { name: 'Asia/Aqtobe', gmt: '+00:00' },
  { name: 'Asia/Ashgabat', gmt: '+00:00' },
  { name: 'Asia/Ashkhabad', gmt: '+00:00' },
  { name: 'Asia/Atyrau', gmt: '+00:00' },
  { name: 'Asia/Baghdad', gmt: '+00:00' },
  { name: 'Asia/Bahrain', gmt: '+00:00' },
  { name: 'Asia/Baku', gmt: '+00:00' },
  { name: 'Asia/Bangkok', gmt: '+00:00' },
  { name: 'Asia/Barnaul', gmt: '+00:00' },
  { name: 'Asia/Beirut', gmt: '+00:00' },
  { name: 'Asia/Bishkek', gmt: '+00:00' },
  { name: 'Asia/Brunei', gmt: '+00:00' },
  { name: 'Asia/Calcutta', gmt: '+00:00' },
  { name: 'Asia/Chita', gmt: '+00:00' },
  { name: 'Asia/Choibalsan', gmt: '+00:00' },
  { name: 'Asia/Chongqing', gmt: '+00:00' },
  { name: 'Asia/Chungking', gmt: '+00:00' },
  { name: 'Asia/Colombo', gmt: '+00:00' },
  { name: 'Asia/Dacca', gmt: '+00:00' },
  { name: 'Asia/Damascus', gmt: '+00:00' },
  { name: 'Asia/Dhaka', gmt: '+00:00' },
  { name: 'Asia/Dili', gmt: '+00:00' },
  { name: 'Asia/Dubai', gmt: '+00:00' },
  { name: 'Asia/Dushanbe', gmt: '+00:00' },
  { name: 'Asia/Famagusta', gmt: '+00:00' },
  { name: 'Asia/Gaza', gmt: '+00:00' },
  { name: 'Asia/Harbin', gmt: '+00:00' },
  { name: 'Asia/Hebron', gmt: '+00:00' },
  { name: 'Asia/Ho_Chi_Minh', gmt: '+00:00' },
  { name: 'Asia/Hong_Kong', gmt: '+00:00' },
  { name: 'Asia/Hovd', gmt: '+00:00' },
  { name: 'Asia/Irkutsk', gmt: '+00:00' },
  { name: 'Asia/Istanbul', gmt: '+00:00' },
  { name: 'Asia/Jakarta', gmt: '+00:00' },
  { name: 'Asia/Jayapura', gmt: '+00:00' },
  { name: 'Asia/Jerusalem', gmt: '+00:00' },
  { name: 'Asia/Kabul', gmt: '+00:00' },
  { name: 'Asia/Kamchatka', gmt: '+00:00' },
  { name: 'Asia/Karachi', gmt: '+00:00' },
  { name: 'Asia/Kashgar', gmt: '+00:00' },
  { name: 'Asia/Kathmandu', gmt: '+00:00' },
  { name: 'Asia/Katmandu', gmt: '+00:00' },
  { name: 'Asia/Khandyga', gmt: '+00:00' },
  { name: 'Asia/Kolkata', gmt: '+00:00' },
  { name: 'Asia/Krasnoyarsk', gmt: '+00:00' },
  { name: 'Asia/Kuala_Lumpur', gmt: '+00:00' },
  { name: 'Asia/Kuching', gmt: '+00:00' },
  { name: 'Asia/Kuwait', gmt: '+00:00' },
  { name: 'Asia/Macao', gmt: '+00:00' },
  { name: 'Asia/Macau', gmt: '+00:00' },
  { name: 'Asia/Magadan', gmt: '+00:00' },
  { name: 'Asia/Makassar', gmt: '+00:00' },
  { name: 'Asia/Manila', gmt: '+00:00' },
  { name: 'Asia/Muscat', gmt: '+00:00' },
  { name: 'Asia/Nicosia', gmt: '+00:00' },
  { name: 'Asia/Novokuznetsk', gmt: '+00:00' },
  { name: 'Asia/Novosibirsk', gmt: '+00:00' },
  { name: 'Asia/Omsk', gmt: '+00:00' },
  { name: 'Asia/Oral', gmt: '+00:00' },
  { name: 'Asia/Phnom_Penh', gmt: '+00:00' },
  { name: 'Asia/Pontianak', gmt: '+00:00' },
  { name: 'Asia/Pyongyang', gmt: '+00:00' },
  { name: 'Asia/Qatar', gmt: '+00:00' },
  { name: 'Asia/Qostanay', gmt: '+00:00' },
  { name: 'Asia/Qyzylorda', gmt: '+00:00' },
  { name: 'Asia/Rangoon', gmt: '+00:00' },
  { name: 'Asia/Riyadh', gmt: '+00:00' },
  { name: 'Asia/Saigon', gmt: '+00:00' },
  { name: 'Asia/Sakhalin', gmt: '+00:00' },
  { name: 'Asia/Samarkand', gmt: '+00:00' },
  { name: 'Asia/Seoul', gmt: '+00:00' },
  { name: 'Asia/Shanghai', gmt: '+00:00' },
  { name: 'Asia/Singapore', gmt: '+00:00' },
  { name: 'Asia/Srednekolymsk', gmt: '+00:00' },
  { name: 'Asia/Taipei', gmt: '+00:00' },
  { name: 'Asia/Tashkent', gmt: '+00:00' },
  { name: 'Asia/Tbilisi', gmt: '+00:00' },
  { name: 'Asia/Tehran', gmt: '+00:00' },
  { name: 'Asia/Tel_Aviv', gmt: '+00:00' },
  { name: 'Asia/Thimbu', gmt: '+00:00' },
  { name: 'Asia/Thimphu', gmt: '+00:00' },
  { name: 'Asia/Tokyo', gmt: '+00:00' },
  { name: 'Asia/Tomsk', gmt: '+00:00' },
  { name: 'Asia/Ujung_Pandang', gmt: '+00:00' },
  { name: 'Asia/Ulaanbaatar', gmt: '+00:00' },
  { name: 'Asia/Ulan_Bator', gmt: '+00:00' },
  { name: 'Asia/Urumqi', gmt: '+00:00' },
  { name: 'Asia/Ust-Nera', gmt: '+00:00' },
  { name: 'Asia/Vientiane', gmt: '+00:00' },
  { name: 'Asia/Vladivostok', gmt: '+00:00' },
  { name: 'Asia/Yakutsk', gmt: '+00:00' },
  { name: 'Asia/Yangon', gmt: '+00:00' },
  { name: 'Asia/Yekaterinburg', gmt: '+00:00' },
  { name: 'Asia/Yerevan', gmt: '+00:00' },
  { name: 'Atlantic/Azores', gmt: '+00:00' },
  { name: 'Atlantic/Bermuda', gmt: '+00:00' },
  { name: 'Atlantic/Canary', gmt: '+00:00' },
  { name: 'Atlantic/Cape_Verde', gmt: '+00:00' },
  { name: 'Atlantic/Faeroe', gmt: '+00:00' },
  { name: 'Atlantic/Faroe', gmt: '+00:00' },
  { name: 'Atlantic/Jan_Mayen', gmt: '+00:00' },
  { name: 'Atlantic/Madeira', gmt: '+00:00' },
  { name: 'Atlantic/Reykjavik', gmt: '+00:00' },
  { name: 'Atlantic/South_Georgia', gmt: '+00:00' },
  { name: 'Atlantic/St_Helena', gmt: '+00:00' },
  { name: 'Atlantic/Stanley', gmt: '+00:00' },
  { name: 'Australia/ACT', gmt: '+00:00' },
  { name: 'Australia/Adelaide', gmt: '+00:00' },
  { name: 'Australia/Brisbane', gmt: '+00:00' },
  { name: 'Australia/Broken_Hill', gmt: '+00:00' },
  { name: 'Australia/Canberra', gmt: '+00:00' },
  { name: 'Australia/Currie', gmt: '+00:00' },
  { name: 'Australia/Darwin', gmt: '+00:00' },
  { name: 'Australia/Eucla', gmt: '+00:00' },
  { name: 'Australia/Hobart', gmt: '+00:00' },
  { name: 'Australia/LHI', gmt: '+00:00' },
  { name: 'Australia/Lindeman', gmt: '+00:00' },
  { name: 'Australia/Lord_Howe', gmt: '+00:00' },
  { name: 'Australia/Melbourne', gmt: '+00:00' },
  { name: 'Australia/NSW', gmt: '+00:00' },
  { name: 'Australia/North', gmt: '+00:00' },
  { name: 'Australia/Perth', gmt: '+00:00' },
  { name: 'Australia/Queensland', gmt: '+00:00' },
  { name: 'Australia/South', gmt: '+00:00' },
  { name: 'Australia/Sydney', gmt: '+00:00' },
  { name: 'Australia/Tasmania', gmt: '+00:00' },
  { name: 'Australia/Victoria', gmt: '+00:00' },
  { name: 'Australia/West', gmt: '+00:00' },
  { name: 'Australia/Yancowinna', gmt: '+00:00' },
  { name: 'Brazil/Acre', gmt: '+00:00' },
  { name: 'Brazil/DeNoronha', gmt: '+00:00' },
  { name: 'Brazil/East', gmt: '+00:00' },
  { name: 'Brazil/West', gmt: '+00:00' },
  { name: 'CET', gmt: '+00:00' },
  { name: 'CST6CDT', gmt: '+00:00' },
  { name: 'Canada/Atlantic', gmt: '+00:00' },
  { name: 'Canada/Central', gmt: '+00:00' },
  { name: 'Canada/Eastern', gmt: '+00:00' },
  { name: 'Canada/Mountain', gmt: '+00:00' },
  { name: 'Canada/Newfoundland', gmt: '+00:00' },
  { name: 'Canada/Pacific', gmt: '+00:00' },
  { name: 'Canada/Saskatchewan', gmt: '+00:00' },
  { name: 'Canada/Yukon', gmt: '+00:00' },
  { name: 'Chile/Continental', gmt: '+00:00' },
  { name: 'Chile/EasterIsland', gmt: '+00:00' },
  { name: 'Cuba', gmt: '+00:00' },
  { name: 'EET', gmt: '+00:00' },
  { name: 'EST', gmt: '+00:00' },
  { name: 'EST5EDT', gmt: '+00:00' },
  { name: 'Egypt', gmt: '+00:00' },
  { name: 'Eire', gmt: '+00:00' },
  { name: 'Etc/GMT', gmt: '+00:00' },
  { name: 'Etc/GMT+0', gmt: '+00:00' },
  { name: 'Etc/GMT+1', gmt: '+00:00' },
  { name: 'Etc/GMT+10', gmt: '+00:00' },
  { name: 'Etc/GMT+11', gmt: '+00:00' },
  { name: 'Etc/GMT+12', gmt: '+00:00' },
  { name: 'Etc/GMT+2', gmt: '+00:00' },
  { name: 'Etc/GMT+3', gmt: '+00:00' },
  { name: 'Etc/GMT+4', gmt: '+00:00' },
  { name: 'Etc/GMT+5', gmt: '+00:00' },
  { name: 'Etc/GMT+6', gmt: '+00:00' },
  { name: 'Etc/GMT+7', gmt: '+00:00' },
  { name: 'Etc/GMT+8', gmt: '+00:00' },
  { name: 'Etc/GMT+9', gmt: '+00:00' },
  { name: 'Etc/GMT-0', gmt: '+00:00' },
  { name: 'Etc/GMT-1', gmt: '+00:00' },
  { name: 'Etc/GMT-10', gmt: '+00:00' },
  { name: 'Etc/GMT-11', gmt: '+00:00' },
  { name: 'Etc/GMT-12', gmt: '+00:00' },
  { name: 'Etc/GMT-13', gmt: '+00:00' },
  { name: 'Etc/GMT-14', gmt: '+00:00' },
  { name: 'Etc/GMT-2', gmt: '+00:00' },
  { name: 'Etc/GMT-3', gmt: '+00:00' },
  { name: 'Etc/GMT-4', gmt: '+00:00' },
  { name: 'Etc/GMT-5', gmt: '+00:00' },
  { name: 'Etc/GMT-6', gmt: '+00:00' },
  { name: 'Etc/GMT-7', gmt: '+00:00' },
  { name: 'Etc/GMT-8', gmt: '+00:00' },
  { name: 'Etc/GMT-9', gmt: '+00:00' },
  { name: 'Etc/GMT0', gmt: '+00:00' },
  { name: 'Etc/Greenwich', gmt: '+00:00' },
  { name: 'Etc/UCT', gmt: '+00:00' },
  { name: 'Etc/UTC', gmt: '+00:00' },
  { name: 'Etc/Universal', gmt: '+00:00' },
  { name: 'Etc/Zulu', gmt: '+00:00' },
  { name: 'Europe/Amsterdam', gmt: '+00:00' },
  { name: 'Europe/Andorra', gmt: '+00:00' },
  { name: 'Europe/Astrakhan', gmt: '+00:00' },
  { name: 'Europe/Athens', gmt: '+00:00' },
  { name: 'Europe/Belfast', gmt: '+00:00' },
  { name: 'Europe/Belgrade', gmt: '+00:00' },
  { name: 'Europe/Berlin', gmt: '+00:00' },
  { name: 'Europe/Bratislava', gmt: '+00:00' },
  { name: 'Europe/Brussels', gmt: '+00:00' },
  { name: 'Europe/Bucharest', gmt: '+00:00' },
  { name: 'Europe/Budapest', gmt: '+00:00' },
  { name: 'Europe/Busingen', gmt: '+00:00' },
  { name: 'Europe/Chisinau', gmt: '+00:00' },
  { name: 'Europe/Copenhagen', gmt: '+00:00' },
  { name: 'Europe/Dublin', gmt: '+00:00' },
  { name: 'Europe/Gibraltar', gmt: '+00:00' },
  { name: 'Europe/Guernsey', gmt: '+00:00' },
  { name: 'Europe/Helsinki', gmt: '+00:00' },
  { name: 'Europe/Isle_of_Man', gmt: '+00:00' },
  { name: 'Europe/Istanbul', gmt: '+00:00' },
  { name: 'Europe/Jersey', gmt: '+00:00' },
  { name: 'Europe/Kaliningrad', gmt: '+00:00' },
  { name: 'Europe/Kiev', gmt: '+00:00' },
  { name: 'Europe/Kirov', gmt: '+00:00' },
  { name: 'Europe/Lisbon', gmt: '+00:00' },
  { name: 'Europe/Ljubljana', gmt: '+00:00' },
  { name: 'Europe/London', gmt: '+00:00' },
  { name: 'Europe/Luxembourg', gmt: '+00:00' },
  { name: 'Europe/Madrid', gmt: '+00:00' },
  { name: 'Europe/Malta', gmt: '+00:00' },
  { name: 'Europe/Mariehamn', gmt: '+00:00' },
  { name: 'Europe/Minsk', gmt: '+00:00' },
  { name: 'Europe/Monaco', gmt: '+00:00' },
  { name: 'Europe/Moscow', gmt: '+00:00' },
  { name: 'Europe/Nicosia', gmt: '+00:00' },
  { name: 'Europe/Oslo', gmt: '+00:00' },
  { name: 'Europe/Paris', gmt: '+00:00' },
  { name: 'Europe/Podgorica', gmt: '+00:00' },
  { name: 'Europe/Prague', gmt: '+00:00' },
  { name: 'Europe/Riga', gmt: '+00:00' },
  { name: 'Europe/Rome', gmt: '+00:00' },
  { name: 'Europe/Samara', gmt: '+00:00' },
  { name: 'Europe/San_Marino', gmt: '+00:00' },
  { name: 'Europe/Sarajevo', gmt: '+00:00' },
  { name: 'Europe/Saratov', gmt: '+00:00' },
  { name: 'Europe/Simferopol', gmt: '+00:00' },
  { name: 'Europe/Skopje', gmt: '+00:00' },
  { name: 'Europe/Sofia', gmt: '+00:00' },
  { name: 'Europe/Stockholm', gmt: '+00:00' },
  { name: 'Europe/Tallinn', gmt: '+00:00' },
  { name: 'Europe/Tirane', gmt: '+00:00' },
  { name: 'Europe/Tiraspol', gmt: '+00:00' },
  { name: 'Europe/Ulyanovsk', gmt: '+00:00' },
  { name: 'Europe/Uzhgorod', gmt: '+00:00' },
  { name: 'Europe/Vaduz', gmt: '+00:00' },
  { name: 'Europe/Vatican', gmt: '+00:00' },
  { name: 'Europe/Vienna', gmt: '+00:00' },
  { name: 'Europe/Vilnius', gmt: '+00:00' },
  { name: 'Europe/Volgograd', gmt: '+00:00' },
  { name: 'Europe/Warsaw', gmt: '+00:00' },
  { name: 'Europe/Zagreb', gmt: '+00:00' },
  { name: 'Europe/Zaporozhye', gmt: '+00:00' },
  { name: 'Europe/Zurich', gmt: '+00:00' },
  { name: 'GB', gmt: '+00:00' },
  { name: 'GB-Eire', gmt: '+00:00' },
  { name: 'GMT', gmt: '+00:00' },
  { name: 'GMT+0', gmt: '+00:00' },
  { name: 'GMT-0', gmt: '+00:00' },
  { name: 'GMT0', gmt: '+00:00' },
  { name: 'Greenwich', gmt: '+00:00' },
  { name: 'HST', gmt: '+00:00' },
  { name: 'Hongkong', gmt: '+00:00' },
  { name: 'Iceland', gmt: '+00:00' },
  { name: 'Indian/Antananarivo', gmt: '+00:00' },
  { name: 'Indian/Chagos', gmt: '+00:00' },
  { name: 'Indian/Christmas', gmt: '+00:00' },
  { name: 'Indian/Cocos', gmt: '+00:00' },
  { name: 'Indian/Comoro', gmt: '+00:00' },
  { name: 'Indian/Kerguelen', gmt: '+00:00' },
  { name: 'Indian/Mahe', gmt: '+00:00' },
  { name: 'Indian/Maldives', gmt: '+00:00' },
  { name: 'Indian/Mauritius', gmt: '+00:00' },
  { name: 'Indian/Mayotte', gmt: '+00:00' },
  { name: 'Indian/Reunion', gmt: '+00:00' },
  { name: 'Iran', gmt: '+00:00' },
  { name: 'Israel', gmt: '+00:00' },
  { name: 'Jamaica', gmt: '+00:00' },
  { name: 'Japan', gmt: '+00:00' },
  { name: 'Kwajalein', gmt: '+00:00' },
  { name: 'Libya', gmt: '+00:00' },
  { name: 'MET', gmt: '+00:00' },
  { name: 'MST', gmt: '+00:00' },
  { name: 'MST7MDT', gmt: '+00:00' },
  { name: 'Mexico/BajaNorte', gmt: '+00:00' },
  { name: 'Mexico/BajaSur', gmt: '+00:00' },
  { name: 'Mexico/General', gmt: '+00:00' },
  { name: 'NZ', gmt: '+00:00' },
  { name: 'NZ-CHAT', gmt: '+00:00' },
  { name: 'Navajo', gmt: '+00:00' },
  { name: 'PRC', gmt: '+00:00' },
  { name: 'PST8PDT', gmt: '+00:00' },
  { name: 'Pacific/Apia', gmt: '+00:00' },
  { name: 'Pacific/Auckland', gmt: '+00:00' },
  { name: 'Pacific/Bougainville', gmt: '+00:00' },
  { name: 'Pacific/Chatham', gmt: '+00:00' },
  { name: 'Pacific/Chuuk', gmt: '+00:00' },
  { name: 'Pacific/Easter', gmt: '+00:00' },
  { name: 'Pacific/Efate', gmt: '+00:00' },
  { name: 'Pacific/Enderbury', gmt: '+00:00' },
  { name: 'Pacific/Fakaofo', gmt: '+00:00' },
  { name: 'Pacific/Fiji', gmt: '+00:00' },
  { name: 'Pacific/Funafuti', gmt: '+00:00' },
  { name: 'Pacific/Galapagos', gmt: '+00:00' },
  { name: 'Pacific/Gambier', gmt: '+00:00' },
  { name: 'Pacific/Guadalcanal', gmt: '+00:00' },
  { name: 'Pacific/Guam', gmt: '+00:00' },
  { name: 'Pacific/Honolulu', gmt: '+00:00' },
  { name: 'Pacific/Johnston', gmt: '+00:00' },
  { name: 'Pacific/Kiritimati', gmt: '+00:00' },
  { name: 'Pacific/Kosrae', gmt: '+00:00' },
  { name: 'Pacific/Kwajalein', gmt: '+00:00' },
  { name: 'Pacific/Majuro', gmt: '+00:00' },
  { name: 'Pacific/Marquesas', gmt: '+00:00' },
  { name: 'Pacific/Midway', gmt: '+00:00' },
  { name: 'Pacific/Nauru', gmt: '+00:00' },
  { name: 'Pacific/Niue', gmt: '+00:00' },
  { name: 'Pacific/Norfolk', gmt: '+00:00' },
  { name: 'Pacific/Noumea', gmt: '+00:00' },
  { name: 'Pacific/Pago_Pago', gmt: '+00:00' },
  { name: 'Pacific/Palau', gmt: '+00:00' },
  { name: 'Pacific/Pitcairn', gmt: '+00:00' },
  { name: 'Pacific/Pohnpei', gmt: '+00:00' },
  { name: 'Pacific/Ponape', gmt: '+00:00' },
  { name: 'Pacific/Port_Moresby', gmt: '+00:00' },
  { name: 'Pacific/Rarotonga', gmt: '+00:00' },
  { name: 'Pacific/Saipan', gmt: '+00:00' },
  { name: 'Pacific/Samoa', gmt: '+00:00' },
  { name: 'Pacific/Tahiti', gmt: '+00:00' },
  { name: 'Pacific/Tarawa', gmt: '+00:00' },
  { name: 'Pacific/Tongatapu', gmt: '+00:00' },
  { name: 'Pacific/Truk', gmt: '+00:00' },
  { name: 'Pacific/Wake', gmt: '+00:00' },
  { name: 'Pacific/Wallis', gmt: '+00:00' },
  { name: 'Pacific/Yap', gmt: '+00:00' },
  { name: 'Poland', gmt: '+00:00' },
  { name: 'Portugal', gmt: '+00:00' },
  { name: 'ROC', gmt: '+00:00' },
  { name: 'ROK', gmt: '+00:00' },
  { name: 'Singapore', gmt: '+00:00' },
  { name: 'Turkey', gmt: '+00:00' },
  { name: 'UCT', gmt: '+00:00' },
  { name: 'US/Alaska', gmt: '+00:00' },
  { name: 'US/Aleutian', gmt: '+00:00' },
  { name: 'US/Arizona', gmt: '+00:00' },
  { name: 'US/Central', gmt: '+00:00' },
  { name: 'US/East-Indiana', gmt: '+00:00' },
  { name: 'US/Eastern', gmt: '+00:00' },
  { name: 'US/Hawaii', gmt: '+00:00' },
  { name: 'US/Indiana-Starke', gmt: '+00:00' },
  { name: 'US/Michigan', gmt: '+00:00' },
  { name: 'US/Mountain', gmt: '+00:00' },
  { name: 'US/Pacific', gmt: '+00:00' },
  { name: 'US/Pacific-New', gmt: '+00:00' },
  { name: 'US/Samoa', gmt: '+00:00' },
  { name: 'UTC', gmt: '+00:00' },
  { name: 'Universal', gmt: '+00:00' },
  { name: 'W-SU', gmt: '+00:00' },
  { name: 'WET', gmt: '+00:00' },
  { name: 'Zulu', gmt: '+00:00' }
];
