import { Me } from '../../domain/entities/me-entity';
import { MeRepository } from '../../domain/repositories/me-repository';
import { getMe } from '../datasources/get-me';
import { editMe } from '../datasources/edit-me';
import { editContact } from '../datasources/edit-contact';
import { uploadProfileImage } from '../datasources/upload-profile-image';
import { uploadSignature } from '../datasources/upload-signature';

export class MeRepositoryImpl implements MeRepository {
  async GetMe(): Promise<Me> {
    const result = await getMe();

    return new Me(result);
  }

  async EditMe(title: string, name: string, gender: string): Promise<Me> {
    const result = await editMe(title, name, gender);

    return new Me(result);
  }

  EditEmail(email: string): Promise<Me> {
    throw new Error('Method not implemented.');
  }

  async EditContact(contact: string): Promise<Me> {
    console.log(contact);
    const result = await editContact(contact);
    console.log(contact);

    return new Me(result);
  }

  async UploadProfileImage(file: any): Promise<Me> {
    const upload = await uploadProfileImage(file);
    console.log(upload);

    const result = await getMe();

    return new Me(result);
  }

  async UploadSignature(file: any): Promise<Me> {
    const upload = await uploadSignature(file);
    console.log(upload);

    const result = await getMe();

    return new Me(result);
  }
}
