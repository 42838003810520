import { User } from "../entities/user-entity";
import { IUserRepository } from "../repositories/iuser-repository";

export interface IUserUsecase extends IUserRepository {}

export class UserUsecase implements IUserUsecase {
  userRepository: IUserRepository;

  constructor(ur: IUserRepository) {
    this.userRepository = ur;
  }

  FindUsers(keyword: string): Promise<User[]> {
    return this.userRepository.FindUsers(keyword);
  }

  GetUsers(location_id: string): Promise<User[]> {
    return this.userRepository.GetUsers(location_id);
  }

  GetUser(user_id: string): Promise<User> {
    return this.userRepository.GetUser(user_id);
  }
}
