const dateKey = (dt) => {
  let date = new Date(dt);
  date.setDate(date.getDate());

  date = date
    .toLocaleString("default", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$2-$1");
  return date;
};

const generateWeekDates = () => {
  let dates = [];
  let count = 0;
  for (let i = 0; i < 7; i++) {
    let date = new Date();
    date.setDate(date.getDate() + count);
    // if (date.getDay() === 0) {
    //   date.setDate(date.getDate() + 1);
    //   count = count + 1;
    // }
    dates.push(date);
    count = count + 1;
  }
  return dates;
};

export const getBarChartData = (bookings, type = "date") => {
  let data = {
    "07:00": 0,
    "08:00": 0,
    "09:00": 0,
    "10:00": 0,
    "11:00": 0,
    "12:00": 0,
    "13:00": 0,
    "14:00": 0,
    "15:00": 0,
    "16:00": 0,
    "17:00": 0,
    "18:00": 0,
    "19:00": 0,
    "20:00": 0,
    "21:00": 0,
  };

  if (type === "date") {
    data = {};
    const dates = generateWeekDates();
    dates.map((date) => (data[dateKey(date)] = 0));
  }

  for (let key in bookings) {
    let dt = bookings[key][type];

    if (type === "time") {
      // Safari fix
      // dt = dt.replace(/ /g, "T") + "Z";
      // dt = new Date(dt);
      var timeOffset = new Date().getTimezoneOffset();
      dt = new Date(dt.getTime() + timeOffset * 60 * 1000);

      dt = new Date(dt).toLocaleString("default", {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      });
    }

    const count = bookings[key]["count"];
    const bookedDate = new Date(dt)
      .toLocaleString("default", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$2-$1");

    if (bookedDate in data) {
      data[bookedDate] = count;
    }
  }

  const arr_x = Object.keys(data).map((dt) => {
    if (type === "time") {
      // Safari Fix
      let datetime = `1970-01-01T${dt}Z`;
      datetime = new Date(datetime);
      const timeOffset = new Date().getTimezoneOffset();
      datetime = new Date(datetime.getTime() + timeOffset * 60 * 1000);

      const timeString12hr = new Date(datetime).toLocaleString("default", {
        hour: "numeric",
        hour12: true,
      });

      console.log("custom after", dt, datetime, timeString12hr);

      return timeString12hr;
    }

    // Safari Fix
    // dt = new Date(dt);
    // const timeOffset = new Date().getTimezoneOffset();
    // dt = new Date(dt.getTime() + timeOffset * 60 * 1000);

    const date = new Date(dt);
    const day = date.toLocaleDateString("default", { weekday: "short" });
    const month = date.toLocaleString("default", { month: "long" });
    return `${day} ${date.getDate()} ${month}`;
  });

  const arr_y = Object.values(data);

  return { categories: arr_x, data: arr_y };
};
