import fetch from "isomorphic-fetch";
import config from "../../../../core/config/config";
import { logoutKickoutAction } from "../../../../core/redux/actions/logout-action";
import store from "../../../../core/redux/store";

export const getMe = async (): Promise<any> => {
  try {
    const state = store.getState();

    const uri = `${config.uri.auth_url}/me/organization`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.token.token}`,
      },
    };

    const response = await fetch(uri, options);
    const result = await response.json();

    if (!response.ok) {
      if (response.status === 401) {
        store.dispatch(logoutKickoutAction());
      }
      store.dispatch(logoutKickoutAction()); // assume
      throw new Error(result.message || "Something went wrong");
    }

    if (result.permissions.length === 0) {
      store.dispatch(logoutKickoutAction());
    }

    return result;
  } catch (err) {
    throw err;
  }
};
