// @ts-check
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { ListItemSecondaryAction } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import CallIcon from "@material-ui/icons/Call";

import LocationOnIcon from "@material-ui/icons/LocationOn";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  heading: {
    margin: theme.spacing(2),
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
}));

function Location({ location }) {
  const classes = useStyles();

  return (
    <>
      <div className="uk-margin">
        <List className={classes.root}>
          <ListItem>
            <ListItemText
              primary={`${location.name}`}
              secondary={null}
            ></ListItemText>

            <ListItemSecondaryAction>
              <IconButton edge="end">
                <LocationOnIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </div>

      {/* <LocationAddressComponent location={location} /> */}
    </>
  );
}

function Mail({ user }) {
  const classes = useStyles();

  return (
    <>
      <List className={classes.root}>
        <ListItem>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText
            primary={<small>Email</small>}
            secondary={user.email}
          ></ListItemText>
        </ListItem>
      </List>
    </>
  );
}

function Call({ user }) {
  const classes = useStyles();

  return (
    <>
      <List className={classes.root}>
        <ListItem>
          <ListItemIcon>
            <CallIcon />
          </ListItemIcon>
          <ListItemText
            primary={<small>Phone no.</small>}
            secondary={user.contact}
          ></ListItemText>
        </ListItem>
      </List>
    </>
  );
}

export function User({ user }) {
  const classes = useStyles();

  let role = "Member";
  if (user.owner) {
    role = "Owner";
  } else if (user.administrator) {
    role = "Admin";
  }

  return (
    <>
      <List className={classes.root}>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              alt={user.name}
              src={user.image || "/static/images/avatar/1.jpg"}
            />
          </ListItemAvatar>
          <ListItemText primary={user.name} secondary={null} />

          <ListItemSecondaryAction>
            <span
              className="uk-label uk-border-rounded "
              style={{
                background: user.administrator ? "#25a9e0" : "#fd8361",
                marginRight: "4px",
              }}
            >
              <b>{role}</b>
            </span>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </>
  );
}

export function UserComponent({
  user,
  openBackdrop,
  closeBackdrop,
  showSnackbar,
}) {
  return (
    <div>
      <User user={user} />

      <div className="uk-margin">
        <Mail user={user} />
        <Call user={user} />
      </div>

      <hr />

      {user.permissions.map((x, key) => (
        <Location key={key} location={x.location} />
      ))}
    </div>
  );
}
