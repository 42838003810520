// @ts-check
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  snackbar: {
    [theme.breakpoints.down('xs')]: {
      bottom: '5rem',
      justifyContent: 'center',
      alignItems: 'center'
    },
    [theme.breakpoints.only('sm')]: {
      bottom: '5rem',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function SnackbarComponent({ open, onClose, severity, message, uri = undefined }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Snackbar
      open={open}
      message={message}
      autoHideDuration={4000}
      onClose={onClose}
      className={classes.snackbar}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        action={
          <React.Fragment>
            {uri && (
              <button
                className="uk-button uk-button-danger uk-button-small uk-border-rounded uk-margin-left"
                type="button"
                onClick={() => history.push(uri)}
              >
                View
              </button>
            )}
            {!uri && (
              <IconButton
                className="uk-margin-left"
                size="small"
                aria-label="close"
                color="inherit"
                onClick={onClose}
                style={{ background: '#f0506e' }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          </React.Fragment>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
