import { LOADING_FAILURE, LOADING_REQUEST, LOADING_SUCCESS } from '../types/loading-types';

const initialState = {
  loading: false,
  error: null
};

function loading(state = initialState, action: any = null) {
  switch (action.type) {
    case LOADING_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case LOADING_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case LOADING_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };

    default:
      return state;
  }
}

export default loading;
