import { Dispatch } from "redux";
import Cookies from "universal-cookie";
import {
  LOADING_FAILURE,
  LOADING_REQUEST,
  LOADING_SUCCESS,
} from "../../../../../core/redux/types/loading-types";

import { SET_USER_TOKEN } from "../../../../../core/redux/types/token-types";
import {
  ME_LOAD_REQUEST,
  ME_LOAD_SUCCESS,
  ME_LOAD_FAILURE,
  ME_SELECT_LOCATION,
} from "../types/me-types";
import {
  UPLOAD_FAILURE,
  UPLOAD_REQUEST,
  UPLOAD_SUCCESS,
} from "../types/upload-types";

import { MeRepositoryImpl } from "../../../data/repositories/me-repository-impl";
import { MeUsecaseImpl } from "../../../domain/usecases/me-usecase";

export const getMeAction = () => {
  return async (dispatch: Dispatch) => {
    const cookies = new Cookies();
    const token = cookies.get("gh_org_tok");

    dispatch({ type: SET_USER_TOKEN, payload: token });
    dispatch({ type: ME_LOAD_REQUEST });

    try {
      const meRepository = new MeRepositoryImpl();
      const meUsecase = new MeUsecaseImpl(meRepository);

      const result = await meUsecase.GetMe();

      const location_id = localStorage.getItem("location_id");

      const index = result.permissions.findIndex(
        (x) => x.location_id === location_id
      );

      let location = result.permissions[0].location;
      if (index > -1) {
        location = result.permissions[index].location;
      }

      dispatch({ type: ME_LOAD_SUCCESS, payload: result });
      dispatch({
        type: ME_SELECT_LOCATION,
        payload: location,
      });
    } catch (error) {
      dispatch({ type: ME_LOAD_FAILURE, error });
    }
  };
};

export const getMeSilentAction = () => {
  return async (dispatch: Dispatch) => {
    try {
      const meRepository = new MeRepositoryImpl();
      const meUsecase = new MeUsecaseImpl(meRepository);

      const result = await meUsecase.GetMe();

      dispatch({ type: ME_LOAD_SUCCESS, payload: result });
    } catch (error) {}
  };
};

export const editMeAction = (title: string, name: string, gender: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const meRepository = new MeRepositoryImpl();
      const meUsecase = new MeUsecaseImpl(meRepository);

      const result = await meUsecase.EditMe(title, name, gender);

      dispatch({ type: ME_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};

export const editContactAction = (contact: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_REQUEST });

    try {
      const meRepository = new MeRepositoryImpl();
      const meUsecase = new MeUsecaseImpl(meRepository);
      const result = await meUsecase.EditContact(contact);

      dispatch({ type: ME_LOAD_SUCCESS, payload: result });
      dispatch({ type: LOADING_SUCCESS });
    } catch (error) {
      dispatch({ type: LOADING_FAILURE, error });
    }
  };
};

export const uploadProfileImageAction = (file: any, props: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: UPLOAD_REQUEST });

    try {
      const meRepository = new MeRepositoryImpl();
      const meUsecase = new MeUsecaseImpl(meRepository);
      const result = await meUsecase.UploadProfileImage(file);

      dispatch({ type: ME_LOAD_SUCCESS, payload: result });
      dispatch({ type: UPLOAD_SUCCESS });
      props.closeBackdrop();
    } catch (error) {
      props.closeBackdrop();
      dispatch({ type: UPLOAD_FAILURE, error });
    }
  };
};

export const uploadSignatureAction = (file: any, props: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: UPLOAD_REQUEST });

    try {
      const meRepository = new MeRepositoryImpl();
      const meUsecase = new MeUsecaseImpl(meRepository);
      const result = await meUsecase.UploadSignature(file);

      if (props.edit) {
        props.updateSignature(false);
      }

      dispatch({ type: ME_LOAD_SUCCESS, payload: result });
      dispatch({ type: UPLOAD_SUCCESS });
    } catch (error) {
      dispatch({ type: UPLOAD_FAILURE, error });
    }
  };
};

export const meSelectLocationAction = (location: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ME_SELECT_LOCATION, payload: location });
    } catch (error) {}
  };
};
