// @ts-check
import React from 'react';

import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
import NightsStayOutlinedIcon from '@material-ui/icons/NightsStayOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';

export const AppointmentStatus = ({ status }) => {
  const statuses = {
    pending: <small className="uk-label uk-border-rounded">{status}</small>,
    accepted: (
      <small className="uk-label uk-border-rounded" style={{ background: '#1e8a61' }}>
        {status}
      </small>
    ),
    rejected: (
      <small className="uk-label uk-border-rounded" style={{ background: '#f0506e' }}>
        {status}
      </small>
    ),
    expired: (
      <small className="uk-label uk-border-rounded" style={{ background: '#f0506e' }}>
        {status}
      </small>
    ),
    cancelled: (
      <small className="uk-label uk-border-rounded" style={{ background: '#f0506e' }}>
        {status}
      </small>
    ),
    noshow: (
      <small className="uk-label uk-border-rounded" style={{ background: '#fd8361' }}>
        {status}
      </small>
    ),
    inprogress: (
      <small className="uk-label uk-border-rounded" style={{ background: '#25a9e0' }}>
        {status}
      </small>
    ),
    completed: (
      <small className="uk-label uk-border-rounded" style={{ background: '#012535' }}>
        {status}
      </small>
    )
  };

  return statuses[status] || null;
};

export const AppointmentPaid = ({ payment_status }) => {
  switch (payment_status) {
    case 'paid':
      return (
        <small className="uk-label uk-border-rounded" style={{ background: '#49a861' }}>
          Paid
        </small>
      );
    case 'unpaid':
      return (
        <small className="uk-label uk-border-rounded" style={{ background: '#f0506e' }}>
          Unpaid
        </small>
      );
    case 'free':
      return (
        <small className="uk-label uk-border-rounded" style={{ background: '#25a9e0' }}>
          Free
        </small>
      );

    default:
      return null;
  }
};

export const AppointmentTypeIcon = ({ type }) => {
  const types = {
    inperson: <LocalHospitalOutlinedIcon fontSize={'default'}></LocalHospitalOutlinedIcon>,
    video: <VideocamOutlinedIcon fontSize={'default'}></VideocamOutlinedIcon>,
    afterhours: <NightsStayOutlinedIcon fontSize={'small'}></NightsStayOutlinedIcon>
  };

  return types[type];
};
