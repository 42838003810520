import {
  GUARDIAN_LOAD_REQUEST,
  GUARDIAN_LOAD_SUCCESS,
  GUARDIAN_LOAD_FAILURE
} from '../types/guardian-types';

const initialState = {
  loading: true,
  success: false,
  error: null,
  guardian: null
};

function guardian(state = initialState, action: any = null) {
  switch (action.type) {
    case GUARDIAN_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null
      };

    case GUARDIAN_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.error
      };

    case GUARDIAN_LOAD_SUCCESS:
      return {
        ...state,
        guardian: action.payload,
        loading: false,
        success: true,
        error: null
      };

    default:
      return state;
  }
}

export default guardian;
