import { BookingAnalytics } from '../../domain/entities/booking-analytics';
import { ReasonAnalytics } from '../../domain/entities/reason-analytics';
import { IAnalyticsRepository } from '../../domain/repositories/ianalytics-repository';
import { weeklyBookingsAnalytics } from '../datasources/weekly-bookings-analytics';
import { weeklyReasonsAnalytics } from '../datasources/weekly-reasons-analytics';

export class AnalyticsRepository implements IAnalyticsRepository {
  async GetWeeklyBookings(level: string, filter: any): Promise<BookingAnalytics[]> {
    const results = await weeklyBookingsAnalytics(level, filter);

    return results.map((x) => new BookingAnalytics(x.date, x.count));
  }

  async GetWeeklyReasons(level: string, filter: any): Promise<ReasonAnalytics[]> {
    const results = await weeklyReasonsAnalytics(level, filter);

    return results.map((x) => new ReasonAnalytics(x.reason, x.count));
  }
}
