import {
  ORGANIZATIONS_LOAD_REQUEST,
  ORGANIZATIONS_LOAD_SUCCESS,
  ORGANIZATIONS_LOAD_FAILURE,
  ORGANIZATIONS_LOAD_DEFAULT,
} from "../types/organizations-types";

const initialState = {
  loading: false,
  error: null,
  success: false,
  organizations: [],
};

function organizations(state = initialState, action: any = null) {
  switch (action.type) {
    case ORGANIZATIONS_LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ORGANIZATIONS_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case ORGANIZATIONS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        organizations: action.payload,
        error: null,
      };

    case ORGANIZATIONS_LOAD_DEFAULT:
      return {
        ...state,
        loading: false,
        error: null,
        organizations: [],
      };

    default:
      return state;
  }
}

export default organizations;
