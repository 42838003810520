export class User {
  id: string;
  title: string;
  name: string;
  image: string;
  gender: string;
  email: string;
  contact: string;
  administrator: boolean;
  owner: boolean;
  permissions: any;

  constructor(args: any) {
    this.id = args.id;
    this.title = args.title;
    this.name = args.name;
    this.image = args.image;
    this.gender = args.gender;
    this.email = args.email;
    this.contact = args.contact;
    this.administrator = args.administrator;
    this.owner = args.owner;
    this.permissions = args.permissions;
  }
}
